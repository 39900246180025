import Tippy from "@tippyjs/react";
import { route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";

export default function OrganisationIntermediariesOverview() {
	return (
		<div>
			<h2>{uctrans("website.organisation_intermediaries")}</h2>
			<AbnOverview
				indexUrl={route("website.organisation-intermediaries.data")}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="name"
							renderHeaderContent={() => uctrans("intermediaries.fields.name")}
							renderCellContent={intermediary => intermediary.name}
						/>
						<DataColumn
							width={2}
							sortable="company_number"
							renderHeaderContent={() => uctrans("intermediaries.fields.company_number")}
							renderCellContent={intermediary => intermediary.company_number}
						/>
						<DataColumn
							width={2}
							sortable="team"
							renderHeaderContent={() => uctrans("intermediaries.fields.team")}
							renderCellContent={intermediary => (
								<Tippy
									placement="top"
									arrow
									animation="perspective"
									duration="400"
									content={
										<div>
											<span>{intermediary.operations_team ? intermediary.operations_team.email : ""}</span>
											<br />
											<span>{intermediary.operations_team ? intermediary.operations_team.phone : ""}</span>
										</div>
									}>
									<span>{intermediary.operations_team ? intermediary.operations_team.name : ""}</span>
								</Tippy>
							)}
						/>
						<DataColumn
							width={3}
							sortable="relationmanager"
							renderHeaderContent={() => uctrans("intermediaries.fields.relationmanager")}
							renderCellContent={intermediary => (
								<Tippy
									placement="left"
									arrow
									animation="perspective"
									duration="400"
									content={
										<div>
											<span>{intermediary.relationmanager ? intermediary.relationmanager.email : ""}</span>
											<br />
											<span>{intermediary.relationmanager ? intermediary.relationmanager.phone : ""}</span>
										</div>
									}>
									<span>{intermediary.relationmanager ? intermediary.relationmanager.name : ""}</span>
								</Tippy>
							)}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("intermediaries.fields.process_times")}
							renderCellContent={intermediary => (
								<a className="link" target="_blank" href={intermediary.process_times_url} rel="noreferrer">
									{uctrans("intermediaries.fields.process_times")}
								</a>
							)}
						/>
					</DataTable>
				)}
				renderFooter={overview => (
					<a
						className="button button-primary mt-5"
						onClick={() => Nav.go(route("website.organisation-intermediaries.export", overview.getParameters()))}>
						{uctrans("intermediaries.export")}
					</a>
				)}
			/>
		</div>
	);
}
