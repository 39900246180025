import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import Select from "../../../../../js/react/components/general/Select";
import findSelectedOption from "../../../../../js/react/hooks/findSelectedOption";

export default function UnsubscribeReasonForm(props) {
	const [selectedReason, setSelectedReason] = useState(null);

	useEffect(() => {
		setSelectedReason(findSelectedOption(props.unsubscribeReasons, selectedReason, true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const unsubscribeOptions = props.unsubscribeReasons;

	const unsubscribeRequest = () => {
		Nav.go(
			route(`website.communication.unsubscribe`, {
				communication_log_token: props.communicationLogToken,
				_query: { unsubscribe_reason_id: selectedReason ? selectedReason.value : null },
			}),
		);
	};

	return (
		<>
			<div className="flex w-full justify-between">
				<div className="w-full">
					<Select
						name="unsubscribe_reason_id"
						options={unsubscribeOptions}
						onChange={selectedValue => setSelectedReason(selectedValue)}
						value={selectedReason}
						placeholder={uctrans(
							"general.select_:item",
							{},
							{ item: "communication.newsletters.unsubscribe_reasons.singular" },
						)}
						isClearable={false}
					/>
				</div>
			</div>
			<div className="w-1/3 mt-2">
				<button onClick={() => unsubscribeRequest()} className="button button-primary leading-normal">
					{uctrans("communication.newsletters.unsubscribe_reasons.send_unsubscribe")}
				</button>
			</div>
		</>
	);
}

UnsubscribeReasonForm.propTypes = {
	unsubscribeReasons: PropTypes.array,
	communicationLogToken: PropTypes.string,
};
