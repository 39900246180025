import { createRef, useEffect, useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import { route } from "../../../../../js/helpers";
import TrackingDownloadLink from "../TrackingDownloadLink";

export default function DownloadsPage() {
	const urlParams = new URLSearchParams(location.search);
	const [searchTerm, setSearchTerm] = useState(urlParams && urlParams.has("document") ? urlParams.get("document") : "");
	const [filteredDownloadGroups, setfilteredDownloadGroups] = useState([]);
	const [refs, setRefs] = useState([]);

	useEffect(() => {
		setfilteredDownloadGroups(
			(window.data.downloadGroups ? [...window.data.downloadGroups] : []).map(downloadGroup => ({
				char: downloadGroup.char,
				documents: downloadGroup.documents.filter(azTerm =>
					azTerm.name.toLowerCase().includes(searchTerm.toLowerCase()),
				),
				ref: createRef(),
			})),
		);
	}, [searchTerm]);

	useEffect(() => {
		setRefs(
			filteredDownloadGroups.reduce((acc, value) => {
				acc[value.char] = createRef();
				return acc;
			}, {}),
		);
	}, [filteredDownloadGroups]);

	const charClick = char => {
		refs[char].current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	return (
		<div className="content-block container spacer mb-16">
			<div className="shadow bg-white padding inner-block-content">
				<h1>{window.data.downloadTitle}</h1>
				<div className="flex lg:flex-row flex-col mb-8">
					<div className="lg:w-1/2">
						<label htmlFor="">{uctrans("website.az.search")}</label>
						<input
							type="text"
							placeholder={uctrans("website.az.search")}
							value={searchTerm}
							onChange={e => {
								setSearchTerm(e.target.value);
							}}
						/>
					</div>
					<div className="lg:w-1/3 lg:ml-auto mt-3 lg:mt-0">
						<label htmlFor="">{uctrans("website.az.go_to_a_char")}</label>
						<div className="flex justify-between px-2 bg-grey-light text-sm rounded max-w-sm">
							{filteredDownloadGroups.map(downloadGroup => {
								if (downloadGroup.documents.length) {
									return (
										<a
											key={downloadGroup.char}
											className=" bg-grey-light px-1 py-1"
											onClick={() => charClick(downloadGroup.char)}>
											<div>{downloadGroup.char}</div>
										</a>
									);
								} else {
									return (
										<span key={downloadGroup.char} className=" bg-grey-light px-1 py-1 text-grey-dark">
											<div>{downloadGroup.char}</div>
										</span>
									);
								}
							})}
						</div>
					</div>
				</div>
				{filteredDownloadGroups.map(downloadGroup => {
					if (downloadGroup.documents.length) {
						return (
							<div key={downloadGroup.char} className="mt-8" ref={refs[downloadGroup.char]}>
								<div className="flex items-start">
									<div className="w-1/12">
										<h2 className="m-0 uppercase">{downloadGroup.char}</h2>
									</div>
									<div className="lg:w-4/5">
										<ul className="az-list">
											{downloadGroup.documents.map((documentData, i) => (
												<li key={i}>
													<TrackingDownloadLink
														text={documentData.name}
														button={false}
														url={route("website.download-document", documentData.id)}
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						);
					}
					return "";
				})}
			</div>
		</div>
	);
}
