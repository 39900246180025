import PropTypes from "prop-types";
import { useLayoutEffect, useRef } from "react";
import { route } from "../../../../../js/helpers";
import axios from "axios";

export default function CustomDashboardBlock({ blockData }) {
	const contentRef = useRef(null);

	useLayoutEffect(() => {
		if (
			blockData.click_remember &&
			blockData.click_remember.hide_after_click &&
			blockData.click_remember.click_reference
		) {
			const { current } = contentRef;
			if (current.innerHTML && current.innerHTML.indexOf("a") >= 0) {
				current.querySelectorAll("a.button").forEach(e => {
					e.addEventListener("click", () => {
						axios.post(route("website.dashboard.dashboard-block-click"), {
							click_reference: blockData.click_remember.click_reference,
						});
					});
				});
			}
		}
	});

	return (
		<div
			className="flex flex-col h-full"
			ref={contentRef}
			dangerouslySetInnerHTML={{ __html: blockData.htmlContent }}
		/>
	);
}

CustomDashboardBlock.propTypes = {
	blockData: PropTypes.object,
};
