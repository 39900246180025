import { createRef, useEffect, useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";

export default function AzPage() {
	const [searchTerm, setSearchTerm] = useState("");
	const [filteredAzGroups, setfilteredAzGroups] = useState([]);
	const [refs, setRefs] = useState([]);

	useEffect(() => {
		setfilteredAzGroups(
			(window.data.azGroups ? [...window.data.azGroups] : []).map(azGroup => ({
				char: azGroup.char,
				terms: azGroup.terms.filter(azTerm => azTerm.term.toLowerCase().includes(searchTerm.toLowerCase())),
				ref: createRef(),
			})),
		);
	}, [searchTerm]);

	useEffect(() => {
		setRefs(
			filteredAzGroups.reduce((acc, value) => {
				acc[value.char] = createRef();
				return acc;
			}, {}),
		);
	}, [filteredAzGroups]);

	const charClick = char => {
		refs[char].current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	return (
		<div className="content-block container spacer mb-16">
			<div className="shadow bg-white padding inner-block-content">
				<h1>{uctrans("website.az.from_a_to_z")}</h1>
				<div className="flex lg:flex-row flex-col mb-8">
					<div className="lg:w-1/2">
						<label htmlFor="">{uctrans("website.az.search")}</label>
						<input
							type="text"
							placeholder={uctrans("website.az.search")}
							value={searchTerm}
							onChange={e => {
								setSearchTerm(e.target.value);
							}}
						/>
					</div>
					<div className="lg:w-1/3 lg:ml-auto mt-3 lg:mt-0">
						<label htmlFor="">{uctrans("website.az.go_to_a_char")}</label>
						<div className="flex justify-between px-2 bg-grey-light text-sm rounded max-w-sm">
							{filteredAzGroups.map(azGroup => {
								if (azGroup.terms.length) {
									return (
										<a key={azGroup.char} className=" bg-grey-light px-1 py-1" onClick={() => charClick(azGroup.char)}>
											<div>{azGroup.char}</div>
										</a>
									);
								} else {
									return (
										<span key={azGroup.char} className=" bg-grey-light px-1 py-1 text-grey-dark">
											<div>{azGroup.char}</div>
										</span>
									);
								}
							})}
						</div>
					</div>
				</div>
				{filteredAzGroups.map(azGroup => {
					if (azGroup.terms.length) {
						return (
							<div key={azGroup.char} className="mt-8" ref={refs[azGroup.char]}>
								<div className="flex items-start">
									<div className="w-1/12">
										<h2 className="m-0 uppercase">{azGroup.char}</h2>
									</div>
									<div className="lg:w-4/5 mt-3">
										<ul className="az-list">
											{azGroup.terms.map((termData, i) => (
												<li key={i}>
													<a href={termData.url}>{termData.term}</a>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						);
					}
					return "";
				})}
			</div>
		</div>
	);
}
