import map from "lodash/map";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import InterestOverviewTableRow from "./InterestOverviewTableRow";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import { TransitionGroup } from "react-transition-group";

export default function InterestOverviewTable({ interestRates, isFuture, expanded, setExpanded, shownPeriods }) {
	const memorizedInterestRates = useMemo(() => Object.values(interestRates)[0], [interestRates]);
	const memorizedShowPeriodCount = useMemo(
		() => Object.values(shownPeriods).filter(value => value).length,
		[shownPeriods],
	);

	const isShort = () =>
		Object.values(interestRates).length <= 3 ||
		memorizedShowPeriodCount === 0 ||
		Object.values(interestRates).length === memorizedShowPeriodCount;
	const isExpanded = () => isShort() || expanded;
	const showDefault = rateName => {
		if (memorizedShowPeriodCount === 0) {
			return true;
		}
		return shownPeriods[rateName] ?? false;
	};

	return (
		<>
			<div className="overflow-x-auto">
				<table className="new-interest-table">
					<thead>
						<tr className="">
							<th>{uctrans("website.interests.fixed_interest_period")}</th>
							{map(Object.values(memorizedInterestRates), (values, index) => (
								<th className="text-center" key={index}>
									{values.interest_rate_risk_category}
								</th>
							))}
						</tr>
					</thead>
					<TransitionGroup component="tbody">
						{map(interestRates, (values, rateName) => (
							<InterestOverviewTableRow
								key={rateName}
								values={values}
								forceShown={isExpanded()}
								isFuture={isFuture}
								defaultShown={showDefault(rateName)}
							/>
						))}
					</TransitionGroup>
				</table>
			</div>
			{!isShort() && (
				<div
					onClick={() => setExpanded(previous => !previous)}
					className="bg-primary-lightest border-t-2 border-grey text-center py-2 px-4 font-medium cursor-pointer">
					<span className="mr-1">
						<EvaIcon type={`chevron-${expanded ? "up" : "down"}-outline`} fill="#249286" height="20" width="20" />
					</span>
					{uctrans(`website.interests.${expanded ? "show_less" : "show_more"}`)}
				</div>
			)}
		</>
	);
}

InterestOverviewTable.propTypes = {
	interestRates: PropTypes.object.isRequired,
	isFuture: PropTypes.bool.isRequired,
	expanded: PropTypes.bool.isRequired,
	setExpanded: PropTypes.func.isRequired,
	shownPeriods: PropTypes.object.isRequired,
};
