import axios from "axios";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import Spinner from "../../../../../js/react/components/general/Spinner";

export default function SearchResultsContent(props) {
	const [searching, setSearching] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		if (props.searchTerm !== "") {
			setSearching(true);
			axios
				.post(route(props.searchRoute), {
					term: props.searchTerm,
					limit: 25,
				})
				.then(response => {
					setSearchResults(response.data);
					setSearching(false);
				})
				.catch(error => {
					console.error(`Errors during search: ${error}`);
				});
		} else {
			setSearchResults([]);
			setSearching(false);
		}
	}, [props.searchRoute, props.searchTerm]);

	return (
		<div className="search-results-content">
			{searching && (
				<>
					<h5>{props.searchingTitle}</h5>
					<div className="search-loader">
						<Spinner width={25} />
					</div>
				</>
			)}
			{!searching && !!searchResults.length && (
				<>
					<h5>{props.foundTitle}</h5>
					<ul>
						{searchResults.map((searchResult, key) => (
							<li key={key}>
								{props.renderSearchResult && props.renderSearchResult(searchResult.url, searchResult.label)}
								{!props.renderSearchResult && <a href={searchResult.url}>{searchResult.label}</a>}
							</li>
						))}
					</ul>
				</>
			)}
			{!searching && !searchResults.length && (
				<>
					<h5>{props.notFoundTitle}</h5>
					<span className="italic">{props.notFoundMessage}</span>
				</>
			)}
		</div>
	);
}

SearchResultsContent.propTypes = {
	searchTerm: PropTypes.string,
	searchRoute: PropTypes.string,
	searchingTitle: PropTypes.string,
	foundTitle: PropTypes.string,
	notFoundTitle: PropTypes.string,
	notFoundMessage: PropTypes.string,
	renderSearchResult: PropTypes.func,
};
