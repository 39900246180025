import PropTypes from "prop-types";
import LabeledDropDown from "../../../../../js/react/components/general/form/LabeledDropDown";
import { uctrans } from "../../../../../js/lib/Translator";
export default function NewInterestArchiveYearSelection(props) {
	return (
		<LabeledDropDown
			label={uctrans("website.interests.year")}
			name="year"
			value={props.selected}
			onChange={props.onChange}
			options={props.years.map(year => ({
				label: year,
				value: year,
			}))}
		/>
	);
}

NewInterestArchiveYearSelection.propTypes = {
	selected: PropTypes.number.isRequired,
	years: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};
