import { useContext, useEffect } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import CooperationRequestContext from "../CooperationRequestContext";
import contact from "../../../../../../static/img/samenwerkingsverzoek/pr-contact.svg";

export default function StepContactperson() {
	const { cooperationRequestData, updateCooperationRequestData, setCanGoBack, setCanGoNext, setIcon } =
		useContext(CooperationRequestContext);

	useEffect(() => {
		setCanGoBack(true);
		setIcon(contact);
	}, [setCanGoBack, setIcon]);

	useEffect(() => {
		const requiredFields = [
			"contactperson_firstname",
			"contactperson_lastname",
			"contactperson_email",
			"contactperson_phone",
		];
		const emptyFields = requiredFields.filter(key => !cooperationRequestData[key]);

		if (emptyFields.length === 0) {
			setCanGoNext(true);
		}
	}, [cooperationRequestData, setCanGoNext]);

	return (
		<div>
			<div className="form-full">
				<span>{uctrans("website.cooperation_request.contactperson_information")}</span>
				<br />

				<LabeledTextInput
					name="contactperson_firstname"
					label="contactpersons.fields.first_name"
					value={cooperationRequestData.contactperson_firstname || ""}
					onChange={e => updateCooperationRequestData(e.target.value, "contactperson_firstname")}
					required
				/>

				<LabeledTextInput
					name="contactperson_insertion"
					label="contactpersons.fields.insertion"
					value={cooperationRequestData.contactperson_insertion || ""}
					onChange={e => updateCooperationRequestData(e.target.value, "contactperson_insertion")}
				/>

				<LabeledTextInput
					name="contactperson_lastname"
					label="contactpersons.fields.last_name"
					value={cooperationRequestData.contactperson_lastname || ""}
					onChange={e => updateCooperationRequestData(e.target.value, "contactperson_lastname")}
					required
				/>

				<LabeledTextInput
					type="email"
					name="contactperson_email"
					label="contactpersons.fields.email"
					value={cooperationRequestData.contactperson_email || ""}
					onChange={e => updateCooperationRequestData(e.target.value, "contactperson_email")}
					required
				/>

				<LabeledTextInput
					type="tel"
					name="contactperson_phone"
					label="contactpersons.fields.phone"
					value={cooperationRequestData.contactperson_phone || ""}
					onChange={e => updateCooperationRequestData(e.target.value, "contactperson_phone")}
					required
				/>
			</div>
		</div>
	);
}
