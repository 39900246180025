import PropTypes from "prop-types";
import ClientLeadsDashboardBlock from "./ClientLeadsDashboardBlock";
import ClientTreatmentEnrollmentBlocks from "./ClientTreatmentEnrollmentBlocks";
import CommercialDashboardBlock from "./CommercialDashboardBlock";
import CustomDashboardBlock from "./CustomDashboardBlock";
import MessagesDashboardBlock from "./MessagesDashboardBlock";
import ProcessTimesDashboardBlock from "./ProcessTimesDashboardBlock";
import PromoGroupDashboardBlock from "./PromoGroupDashboardBlock";

export default function DashboardBlock({ title, type, blockData, forPromoGroup }) {
	const getBlock = () => {
		switch (type) {
			case "commercial":
				return <CommercialDashboardBlock title={title} />;
			case "messages":
				return <MessagesDashboardBlock title={title} />;
			case "processtimes":
				return <ProcessTimesDashboardBlock title={title} />;
			case "client_treatment_enrollments":
				return <ClientTreatmentEnrollmentBlocks title={title} blockData={blockData} />;
			case "client_leads":
				return <ClientLeadsDashboardBlock title={title} blockData={blockData} />;
			case "custom":
				return <CustomDashboardBlock blockData={blockData} />;
			case "promo_group":
				if (!forPromoGroup) {
					return <PromoGroupDashboardBlock blockData={blockData} />;
				}
		}
		return null;
	};

	return forPromoGroup || type === "promo_group" ? (
		getBlock()
	) : (
		<div className="three-blocks vertical-spacer spacer flex">
			<div className="bg-white shadow padding flex flex-col w-full">{getBlock()}</div>
		</div>
	);
}

DashboardBlock.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string,
	blockData: PropTypes.object,
	forPromoGroup: PropTypes.bool,
};

DashboardBlock.defaultProps = {
	forPromoGroup: false,
};
