import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import { useState, useCallback } from "react";
import { format, parse } from "../../../lib/Date";
import { uctrans } from "../../../lib/Translator";
import DatePicker from "../general/DatePicker";
import EvaIcon from "../general/EvaIcon";
import Checkmark from "../general/form/checkmark/Checkmark";
import ImageThumbnailFileInput from "../general/form/ImageThumbnailFileInput";
import Translate from "../general/Translate";
import find from "lodash/find";
import SelectInterestList from "../interests/SelectInterestList";

export default function PrivacyPreferences(props) {
	const [privacyPreferences, setPrivacyPreferences] = useState(props.privacyPreferenceData);

	const callbackChange = useCallback(
		newPreferences => {
			props.changePrivacyPreferences(newPreferences);
			setPrivacyPreferences(newPreferences);
		},
		[props],
	);

	const handlePreferenceChange = key => {
		const index = privacyPreferences.findIndex(preference => Number(preference.type) === Number(key));

		if (index !== -1) {
			const newPrivacyPreferences = [...privacyPreferences];
			newPrivacyPreferences[index].disabled = !newPrivacyPreferences[index].disabled;
			callbackChange(newPrivacyPreferences);
		} else {
			callbackChange([
				...privacyPreferences,
				{
					type: key,
					disabled: 1,
				},
			]);
		}
	};

	const isChecked = key => {
		const preference = find(privacyPreferences, preference => Number(preference.type) === Number(key));
		if (preference) {
			if (preference.disabled) {
				return preference.disabled;
			}
		}
		return false;
	};

	return (
		<>
			<h3>{uctrans("my_profile.fields.date_of_birth")}</h3>
			<div className="form-full mb-8">
				<div className="flex">
					<Checkmark
						label={uctrans("my_profile.fields.allow_date_of_birth")}
						name="allow_date_of_birth"
						checked={!isChecked(props.privacyPreferenceTypes.DATE_OF_BIRTH.key)}
						onChange={() => handlePreferenceChange(props.privacyPreferenceTypes.DATE_OF_BIRTH.key)}
					/>
					{!!props.dateOfBirthHint && (
						<Tippy
							content={<span dangerouslySetInnerHTML={{ __html: props.dateOfBirthHint }} />}
							placement="top"
							arrow
							animation="perspective">
							<span className="mt-1 ml-2">
								<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
							</span>
						</Tippy>
					)}
				</div>
				{!isChecked(props.privacyPreferenceTypes.DATE_OF_BIRTH.key) ? (
					<>
						{" "}
						<label htmlFor="date_of_birth">
							<Translate content="my_profile.fields.date_of_birth" />
						</label>
						<DatePicker
							initialValue={props.formData.date_of_birth ? parse(props.formData.date_of_birth) : null}
							onChange={date => props.changeFieldValue("date_of_birth", date ? format(date) : null)}
						/>
					</>
				) : null}
			</div>

			<h3>{uctrans("my_profile.fields.photo")}</h3>
			<div className="form-full">
				<div className="form-1-3">
					<div className="flex">
						<Checkmark
							label={uctrans("my_profile.fields.allow_photo")}
							name="allow_photo"
							checked={!isChecked(props.privacyPreferenceTypes.PHOTO.key)}
							onChange={() => handlePreferenceChange(props.privacyPreferenceTypes.PHOTO.key)}
						/>
						{!!props.photoHint && (
							<Tippy
								content={<span dangerouslySetInnerHTML={{ __html: props.photoHint }} />}
								placement="top"
								arrow
								animation="perspective">
								<span className="mt-1 ml-2">
									<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
								</span>
							</Tippy>
						)}
					</div>
					{!isChecked(props.privacyPreferenceTypes.PHOTO.key) ? (
						<>
							{" "}
							<label htmlFor="allow_photo">
								<Translate content="my_profile.fields.photo" />
							</label>
							<ImageThumbnailFileInput
								storeRoute="website.files.store"
								newFileDownloadRoute="website.files.download"
								existingFileDownloadRoute="website.my-profile.download-image"
								maxFiles={1}
								image={props.formData.photo ? props.formData.photo : null}
								onChange={files => props.changeFieldValue("photo", files)}
							/>
						</>
					) : null}
				</div>
			</div>

			<h3>{uctrans("my_profile.fields.interests")}</h3>
			<div className="form-full">
				<div className="flex">
					<Checkmark
						label={uctrans("my_profile.fields.allow_interests")}
						name="allow_interests"
						checked={!isChecked(props.privacyPreferenceTypes.INTEREST.key)}
						onChange={() => handlePreferenceChange(props.privacyPreferenceTypes.INTEREST.key)}
					/>
					{!!props.interestHints && (
						<Tippy
							content={<span dangerouslySetInnerHTML={{ __html: props.interestHints }} />}
							placement="top"
							arrow
							animation="perspective">
							<span className="mt-1 ml-2">
								<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
							</span>
						</Tippy>
					)}
				</div>
				{!isChecked(props.privacyPreferenceTypes.INTEREST.key) ? (
					<div className="ml-8">
						<SelectInterestList
							initialInteresttypes={props.formData.interests}
							initialCategories={props.interestCategories}
							onChange={selectedInterests => props.changeFieldValue("interests", selectedInterests)}
						/>
					</div>
				) : null}
			</div>

			<h3>{uctrans("my_profile.fields.events")}</h3>
			<div className="form-full">
				<div className="flex">
					<Checkmark
						label={uctrans("my_profile.fields.allow_events")}
						name="allow_events"
						checked={!isChecked(props.privacyPreferenceTypes.EVENTS.key)}
						onChange={() => handlePreferenceChange(props.privacyPreferenceTypes.EVENTS.key)}
					/>
					{!!props.eventHint && (
						<Tippy
							content={<span dangerouslySetInnerHTML={{ __html: props.eventHint }} />}
							placement="top"
							arrow
							animation="perspective">
							<span className="mt-1 ml-2">
								<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
							</span>
						</Tippy>
					)}
				</div>
			</div>
		</>
	);
}

PrivacyPreferences.propTypes = {
	privacyPreferenceData: PropTypes.array.isRequired,
	privacyPreferenceTypes: PropTypes.object.isRequired,
	changeFieldValue: PropTypes.func.isRequired,
	changePrivacyPreferences: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
	interestCategories: PropTypes.array,
	interestHints: PropTypes.string,
	eventHint: PropTypes.string,
	dateOfBirthHint: PropTypes.string,
	photoHint: PropTypes.string,
};
