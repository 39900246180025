import { useContext, useEffect, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import CooperationRequestContext from "../CooperationRequestContext";
import trafficLightRed from "../../../../../../static/img/samenwerkingsverzoek/pr-stoplicht rood.svg";
import trafficLightGreen from "../../../../../../static/img/samenwerkingsverzoek/pr-stoplicht groen.svg";

export default function StepAfm() {
	const { setCanGoNext, updateCooperationRequestData, pre_processed_data, setIcon } =
		useContext(CooperationRequestContext);
	const [afmNumber, setAfmNumber] = useState(null);
	const [missingAfmPermits, setMissingAfmPermits] = useState(null);

	useEffect(() => {
		if (pre_processed_data.afm_number) {
			setAfmNumber(pre_processed_data.afm_number);
			updateCooperationRequestData(pre_processed_data.afm_number, "afm_number");
		}

		setMissingAfmPermits(pre_processed_data.missing_afm_permits);

		if (pre_processed_data.missing_afm_permits.length === 0 && pre_processed_data.afm_number) {
			setCanGoNext(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (missingAfmPermits && missingAfmPermits.length > 0) {
			setIcon(trafficLightRed);
		} else {
			setIcon(trafficLightGreen);
		}
	}, [missingAfmPermits, setIcon]);

	return (
		<div>
			<div className="form-full">
				<span>{uctrans("website.cooperation_request.based_on_kvk_kifid_and_afm_data_fetched")}</span>
				<br />

				<ul className="list-disc pl-4">
					<li>
						{afmNumber
							? `${uctrans("website.cooperation_request.afm_number")}: ${afmNumber}`
							: uctrans("website.cooperation_request.afm_number_not_found_message")}
					</li>
				</ul>

				{missingAfmPermits ? (
					missingAfmPermits.length === 0 ? (
						<span>
							{uctrans("website.cooperation_request.all_required_permits_available_message")} <br />
						</span>
					) : (
						<div>
							<br />
							{uctrans(
								"website.cooperation_request.missing_message",
								{},
								{
									permits:
										missingAfmPermits.length === 1
											? "website.cooperation_request.permit"
											: "website.cooperation_request.permits",
									missing:
										missingAfmPermits.length === 1
											? "website.cooperation_request.missing_singular"
											: "website.cooperation_request.missing_plural",
								},
							)}

							<ul className="list-disc pl-4">
								{missingAfmPermits.map((permit, key) => (
									<li key={key}>{`${permit.afm_service.name} - ${permit.afm_product.name}`}</li>
								))}
							</ul>

							<br />
							<span>{uctrans("website.cooperation_request.afm_try_again_in_a_few_days")}</span>
						</div>
					)
				) : null}

				{missingAfmPermits && missingAfmPermits.length > 0 && (
					<div>
						<br />
						<span>{uctrans("website.cooperation_request.close_the_browser")}</span>
					</div>
				)}
			</div>
		</div>
	);
}
