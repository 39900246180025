import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";

import SearchResultsContent from "./SearchResultsContent";

export default function SearchResults({ searchTerm }) {
	return (
		<div className={`search-results ${searchTerm === "" ? "hidden" : ""}`}>
			<div className="search-results-container">
				<SearchResultsContent
					searchTerm={searchTerm}
					searchRoute="website.search.site-search-results"
					searchingTitle={uctrans("website.search.site_searching_title")}
					foundTitle={uctrans("website.search.site_found_title")}
					notFoundTitle={uctrans("website.search.site_not_found_title")}
					notFoundMessage={uctrans("website.search.site_not_found_message")}
				/>
				{/* {can("credit-guide") && (*/}
				{/*	<SearchResultsContent*/}
				{/*		searchTerm={searchTerm}*/}
				{/*		searchRoute="website.search.credit-guide-search-results"*/}
				{/*		searchingTitle={uctrans("website.search.credit_guide_searching_title")}*/}
				{/*		foundTitle={uctrans("website.search.credit_guide_found_title")}*/}
				{/*		notFoundTitle={uctrans("website.search.credit_guide_not_found_title")}*/}
				{/*		notFoundMessage={uctrans("website.search.credit_guide_not_found_message")}*/}
				{/*		renderSearchResult={function (url, label) {*/}
				{/*			return (*/}
				{/*				<a href={url}>*/}
				{/*					<span dangerouslySetInnerHTML={{ __html: label }} />*/}
				{/*				</a>*/}
				{/*			);*/}
				{/*		}}*/}
				{/*	/>*/}
				{/* )}*/}
			</div>
		</div>
	);
}

SearchResults.propTypes = {
	searchTerm: PropTypes.string,
};
