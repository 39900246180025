import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { uctrans } from "../../../../js/lib/Translator";
import EvaIcon from "../../../../js/react/components/general/EvaIcon";
import DebouncedInput from "../../../../js/react/components/general/form/DebouncedInput";

export default function WebsiteSearchbar({ onChange, name, placeholder, value, searchValue }) {
	const [isActive, setIsActive] = useState(false);

	const handleClick = useCallback(() => {
		setIsActive(current => !current);
		searchValue("");
	}, [searchValue]);

	return (
		<div className="search-bar">
			<div>
				{isActive && (
					<DebouncedInput
						type="text"
						name={name}
						placeholder={placeholder}
						value={value || ""}
						onChange={onChange}
						autoFocus
					/>
				)}
			</div>

			<a onClick={handleClick}>
				<EvaIcon
					type={isActive ? "close" : "search"}
					className="search-placeholder"
					fill="#FFFFFF"
					height="22"
					width="22"
				/>
			</a>
		</div>
	);
}

WebsiteSearchbar.propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	searchValue: PropTypes.string,
	name: PropTypes.string,
};

WebsiteSearchbar.defaultProps = {
	placeholder: uctrans("general.search"),
};
