import { useContext, useEffect, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import trafficLightGreen from "../../../../../../static/img/samenwerkingsverzoek/pr-stoplicht groen.svg";
import trafficLightOrange from "../../../../../../static/img/samenwerkingsverzoek/pr-stoplicht oranje.svg";
import CooperationRequestContext from "../CooperationRequestContext";

export default function StepKifid() {
	const { setCanGoNext, updateCooperationRequestData, pre_processed_data, setIcon } =
		useContext(CooperationRequestContext);
	const [kifidNumber, setKifidNumber] = useState(null);

	useEffect(() => {
		if (pre_processed_data.kifid_number) {
			setKifidNumber(pre_processed_data.kifid_number);
			updateCooperationRequestData(pre_processed_data.kifid_number, "kifid_number");
		}
		setCanGoNext(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (pre_processed_data && !pre_processed_data.kifid_number) {
			setIcon(trafficLightOrange);
		} else {
			setIcon(trafficLightGreen);
		}
	}, [pre_processed_data, setIcon]);

	return (
		<div>
			<div className="form-full">
				<span>{uctrans("website.cooperation_request.based_on_kvk_kifid_and_afm_data_fetched")}</span>
				<br />

				<ul className="list-disc pl-4">
					<li>
						{kifidNumber
							? `${uctrans("website.cooperation_request.kifid_number")}: ${kifidNumber}`
							: uctrans("website.cooperation_request.kifid_number_not_found_message")}
					</li>
				</ul>
			</div>
		</div>
	);
}
