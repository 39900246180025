import DiscussRequestCreateFormContent from "../../../../../js/react/components/discussRequests/DiscussRequestCreateFormContent";
import DiscussRequestEditFormContent from "../../../../../js/react/components/discussRequests/DiscussRequestEditFormContent";

export default function DiscussRequestForm() {
	const formType = window.data.discuss_request_formtype;

	return formType === "create" ? (
		<DiscussRequestCreateFormContent
			fileStoreRoute="website.files.store"
			newFileDownloadRoute="website.files.download"
			indexRoute="website.discuss-requests.index"
			storeRoute="website.discuss-requests.store"
		/>
	) : (
		<DiscussRequestEditFormContent
			fileStoreRoute="website.files.store"
			newFileDownloadRoute="website.files.download"
			existingFileDownloadRoute="website.discuss-requests.attachments.download"
			indexRoute="website.discuss-requests.index"
			updateRoute="website.discuss-requests.update"
		/>
	);
}
