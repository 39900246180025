import { useContext, useEffect } from "react";
import CmsHtml from "../../../../../../js/react/components/CmsHtml";
import CooperationRequestContext from "../CooperationRequestContext";

export default function StepRequested() {
	const { pre_processed_data, setCanGoNext } = useContext(CooperationRequestContext);

	useEffect(
		() => setCanGoNext(true),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return <CmsHtml html={pre_processed_data && pre_processed_data[0]} />;
}
