import IframeResizer from "iframe-resizer-react";
import { renderComponent, renderComponentsToClassname } from "../../../../js/componentRenderer";
import { renderDefinitionTippyToClassname } from "../../definitionTippyRenderer";
import { highlightSearchtermsInMainContent } from "../../highlightSearchterms";
import AllCompanySelectionOverview from "../components/AllCompanySelectionOverview";
import AppRegistrationActivationCode from "../components/AppRegistrationActivationCode";
import AppRegistrationQrCode from "../components/AppRegistrationQrCode";
import AzPage from "../components/az/AzPage";
import ClientTreatmentLandingPage from "../components/clientTreatment/ClientTreatmentLandingPage";
import UnsubscribeReasonForm from "../components/communication/UnsubscribeReasonForm";
import ContactPage from "../components/contact/ContactPage";
import CooperationRequest from "../components/cooperationRequest/cooperationRequest";
import RequestCooperationRequestForm from "../components/cooperationRequest/RequestCooperationRequestForm";
import ClientTreatmentEnrollmentBlocks from "../components/Dashboard/ClientTreatmentEnrollmentBlocks";
import WebsiteDashboard from "../components/Dashboard/WebsiteDashboard";
import DownloadsPage from "../components/downloads/DownloadsPage";
import ErrorSearchBox from "../components/error/ErrorSearchBox";
import IconLinks from "../components/IconLinks";
import NewInterestArchive from "../components/interest/NewInterestArchive";
import MiniLocator from "../components/locator/MiniLocator";
import MyAppInfo from "../components/myProfile/MyAppInfo";
import MyExistingColleaguesOverview from "../components/myProfile/MyExistingColleaguesOverview";
import MyUserProfileForm from "../components/myProfile/MyUserProfileForm";
import OrganisationIntermediariesOverview from "../components/organisation/OrganisationIntermediariesOverview";
import ClientConsentCreateForm from "../components/clientConsents/ClientConsentCreateForm";
import ClientConsentDetail from "../components/clientConsents/ClientConsentDetail";
import ClientConsentOverview from "../components/clientConsents/ClientConsentOverview";
import MyContactpersonProfileForm from "../components/myProfile/MyContactpersonProfileForm";
import MyColleguesForm from "../components/myProfile/MyColleguesForm";
import MyColleaguesOverview from "../components/myProfile/MyColleaguesOverview";
import MessagesOverview from "../components/messages/MessagesOverview";
import ReportDownload from "../components/reports/ReportDownload";
import MyCompanyForm from "../components/myProfile/MyCompanyForm";
import InterestArchive from "../components/interest/InterestArchive";
import InterestOverview from "../components/interest/InterestOverview";
import NewInterestOverview from "../components/interest/NewInterestOverview";
import DiscussRequestForm from "../components/discussRequests/DiscussRequestForm";
import DiscussRequestOverview from "../components/discussRequests/DiscussRequestOverview";
import Menu from "../components/Menu";
import QrLogin from "../components/QrLogin";
import Questionnaire from "../components/Questionnaire";
import { Notifications } from "../../../../js/react/components/general/notifications";
import SearchOverview from "../components/SearchOverview";
import UserMenu from "../components/UserMenu";
import VideoPlayer from "../components/VideoPlayer";
import WebsitePopups from "../components/WebsitePopups";

/**
 * Entry file for forms, all forms are rendered here
 */
renderComponent(Menu, "menu");
renderComponent(UserMenu, "user-menu");
renderComponent(ErrorSearchBox, "error-search-box");
renderComponent(AllCompanySelectionOverview, "all-company-selection-overview");
renderComponent(Questionnaire, "questionnaire");
renderComponent(Notifications, "notifications");
renderComponent(DiscussRequestOverview, "discuss-request-overview");
renderComponent(DiscussRequestForm, "discuss-request-form");
renderComponent(QrLogin, "qr-login");
renderComponent(InterestOverview, "interest-overview");
renderComponent(NewInterestOverview, "new-interest-overview");
renderComponent(InterestArchive, "interest-archive");
renderComponent(NewInterestArchive, "new-interest-archive");
renderComponent(IframeResizer, "iframe-resizer");
renderComponent(ClientConsentCreateForm, "client-consent-create-form");
renderComponent(ClientConsentDetail, "client-consent-detail");
renderComponent(ClientConsentOverview, "client-consent-overview");
renderComponent(MyContactpersonProfileForm, "my-contactperson-profile-form");
renderComponent(MyUserProfileForm, "my-user-profile-form");
renderComponent(MyCompanyForm, "my-company-profile-form");
renderComponent(MyColleaguesOverview, "my-collegues-overview");
renderComponent(MyColleguesForm, "my-collegues-form");
renderComponent(MyExistingColleaguesOverview, "my-existing-colleagues-overview");
renderComponent(MyAppInfo, "my-app-info");
renderComponent(AzPage, "from-alfa-to-zulu");
renderComponent(DownloadsPage, "downloads-page");
renderComponent(ContactPage, "contact-page");
renderComponent(IconLinks, "icon-links");
renderComponent(SearchOverview, "search-overview");
renderComponent(MessagesOverview, "messages-overview");
renderComponent(ReportDownload, "report-download");
renderComponent(WebsitePopups, "website-popups");
renderComponent(ClientTreatmentLandingPage, "client-treatment-landing-page");
renderComponent(ClientTreatmentEnrollmentBlocks, "client-treatment-enrollment-blocks");
renderComponent(UnsubscribeReasonForm, "unsubscribe-reason-form");
renderComponent(WebsiteDashboard, "website-dashboard");
renderComponent(MiniLocator, "mini-locator");
renderComponent(CooperationRequest, "cooperation-request");
renderComponent(OrganisationIntermediariesOverview, "organisation-intermediaries-overview");
renderComponent(RequestCooperationRequestForm, "request-cooperation-check-form");
renderComponentsToClassname(AppRegistrationActivationCode, "app-registration-code");
renderComponentsToClassname(VideoPlayer, "video-player");
renderComponentsToClassname(AppRegistrationQrCode, "qr-code-reqgistration-app");
renderDefinitionTippyToClassname("definition-tooltip");

if (window.data.highlightSearchterm) {
	highlightSearchtermsInMainContent(window.data.highlightSearchterm);
}

/**
 * These imports are manually needed for our server-side templates
 * Only add images here if they're needed in those templates
 * If you need one of these in your component, just import it there
 **/
import "../../../../static/img/favicon.ico";
import "../../../../static/img/abn-amro-grey.svg";
import "../../../../static/img/pvSystem.jpg";
import "../../../../static/img/wallInsulation.jpg";
import "../../../../static/img/floorInsulation.jpg";
import "../../../../static/img/roofInsulation.jpg";
import "../../../../static/img/insulationGlazing.jpg";
import "../../../../static/img/centralHeating.jpg";
import "../../../../static/img/responsive-logo.svg";
import "../../../../static/img/login-error.svg";
import "../../../../static/img/logout-icon.svg";
import "../../../../static/img/logout-icon-hover.svg";
import "../../../../static/img/abn-rocket.png";
import "../../../../static/img/stad.jpg";

/**
 * MAIL IMAGES
 */
import "../../../../static/img/mail/block_corner_footer.jpg";
import "../../../../static/img/mail/header-triangle-blue.png";
import "../../../../static/img/mail/header-triangle-green.png";
import "../../../../static/img/mail/header-triangle-white.png";
import "../../../../static/img/mail/slope-green.png";
import "../../../../static/img/mail/logo-abn-amro.png";
import "../../../../static/img/mail/bg_repeat.png";
import "../../../../static/img/mail/border-text-block.png";
import "../../../../static/img/mail/logo-abn-amro-2.png";
import "../../../../static/img/mail/logo-abn-amro-mail.png";
import "../../../../static/img/mail/triangle.png";
import "../../../../static/img/mail/list-icon.png";
import "../../../../static/img/mail/no-image.png";
import "../../../../static/img/mail/subject-placeholder.png";
import "../../../../static/img/pdf/pdf_logo.png";
import "../../../../static/img/pdf/alert-triangle-outline.png";
import "../../../../static/img/pdf/home-outline.png";
import "../../../../static/img/pdf/person-outline.png";
import "../../../../pdf/abn-pdf-header.png";
import "../../../../pdf/cover_liggend.jpg";
import "../../../../pdf/cover.jpg";
import "../../../../static/img/signatures/ah_bosch.png";
import "../../../../static/img/signatures/fm_groenendijk.png";
import "../../../../static/img/pdf/logo_green.svg";
