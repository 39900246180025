import axios from "axios";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState, useMemo } from "react";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import LabeledCheckbox from "../../../../../js/react/components/general/form/LabeledCheckbox";
import LabeledDropDown from "../../../../../js/react/components/general/form/LabeledDropDown";
import Spinner from "../../../../../js/react/components/general/Spinner";
import ChangeLegend from "./InterestOverviewChangeLegend";
import InterestOverviewPeriodTab from "./InterestOverviewPeriodTab";
import InterestOverviewTable from "./InterestOverviewTable";
import { error } from "../../../../../js/react/components/general/notifications";
import NewInterestBridgingLoans from "./NewInterestBridgingLoans";

export default function NewInterestOverview(props) {
	const [currentPeriod, setCurrentPeriod] = useState(props.period ?? window.data.current_period);
	const [currentMortgageProduct, setCurrentMortgageProduct] = useState(currentPeriod.mortgage_products[0] ?? []);
	const [currentBridgingLoans, setCurrentBridgingLoans] = useState([]);
	const [interestRates, setInterestRates] = useState([]);
	const [interestFilters, setInterestFilters] = useState(
		window.data.default_interest_filters ?? {
			period: currentPeriod.id,
			mortgage_product: currentMortgageProduct.id ?? null,
			interest_rate_table: currentMortgageProduct.interest_rate_tables[0].id ?? null,
			deviations: [currentMortgageProduct.deviations[0] ? currentMortgageProduct.deviations[0].id : null, ""],
		},
	);
	const [houseDeviation, setHouseDeviation] = useState(
		currentMortgageProduct ? currentMortgageProduct.deviations[0] : [],
	);
	const [periods, setPeriods] = useState([]);
	const [loading, setLoading] = useState(true);
	const [expanded, setExpanded] = useState(false);

	const getInterestRates = useCallback(() => {
		setLoading(true);
		setInterestRates([]);
		axios
			.get(
				route("website.interest.periods.calculate", {
					interestPeriod: interestFilters.period,
					interest_rate_table: interestFilters.interest_rate_table,
					mortgage_product: interestFilters.mortgage_product,
					deviations: interestFilters.deviations,
				}),
			)
			.then(response => {
				if (response && response.data && response.data.rates) {
					setInterestRates(response.data.rates);
				}
			})
			.catch(e => {
				if (e.response.status === 404) {
					error(uctrans("website.interests.no_period_found"));
				} else {
					console.error(e);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, [interestFilters]);

	const selectPeriod = useCallback(
		async newPeriodId => {
			if (newPeriodId === currentPeriod.id) {
				return;
			}
			setLoading(true);
			const response = await axios.get(route("website.interest.period", { period: newPeriodId }));
			const houseDeviation = response.data.mortgage_products[0].deviations
				? response.data.mortgage_products[0].deviations[0]
				: null;
			setCurrentPeriod(response.data);
			setCurrentMortgageProduct(response.data.mortgage_products[0]);
			setHouseDeviation(houseDeviation);
			setInterestFilters({
				...interestFilters,
				period: newPeriodId,
				mortgage_product: response.data.mortgage_products[0].id,
				interest_rate_table: response.data.mortgage_products[0].interest_rate_tables[0].id,
				deviations: [houseDeviation ? houseDeviation.id : null],
			});
		},
		[currentPeriod.id, interestFilters],
	);

	const isFromArchive = useMemo(() => !!props.period, [props.period]);
	const isFuture = useMemo(
		() => !isFromArchive && currentPeriod.id !== window.data.current_period.id,
		[currentPeriod.id, isFromArchive],
	);

	const getPeriods = useCallback(async () => {
		try {
			const response = await axios.get(route("website.interest.periods"));
			setPeriods(response.data);
		} catch (e) {
			console.error(e);
		}
	}, [setPeriods]);

	const changeCurrentMortgageProduct = useCallback(
		({ value }) => {
			setLoading(true);
			const product = currentPeriod.mortgage_products.find(mortgage_product => mortgage_product.id === value);

			const houseDeviation = product.deviations ? product.deviations[0] : null;

			setCurrentMortgageProduct(product);
			setHouseDeviation(houseDeviation);
			setInterestFilters({
				...interestFilters,
				mortgage_product: value,
				interest_rate_table: product.interest_rate_tables[0].id,
				deviations: [houseDeviation ? houseDeviation.id : null],
			});
		},
		[currentPeriod, interestFilters],
	);

	const changeCurrentHouseDeviation = useCallback(
		event => {
			if (!houseDeviation) {
				return;
			}
			setLoading(true);
			const { deviations } = interestFilters;
			deviations[0] = event.target.checked ? houseDeviation.id : 0;
			setInterestFilters({ ...interestFilters, deviations });
		},
		[houseDeviation, interestFilters],
	);

	const changeCurrentSustainableDeviation = useCallback(
		selected => {
			setLoading(true);
			const { deviations } = interestFilters;
			deviations[1] = selected ? selected.value : 0;

			setInterestFilters({ ...interestFilters, deviations });
		},
		[interestFilters],
	);

	const currentRateTable = useMemo(
		() => currentMortgageProduct.interest_rate_tables.find(table => table.id === interestFilters.interest_rate_table),
		[currentMortgageProduct.interest_rate_tables, interestFilters.interest_rate_table],
	);

	const availableSustainableDeviations = useMemo(
		() =>
			currentMortgageProduct.deviations
				.filter(deviation => deviation.is_sustainable_deviation && currentRateTable.deviations.includes(deviation.id))
				.map(deviation => ({
					label: deviation.frontend_label,
					value: deviation.id,
				})),
		[currentMortgageProduct.deviations, currentRateTable.deviations],
	);

	const hasHouseDeviation = useMemo(
		() => houseDeviation && currentRateTable.deviations.includes(houseDeviation.id),
		[currentRateTable.deviations, houseDeviation],
	);

	useEffect(() => {
		getInterestRates();
	}, [getInterestRates, interestFilters]);

	useEffect(() => {
		getPeriods();
	}, [getPeriods]);

	useEffect(() => {
		axios
			.get(route("website.interest.bridging-loans", { mortgageProduct: currentMortgageProduct.id }))
			.then(({ data }) => setCurrentBridgingLoans(data))
			.catch(() => setCurrentBridgingLoans([]));
	}, [currentMortgageProduct]);

	return (
		<div className="content-block container">
			<div className={`${isFromArchive || "shadow bg-white padding"}`}>
				<h1>{props.title ? props.title : uctrans("website.interests.interest")}</h1>
				<div className="flex flex-col gap-4">
					<div className="flex lg:flex-row flex-col flex-wrap">
						<div className="lg:w-1/2 lg:flex-shrink-0 p-2">
							<LabeledDropDown
								name="mortgage-kind"
								hint={
									<ul className="list-disc pl-3">
										<li>
											De Budget Hypotheek is maximaal 6 maanden geldig, de Woning Hypotheek heeft een maximale
											geldigheid van 12 maanden.
										</li>
										<li>De rente van de Budget Hypotheek is 0,15% lager dan de Woning Hypotheek.</li>
										<li>
											De rente in de offerte van de Woning Hypotheek daalt mee als deze lager is op de ingangsdatum. Is
											de rente op de ingangsdatum hoger? Dan houden we de rente in de offerte aan.
										</li>
									</ul>
								}
								className="interest-dropdown"
								options={currentPeriod.mortgage_products.map(mortgage_product => ({
									label: mortgage_product.name,
									value: mortgage_product.id,
								}))}
								value={interestFilters.mortgage_product}
								label={uctrans("website.interests.mortgage_type")}
								onChange={changeCurrentMortgageProduct}
							/>
						</div>
						<div className="lg:w-1/2 lg:flex-shrink-0 p-2">
							<LabeledDropDown
								name="mortgage-type"
								className="interest-dropdown"
								options={currentMortgageProduct.interest_rate_tables
									.filter(
										interest_rate_table =>
											currentBridgingLoans.length && interest_rate_table.name !== currentBridgingLoans[0].name,
									)
									.map(interest_rate_table => ({
										label: interest_rate_table.name,
										value: interest_rate_table.id,
									}))}
								value={interestFilters.interest_rate_table}
								label={uctrans("website.interests.mortgage_shape")}
								onChange={({ value }) =>
									setInterestFilters({
										...interestFilters,
										interest_rate_table: value,
									})
								}
							/>
						</div>
						{hasHouseDeviation && (
							<div className="lg:w-1/2 lg:flex-shrink-0 p-2">
								<LabeledCheckbox
									name="bank_discount"
									label={uctrans("website.interests.deviation")}
									hint={uctrans("website.interests.deviation_hint")}
									placeholder={houseDeviation.frontend_label}
									checked={interestFilters.deviations[0] !== 0}
									onChange={changeCurrentHouseDeviation}
								/>
							</div>
						)}
						{availableSustainableDeviations.length > 0 && (
							<div className="lg:w-1/2 lg:flex-shrink-0 p-2">
								<LabeledDropDown
									name="sustainable-discount"
									className="interest-dropdown"
									hint={uctrans("website.interests.sustainable_hint")}
									options={availableSustainableDeviations}
									label={uctrans("website.interests.sustainable")}
									value={interestFilters.deviations[1] ?? ""}
									onChange={changeCurrentSustainableDeviation}
								/>
							</div>
						)}
					</div>
					{!isFromArchive && (
						<div className="flex flex-col sm:flex-row py-2 gap-4">
							<InterestOverviewPeriodTab
								period={window.data.current_period}
								active={currentPeriod.id === window.data.current_period.id}
								selectPeriod={selectPeriod}
							/>
							{periods.map(period => (
								<InterestOverviewPeriodTab
									key={period.id}
									period={period}
									active={currentPeriod.id === period.id}
									selectPeriod={selectPeriod}
								/>
							))}
						</div>
					)}
					{currentPeriod.settings && currentPeriod.settings.message && <div>{currentPeriod.settings.message}</div>}
					<div>
						{Object.keys(interestRates).length > 0 && currentBridgingLoans.length > 0 ? (
							<>
								<InterestOverviewTable
									interestRates={interestRates}
									isFuture={isFuture && !loading}
									expanded={expanded}
									setExpanded={setExpanded}
									shownPeriods={currentPeriod.settings ? currentPeriod.settings.shown_periods ?? {} : {}}
								/>
								<NewInterestBridgingLoans bridgingLoans={currentBridgingLoans} isFuture={isFuture} />
							</>
						) : (
							<div className="flex justify-center py-4 w-100 ">
								<Spinner width={100} />
							</div>
						)}
					</div>
					{isFuture && !loading ? (
						<ChangeLegend previousDate={format(window.data.current_period.started_at, "dd-MM-y")} />
					) : (
						<div></div>
					)}

					<div>
						<a
							href={route("website.interest.periods.pdf", {
								interestPeriod: interestFilters.period,
								interest_rate_table: interestFilters.interest_rate_table,
								mortgage_product: interestFilters.mortgage_product,
								deviations: interestFilters.deviations,
							})}
							target="_blank"
							className="button button-primary"
							rel="noreferrer">
							{uctrans("website.interests.download_interest")}
						</a>
						{isFromArchive || (
							<a href={route("website.interest.archive")} className="button button-secondary md:ml-4">
								{uctrans("website.interests.show_archive")}
							</a>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

NewInterestOverview.propTypes = {
	title: PropTypes.string,
	period: PropTypes.object,
};
