import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import { ChangeIcon, formatInterest } from "./InterestOverviewChangeIcon";
import { CSSTransition } from "react-transition-group";

export default function InterestOverviewTableRow({ values, defaultShown, forceShown, isFuture }) {
	const valueToUse = useMemo(() => (values.length > 0 ? values[0] : []), [values]);
	const monthTotal = useMemo(() => 1 * valueToUse.months + 1 * valueToUse.reflection_months, [valueToUse]);
	const displayName = () => {
		if (valueToUse.length === 0) {
			return "";
		}

		if (monthTotal < 12) {
			return `${uctrans("website.interests.variable")}`;
		}

		return `${Math.floor(monthTotal / 12)} ${trans("website.interests.year")}`;
	};

	const isShown = forceShown || defaultShown;

	const reflectionYearsText = () => {
		if (monthTotal === 24) {
			return uctrans("website.interests.has_reflection_years_2_year_set");
		}

		return uctrans("website.interests.has_:amount_reflection_years", {
			amount: Math.floor(valueToUse.reflection_months / 12),
		});
	};

	return (
		<CSSTransition in={isShown} timeout={100} classNames="table-row" unmountOnExit>
			<tr>
				<th>
					{displayName(values)}
					{!!valueToUse.reflection_months && (
						<Tippy placement="auto" arrow animation="perspective" content={reflectionYearsText()}>
							<span className="ml-2">
								<EvaIcon type="info-outline" fill="#249286" height="20" width="20" />
							</span>
						</Tippy>
					)}
				</th>
				{values.map((value, index) => (
					<td className="text-center" key={index}>
						<span className="whitespace-nowrap">{formatInterest(value.rate)}</span>
						{isFuture && value.previous_rate > 0 && <ChangeIcon interest={value} />}
					</td>
				))}
			</tr>
		</CSSTransition>
	);
}

InterestOverviewTableRow.propTypes = {
	values: PropTypes.array.isRequired,
	defaultShown: PropTypes.bool.isRequired,
	forceShown: PropTypes.bool.isRequired,
	isFuture: PropTypes.bool.isRequired,
};
