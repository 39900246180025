import Tippy from "@tippyjs/react";
import { can, route } from "../../../../../js/helpers";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";

export default function MyColleaguesOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			{can("contactperson", "current_company_create") && (
				<div className="flex mb-6">
					<div className="form-1-4">
						<a className="button button-highlightblock" href={route("website.my-collegues.create")}>
							{uctrans("my_profile.my_collegues.create_new_collegue")}
						</a>
					</div>

					<div className="form-1-3">
						<a className="button button-highlightblock" href={route("website.my-existing-colleagues")}>
							{uctrans("my_profile.my_existing_colleagues.singular")}
						</a>
					</div>
				</div>
			)}
			<AbnOverview
				initialData={overviewData}
				indexUrl={route("website.my-collegues.data")}
				deleteRouteName="website.my-collegues.delete"
				modelTranslations={{
					plural: trans("my_profile.my_collegues.plural"),
					singular: trans("my_profile.my_collegues.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="name"
							renderHeaderContent={() => uctrans("my_profile.my_collegues.fields.name")}
							renderCellContent={collegue => (
								<span>
									{collegue.name}
									{collegue.is_current_user && <small>&nbsp;({uctrans("general.yourself")})</small>}
								</span>
							)}
						/>
						<DataColumn
							width={3}
							sortable="email"
							renderHeaderContent={() => uctrans("my_profile.my_collegues.fields.email")}
							renderCellContent={collegue => collegue.email}
						/>
						<DataColumn
							width={3}
							sortable="website_role"
							renderHeaderContent={() => uctrans("my_profile.my_collegues.fields.website_role")}
							renderCellContent={collegue => collegue.website_role}
						/>
						<DataColumn
							width={3}
							sortable="employments"
							renderHeaderContent={() => uctrans("my_profile.my_collegues.fields.workingby")}
							renderCellContent={person => person.employments && person.employments.label}
						/>
						{can("contactperson", "current_company_delete") || can("contactperson", "current_company_update") ? (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(collegue, overview) => (
									<div className="overview-actions">
										{can("contactperson", "current_company_update") && (
											<a href={route("website.my-collegues.edit", collegue.id)} className="mr-3">
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.edit")}>
													<span>
														<EvaIcon type="edit-outline" fill="#249286" height="20" width="20" />
													</span>
												</Tippy>
											</a>
										)}
										{can("contactperson", "current_company_delete") && !collegue.is_current_user && (
											<a className="mr-3" onClick={() => overview.askDelete(collegue)}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.delete")}>
													<span>
														<EvaIcon type="trash-2-outline" fill="#f9acaa" height="20" width="20" />
													</span>
												</Tippy>
											</a>
										)}
									</div>
								)}
							/>
						) : (
							<DataColumn width={1} renderHeaderContent={() => ""} renderCellContent={() => ""} />
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
