import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import Dialog from "../../../../../js/react/components/general/Dialog";
import { uctrans } from "../../../../../js/lib/Translator";
import { route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import Tippy from "@tippyjs/react";
import { format } from "../../../../../js/lib/Date";
import axios from "axios";
import TrackingDownloadLink from "../TrackingDownloadLink";

export default function InterestOverview(props) {
	const interestTypes = window.data.interest_types;
	const interestType = window.data.interest_type;
	const currentPeriod = window.data.current_period;
	const newPeriod = window.data.new_period;
	const discountExceptions = interestType.discount_exceptions;
	const fixedInterestPeriods = interestType.fixed_interest_periods;
	const interestRateClasses = interestType.interest_rate_classes;
	const { bridging_loan: bridgingLoan, bridging_loan_value: bridgingLoanValue } = window.data;

	const getPeriodData = period => {
		const selectedDiscounts = new Set(period.discounts.filter(discount => discount.active_by_default_frontend)).map(
			discount => discount.id,
		);

		const values = {};

		const visibleFixedInterestPeriods = new Set();
		const visibleInterestRateClasses = new Set();

		period.interestValues.forEach(interestValue => {
			visibleFixedInterestPeriods.add(interestValue.fixed_interest_period_id);
			visibleInterestRateClasses.add(interestValue.rate_class_id);
			values[`${interestValue.fixed_interest_period_id}-${interestValue.rate_class_id}`] = interestValue;
		});

		return {
			selected_discounts: selectedDiscounts,
			available_discounts: period.discounts,
			default_values: values,
			visible_fixed_interest_periods: visibleFixedInterestPeriods,
			visible_interest_rate_classes: visibleInterestRateClasses,
		};
	};

	const calculateValuesWithDiscounts = (values, availableDiscounts, selectedDiscounts) => {
		const clonedValues = JSON.parse(JSON.stringify(values));
		const activatedDiscounts = availableDiscounts.filter(discount => selectedDiscounts.has(discount.id));

		if (activatedDiscounts.length === 0) {
			return clonedValues;
		} else {
			activatedDiscounts.forEach(discount => {
				discount.discountValues.forEach(discountValue => {
					if (
						Object.prototype.hasOwnProperty.call(
							clonedValues,
							`${discountValue.fixed_interest_period_id}-${discountValue.rate_class_id}`,
						)
					) {
						clonedValues[`${discountValue.fixed_interest_period_id}-${discountValue.rate_class_id}`].value =
							clonedValues[`${discountValue.fixed_interest_period_id}-${discountValue.rate_class_id}`].value +
							discountValue.value;
					}
				});
			});
		}

		return clonedValues;
	};

	const [newPeriodSelected, setNewPeriodSelected] = useState(false);
	const [selectedDiscounts, setSelectedDiscounts] = useState(new Set());
	const [availableDiscounts, setAvailableDiscounts] = useState([]);
	const [defaultValues, setDefaultValues] = useState({});
	const [calculatedValues, setCalculatedValues] = useState({});
	const [currentDiscountException, setCurrentDiscountException] = useState(null);
	const [visibleFixedInterestPeriods, setVisibleFixedInterestPeriods] = useState(new Set());
	const [visibleInterestRateClasses, setVisibleInterestRateClasses] = useState(new Set());

	const setValuesByPeriod = useCallback(periodData => {
		setAvailableDiscounts(periodData.available_discounts);
		setSelectedDiscounts(periodData.selected_discounts);
		setDefaultValues(periodData.default_values);
		setCalculatedValues(
			calculateValuesWithDiscounts(
				periodData.default_values,
				periodData.available_discounts,
				periodData.selected_discounts,
			),
		);
		setVisibleFixedInterestPeriods(periodData.visible_fixed_interest_periods);
		setVisibleInterestRateClasses(periodData.visible_interest_rate_classes);
	}, []);

	useEffect(() => {
		if (currentPeriod !== null) {
			const periodData = getPeriodData(currentPeriod);
			setValuesByPeriod(periodData);
		}
	}, [currentPeriod, setValuesByPeriod]);

	const changeActivePeriod = newSelectedPeriod => {
		if (newSelectedPeriod !== newPeriodSelected) {
			setNewPeriodSelected(newSelectedPeriod);
			const period = newSelectedPeriod ? newPeriod : currentPeriod;
			const periodData = getPeriodData(period);
			setValuesByPeriod(periodData);
		}
	};

	const onInterestTypeChange = interestType => {
		Nav.go(route("website.interest.show", interestType.slug));
	};

	const onDiscountSelection = discountId => {
		const newSelectedDiscounts = new Set([...selectedDiscounts]);

		newSelectedDiscounts.has(discountId)
			? newSelectedDiscounts.delete(discountId)
			: newSelectedDiscounts.add(discountId);
		let exceptionFound = false;

		discountExceptions.forEach(discountException => {
			let allDiscountsSelected = true;
			discountException.discounts.forEach(discount => {
				if (!newSelectedDiscounts.has(discount.id)) {
					allDiscountsSelected = false;
				}
			});
			if (allDiscountsSelected) {
				exceptionFound = true;
				setCurrentDiscountException(discountException);
			}
		});

		if (!exceptionFound) {
			setSelectedDiscounts(new Set([...newSelectedDiscounts]));
			setCalculatedValues(calculateValuesWithDiscounts(defaultValues, availableDiscounts, newSelectedDiscounts));
		}
	};

	const getBridgingLoanColor = (currentPeriodValue, newPeriodValue) => {
		if (currentPeriodValue === newPeriodValue) {
			return "";
		} else if (currentPeriodValue < newPeriodValue) {
			return "text-red";
		} else {
			return "text-green";
		}
	};

	const getTextColorByDifference = value => {
		if (value === 0) {
			return "";
		} else if (value > 0) {
			return "text-red";
		} else {
			return "text-green";
		}
	};

	const getDifference = (fixedInterestPeriodId, interestRateClassId) => {
		if (newPeriodSelected) {
			const currentPeriodDiscounts = currentPeriod.discounts;
			const newPeriodSelectedDiscounts = selectedDiscounts;

			let discountDifference = 0;
			newPeriodSelectedDiscounts.forEach(selectedDiscountId => {
				const newPeriodDiscount = availableDiscounts.find(discount => discount.id === selectedDiscountId);
				const currentPeriodDiscount = currentPeriodDiscounts.find(
					discount => discount.site_name === newPeriodDiscount.site_name,
				);

				if (typeof currentPeriodDiscount !== "undefined") {
					const newPeriodDiscountValue = newPeriodDiscount.discountValues.find(
						discountValue =>
							discountValue.rate_class_id === interestRateClassId &&
							discountValue.fixed_interest_period_id === fixedInterestPeriodId,
					);
					const currentPeriodDiscountValue = currentPeriodDiscount.discountValues.find(
						discountValue =>
							discountValue.rate_class_id === interestRateClassId &&
							discountValue.fixed_interest_period_id === fixedInterestPeriodId,
					);
					if (typeof currentPeriodDiscountValue !== "undefined" && typeof newPeriodDiscountValue !== "undefined") {
						const difference = newPeriodDiscountValue.value - currentPeriodDiscountValue.value;
						discountDifference += difference;
					}
				}
			});

			const currentPeriodValue = currentPeriod.interestValues.find(
				interestValue =>
					interestValue.rate_class_id === interestRateClassId &&
					interestValue.fixed_interest_period_id === fixedInterestPeriodId,
			);

			if (!currentPeriodValue) {
				return 0;
			}

			const newPeriodValue = newPeriod.interestValues.find(
				interestValue =>
					interestValue.rate_class_id === interestRateClassId &&
					interestValue.fixed_interest_period_id === fixedInterestPeriodId,
			);

			const finalValue = newPeriodValue.value - currentPeriodValue.value + discountDifference;

			if (finalValue === 0) {
				return 0;
			} else {
				return finalValue.toFixed(2);
			}
		}
		return 0;
	};

	const getPdf = async () => {
		const bridgingLoanData = getBridgingLoanData();

		let bridgingLoanText = "";
		if (bridgingLoanData.value === null) {
			bridgingLoanText = `${bridgingLoanData.prependingText} ${bridgingLoanData.appendingText}`;
		} else {
			bridgingLoanText = `${bridgingLoanData.prependingText}${bridgingLoanData.value.toFixed(2).replace(".", ",")}%${
				bridgingLoanData.appendingText
			}`;
		}

		const response = await axios.post(
			route(`website.interest.files.store`, newPeriodSelected ? newPeriod.id : currentPeriod.id),
			{
				data: {
					interest_type: interestType,
					period_date: format(
						newPeriodSelected ? window.data.new_period.start_date : window.data.current_period.start_date,
						"dd-MM-y",
					),
					calculated_values: calculatedValues,
					fixed_interest_periods: fixedInterestPeriods,
					interest_rate_classes: interestRateClasses,
					available_discounts: availableDiscounts,
					selected_discounts: [...selectedDiscounts],
					bridging_loan_text: bridgingLoanText,
					visible_fixed_interest_periods: [...visibleFixedInterestPeriods],
					visible_interest_rate_classes: [...visibleInterestRateClasses],
				},
			},
		);
		Nav.go(route(`website.interest.files.show`, response.data.download_key));
	};

	const getBridgingLoanData = () => {
		const data = {
			prependingText: "",
			appendingText: "",
			value: null,
			color: "",
			difference: 0,
		};

		if (bridgingLoan && !newPeriodSelected) {
			if (bridgingLoanValue !== null || (bridgingLoan.interest_type_id === interestType.id && newPeriodSelected)) {
				if (newPeriodSelected) {
					if (bridgingLoan.selected_type_id === interestType.id) {
						data.prependingText = `${uctrans("website.interests.bridging_loan")}: `;
						data.value = defaultValues[`${bridgingLoan.fixed_interest_period_id}-${bridgingLoan.rate_class_id}`].value;
						data.difference = data.value - bridgingLoanValue.value;
						data.color = getBridgingLoanColor(bridgingLoanValue.value, data.value);
						data.appendingText = ` (${uctrans("website.interests.variable_interest")})`;
					} else {
						data.prependingText = `${uctrans("website.interests.bridging_loan")}: ${uctrans(
							"website.interests.see_interest_for",
						)} `;
						data.appendingText = interestTypes.find(
							interestType => interestType.id === bridgingLoan.selected_type_id,
						).name;
					}
				} else {
					data.prependingText = `${uctrans("website.interests.bridging_loan")}: `;
					data.value = bridgingLoanValue.value;
					data.appendingText = ` (${uctrans("website.interests.variable_interest")})`;
				}
			} else {
				data.prependingText = `${uctrans("website.interests.bridging_loan")}: ${uctrans(
					"website.interests.see_interest_for",
				)} `;
				data.appendingText = interestTypes.find(interestType => interestType.id === bridgingLoan.selected_type_id).name;
			}
		}

		return data;
	};

	const getBridgingLoanElement = () => {
		const data = getBridgingLoanData();

		return (
			<p>
				{data.prependingText}
				{data.value !== null &&
					(data.difference === 0 ? (
						<span>{data.value.toFixed(2).replace(".", ",")}%</span>
					) : (
						<Tippy
							placement="right"
							arrow
							animation="perspective"
							duration="400"
							content={`${data.difference > 0 ? "+" : ""}${data.difference.toFixed(2)}%`}>
							<span className={data.color}>{data.value.toFixed(2).replace(".", ",")}%</span>
						</Tippy>
					))}
				{data.appendingText}
			</p>
		);
	};

	return (
		<>
			<div className="content-block container spacer">
				<div className="shadow bg-white padding">
					<div className="flex flex-col lg:flex-row">
						<div className="lg:w-full">
							<h2>{props.title ? props.title : uctrans("website.interests.interest")}</h2>
							{currentPeriod.submit_date ? (
								<small>
									{uctrans("website.interests.interest_explanation_:date", {
										date: format(currentPeriod.submit_date, "dd-MM-y"),
									})}
								</small>
							) : (
								<small>
									{uctrans("website.interests.interest_explanation_:date", {
										date: "-",
									})}
								</small>
							)}
						</div>
					</div>
					<div className="mt-4">
						<h5>{uctrans("website.interests.interest_filter_title")}</h5>
						<div className="flex flex-col lg:flex-row">
							<div className="lg:w-1/2 mt-6 lg:mt-0">
								<div className="bg-primary-lightest p-6 mr-8">
									<fieldset className="interest-selection" id="rentekortingen_fieldset">
										<h6>{uctrans("website.interests.interest_type")}</h6>
										{interestTypes.map(item => (
											<div className="form-full mb-0" key={item.id}>
												<label className="container-radio">
													<input
														type="radio"
														value={item.id}
														checked={item.id === interestType.id}
														onChange={() => onInterestTypeChange(item)}
													/>
													<span className="checkmark"></span>
													<span>{item.name}</span>
												</label>
											</div>
										))}
									</fieldset>
								</div>
							</div>
							{availableDiscounts.length > 0 && (
								<div className="lg:w-1/2 mt-6 lg:mt-0">
									<div className="bg-primary-lightest lg:h-full p-6">
										<fieldset className="interest-selection" id="rentekortingen_fieldset">
											<h6>{uctrans("website.interests.discount-storage")}</h6>
											{availableDiscounts.map(discount => (
												<div className="form-full mb-0" key={discount.id}>
													<label className="container-checkbox">
														<input
															type="checkbox"
															value={discount.id}
															checked={selectedDiscounts.has(discount.id)}
															onChange={() => {
																onDiscountSelection(discount.id);
															}}
														/>
														<span className="checkmark" />
														{discount.description !== null ? (
															<Tippy
																placement="right"
																arrow
																animation="perspective"
																duration="400"
																content={<div dangerouslySetInnerHTML={{ __html: discount.description }} />}>
																<span>{discount.site_name}</span>
															</Tippy>
														) : (
															<span>{discount.site_name}</span>
														)}
													</label>
												</div>
											))}
										</fieldset>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="divider" />
			<div className="content-block container spacer">
				<div className="shadow bg-white padding">
					<h2 className="mt-0 mb-0">{interestType.name}</h2>
					{interestType.description !== null && interestType.description !== "" && (
						<p className="mb-4" dangerouslySetInnerHTML={{ __html: interestType.description }} />
					)}
					{currentPeriod !== null ? (
						<>
							<div className="row mt-2 mb-4">
								<button
									className={newPeriodSelected ? "bg-grey-light px-10 py-4" : "bg-primary text-white px-10 py-4"}
									onClick={() => changeActivePeriod(false)}>
									<b>
										{uctrans("website.interests.interest_for")} {format(currentPeriod.start_date, "dd-MM-y")}
									</b>
								</button>
								{newPeriod !== null && (
									<button
										onClick={() => changeActivePeriod(true)}
										className={
											!newPeriodSelected ? "bg-grey-light ml-5 px-10 py-4" : "bg-primary ml-5 text-white px-10 py-4"
										}>
										<b>
											{uctrans("website.interests.interest_for")} {format(newPeriod.start_date, "dd-MM-y")}
										</b>
									</button>
								)}
							</div>

							<div className="row mt-2 mb-4">
								{newPeriodSelected ? (
									<div>
										{newPeriod &&
											newPeriod.archive_text !== "" &&
											newPeriod.archive_text.split("\n").map((line, index) => (
												<span key={index}>
													{line}
													<br />
												</span>
											))}
									</div>
								) : (
									<div>
										{currentPeriod &&
											currentPeriod.archive_text !== "" &&
											currentPeriod.archive_text.split("\n").map((line, index) => (
												<span key={index}>
													{line}
													<br />
												</span>
											))}
									</div>
								)}
							</div>

							<div className="flex">
								{calculatedValues !== null && (
									<table className="interest-table table-fixed">
										<thead>
											<tr>
												<td>
													<strong>{uctrans("website.interests.fixed_interest_period")}</strong>
												</td>
												{interestRateClasses
													.filter(interestRateClass => visibleInterestRateClasses.has(interestRateClass.id))
													.map(interestRateClass => (
														<td className="text-center" key={interestRateClass.id}>
															<strong className="mr-2">{interestRateClass.name}</strong>
															<small dangerouslySetInnerHTML={{ __html: interestRateClass.description }} />
														</td>
													))}
											</tr>
										</thead>
										<tbody>
											{fixedInterestPeriods
												.filter(fixedInterestPeriod => visibleFixedInterestPeriods.has(fixedInterestPeriod.id))
												.map(fixedInterestPeriod => (
													<tr key={fixedInterestPeriod.id}>
														<td>
															{fixedInterestPeriod.name}{" "}
															{fixedInterestPeriod.description !== "" && <>({fixedInterestPeriod.description})</>}
														</td>
														{interestRateClasses
															.filter(interestRateClass => visibleInterestRateClasses.has(interestRateClass.id))
															.map(interestRateClass => (
																<td
																	className={`${getTextColorByDifference(
																		getDifference(fixedInterestPeriod.id, interestRateClass.id),
																	)} text-center`}
																	key={calculatedValues[`${fixedInterestPeriod.id}-${interestRateClass.id}`].id}>
																	<Tippy
																		placement="right"
																		arrow
																		animation="perspective"
																		disabled={!newPeriodSelected}
																		duration="400"
																		content={`${
																			getDifference(fixedInterestPeriod.id, interestRateClass.id) > 0 ? "+" : ""
																		}${getDifference(fixedInterestPeriod.id, interestRateClass.id)}%`}>
																		<span>
																			{calculatedValues[`${fixedInterestPeriod.id}-${interestRateClass.id}`].value
																				.toFixed(2)
																				.replace(".", ",")}
																			%
																		</span>
																	</Tippy>
																</td>
															))}
													</tr>
												))}
										</tbody>
									</table>
								)}
							</div>
							{bridgingLoan !== null && <div className="row mt-8">{getBridgingLoanElement()}</div>}
							<div className="row mt-1">
								<TrackingDownloadLink
									button
									elementStyle="primary"
									onClick={() => getPdf()}
									className="button button-primary"
									text={uctrans("website.interests.download_interest")}
									targetUrl={route(`website.interest.files.store`, newPeriodSelected ? newPeriod.id : currentPeriod.id)}
								/>
							</div>
							<div className="row mt-6">
								&#x2192;&nbsp;
								<a href={route("website.interest.archive.index", interestType.slug)}>
									{uctrans("website.interests.show_archive")}
								</a>
							</div>
						</>
					) : (
						<p>{uctrans("website.interests.no_period_found")}</p>
					)}
				</div>
			</div>
			{currentDiscountException !== null && (
				<Dialog isOpen onClose={() => setCurrentDiscountException(null)}>
					<h4>{uctrans("website.interests.discount_exception_found_title")}</h4>
					<p>{uctrans("website.interests.discount_exception_found_text")}</p>
					<ul>
						{currentDiscountException.discounts.map(discount => (
							<li key={discount.id}>{discount.site_name}</li>
						))}
					</ul>
					<small>{uctrans("website.interests.discount_exception_found_sub_text")}</small>
					<div className="">
						<button
							type="button"
							className="button button-primary button-dialog"
							onClick={() => setCurrentDiscountException(null)}>
							{uctrans("general.ok")}
						</button>
					</div>
				</Dialog>
			)}
		</>
	);
}

InterestOverview.propTypes = {
	title: PropTypes.string,
};
