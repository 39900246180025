import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { format, parse } from "../../../../../js/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Dialog from "../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DebouncedInput from "../../../../../js/react/components/general/form/DebouncedInput";
import FileInput from "../../../../../js/react/components/general/form/FileInput";
import Spinner from "../../../../../js/react/components/general/Spinner";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import { route } from "../../../../../js/helpers";
import axios from "axios";

export default function ClientConsentCreateForm() {
	const [formData, setFormData] = useState({ ...window.data.client_consent.form_data });
	const handler = useGenericHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	const minCSDate = window.data.client_consent && new Date(window.data.client_consent.static_data.min_date);

	const [showLoanLengthMessage, setShowLoanLengthMessage] = useState(false);
	const [showLoanZerosMessage, setShowLoanZerosMessage] = useState(false);
	const [showUnknownLoanNumberMessage, setShowUnknownLoanNumberMessage] = useState(false);
	const [showLoanServiceError, setShowLoanServiceError] = useState(false);

	const [checkingLoanNumber, setCheckingLoanNumber] = useState(null);
	const [ufoData, setUfoData] = useState(null);

	const [showSuccesDialog, setShowSuccesDialog] = useState(false);
	const [succesRedirectRoute, setSuccesRedirectRoute] = useState(null);

	const onSuccess = response => {
		if (response.data.success) {
			setShowSuccesDialog(true);
			setSuccesRedirectRoute(response.data.redirect_route ? response.data.redirect_route : null);
		} else {
			window.location.reload();
		}
	};
	const redirectToSuccesRedirectRoute = () => {
		if (succesRedirectRoute) {
			Nav.go(succesRedirectRoute);
		} else {
			window.location.reload();
		}
	};

	const handleLoanNumberChange = value => {
		handler(value.replace(/[^0-9,-]/g, ""), "loan_number");
	};

	const checkLoanNumber = () => {
		setCheckingLoanNumber(true);
		axios
			.post(route("website.client-consents.check-loan-number"), {
				loan_number: formData.loan_number,
			})
			.then(response => {
				if (response.data && response.data.loanExists) {
					setShowUnknownLoanNumberMessage(false);
					setUfoData(response.data);
				} else {
					setShowUnknownLoanNumberMessage(true);
				}
				setCheckingLoanNumber(false);
				setShowLoanServiceError(false);
			})
			.catch(error => {
				console.error(`Errors during search: ${error}`);
				setUfoData(null);
				setCheckingLoanNumber(false);
				setShowLoanServiceError(true);
			});
	};

	useEffect(() => {
		if (formData.loan_number !== "" && formData.loan_number !== null) {
			if (formData.loan_number.length < 8 || formData.loan_number.length > 9 || formData.loan_number.startsWith("0")) {
				if (formData.loan_number.startsWith("0")) {
					setShowLoanZerosMessage(true);
				} else {
					setShowLoanZerosMessage(false);
				}
				if (formData.loan_number.length < 8 || formData.loan_number.length > 9) {
					setShowLoanLengthMessage(true);
				} else {
					setShowLoanLengthMessage(false);
				}
			} else {
				setShowLoanZerosMessage(false);
				setShowLoanLengthMessage(false);
			}
		}
		setShowUnknownLoanNumberMessage(false);
		setShowLoanServiceError(false);
	}, [formData.loan_number]);

	return (
		<AjaxForm
			method="POST"
			submitUrl={route("website.client-consents.store")}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "client_consents.singular" })}
			onSuccess={onSuccess}
			data={{ ...formData }}
			useFlashMessage>
			{ufoData === null && (
				<>
					<div className="form-container">
						<div className="form-1-2">
							<label htmlFor="loan_number">
								<Translate content="client_consents.fields.loan_number" /> <span className="text-orange">*</span>
							</label>
							<DebouncedInput
								type="number"
								name="loan_number"
								autocomplete={false}
								placeholder={uctrans("client_consents.loan_number_placeholder")}
								value={formData.loan_number ? formData.loan_number : ""}
								onChange={event => handleLoanNumberChange(event.target.value)}
								disabled={checkingLoanNumber}
							/>
							{showLoanLengthMessage && (
								<span className="italic mt-1 text-red block">{uctrans("client_consents.loan_length_message")}</span>
							)}
							{showLoanZerosMessage && (
								<span className="italic mt-1 text-red block">{uctrans("client_consents.loan_zeros_message")}</span>
							)}
							{showUnknownLoanNumberMessage && (
								<span className="italic mt-1 text-red block">
									{uctrans("client_consents.unknown_:loan_number_message", { loan_number: formData.loan_number })}
								</span>
							)}
							{showLoanServiceError && (
								<span className="italic mt-1 text-red block">{uctrans("client_consents.loan_service_error")}</span>
							)}
							{checkingLoanNumber && (
								<div className="italic mt-2 flex">
									<Spinner width={25} />
									<div className="ml-2">{uctrans("client_consents.checking_loan_number")}</div>
								</div>
							)}
						</div>
					</div>

					<div className="form-full">
						<div className="row">
							<div className="w-full md:w-1/2 lg:w-1/3 column">
								<button
									type="button"
									disabled={
										formData.loan_number === "" ||
										formData.loan_number === null ||
										showLoanLengthMessage ||
										showLoanZerosMessage ||
										showUnknownLoanNumberMessage ||
										checkingLoanNumber
									}
									className={classNames("button background-color-success button-primary mr-5", {
										disabled:
											formData.loan_number === "" ||
											formData.loan_number === null ||
											showLoanLengthMessage ||
											showLoanZerosMessage ||
											showUnknownLoanNumberMessage ||
											checkingLoanNumber,
									})}
									onClick={checkLoanNumber}>
									<span>{uctrans("client_consents.client_consent_next")}</span>
								</button>
							</div>
						</div>
					</div>
				</>
			)}

			{ufoData !== null && (
				<>
					<div className="form-container">
						<div className="form-1-2">
							<label htmlFor="loan_number">
								<Translate content="client_consents.fields.loan_number" />
							</label>
							<input type="hidden" name="loan_number" value={ufoData.loanNumber} />
							<p>{ufoData.loanNumber}</p>
						</div>
					</div>
					<div className="form-container">
						<div className="form-1-2">
							{ufoData !== null && !!ufoData.nameFirstApplicant && (
								<p>
									{uctrans("client_consents.verify_loan_belongs_to_:name", {
										name: ufoData.nameSecondApplicant
											? `${ufoData.nameFirstApplicant} & ${ufoData.nameSecondApplicant}`
											: ufoData.nameFirstApplicant,
									})}
								</p>
							)}
							{ufoData !== null && !!ufoData.activeConsentExists && (
								<p>
									<br />
									<span className="italic font-bold mt-1 block">
										{uctrans("client_consents.active_consent_exists_message")}
									</span>
								</p>
							)}
							{ufoData !== null && !!ufoData.verifyConsentExists && (
								<p>
									<br />
									<span className="italic font-bold mt-1 block">
										{uctrans("client_consents.verify_exists_message")}
									</span>
								</p>
							)}
						</div>
					</div>
					<div className="form-container ">
						<div className="form-1-2">
							<label htmlFor="file">
								{uctrans("client_consents.fields.declaration")}
								<span className="text-orange"> *</span>
							</label>
							<FileInput
								storeRoute="website.files.store"
								newFileDownloadRoute="website.files.download"
								maxFiles={1}
								onChange={files => genericHandler(files, "declaration")}
								pickAFileTranslation="client_consents.pick_a_file"
							/>
						</div>
					</div>

					<div className="form-container mb-3">
						<div className="form-1-2">
							<label htmlFor="starts_at">
								{uctrans("client_consents.fields.start_date_consent")}
								<span className="text-orange"> *</span>
								<Tippy
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("client_consents.start_date_content_explain")}>
									<span className="ml-2">
										<EvaIcon type="info" fill="#249286" height="19" width="19" />
									</span>
								</Tippy>
							</label>
							<DatePicker
								initialValue={formData.starts_at ? parse(formData.starts_at) : null}
								onChange={date => genericHandler(date ? format(date) : null, "starts_at")}
								maxDate={new Date()}
								minDate={minCSDate}
							/>
						</div>
					</div>

					<div className="form-full">
						<div className="row">
							<div className="w-full flex">
								<button
									type="button"
									className="button  button-tertiary mr-5"
									onClick={() => {
										setUfoData(null);
										setShowLoanLengthMessage(false);
										setShowLoanZerosMessage(false);
										setShowUnknownLoanNumberMessage(false);
									}}
									disabled={showSuccesDialog}>
									<span>{uctrans("client_consents.client_consent_previous")}</span>
								</button>
								<button type="submit" className="button button-primary" disabled={showSuccesDialog}>
									<span>{uctrans("client_consents.client_consent_submit")}</span>
								</button>
							</div>
						</div>
					</div>

					{showSuccesDialog && (
						<Dialog isOpen onClose={redirectToSuccesRedirectRoute}>
							<p>{uctrans("client_consents.succes_message")}</p>
							<button className="button button-primary mt-5" onClick={redirectToSuccesRedirectRoute}>
								Ok
							</button>
						</Dialog>
					)}
				</>
			)}
		</AjaxForm>
	);
}
