import forEach from "lodash/forEach";
import upperFirst from "lodash/upperFirst";
import { useCallback, useContext, useEffect, useState } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import KvkTextInput from "../../../../../../js/react/components/general/form/KvkTextInput";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import { createLoader, error } from "../../../../../../js/react/components/general/notifications";
import CooperationRequestContext from "../CooperationRequestContext";
import axios from "axios";
import loepje from "../../../../../../static/img/samenwerkingsverzoek/loepje.svg";
import trafficLightRed from "../../../../../../static/img/samenwerkingsverzoek/pr-stoplicht rood.svg";
import trafficLightGreen from "../../../../../../static/img/samenwerkingsverzoek/pr-stoplicht groen.svg";

export default function StepKvk() {
	const { cooperationRequestData, updateCooperationRequestData, setCanGoNext, setIcon } =
		useContext(CooperationRequestContext);
	const [statutoryName, setStatutoryName] = useState(null);
	const [hasKvkOriginal] = useState(Boolean(cooperationRequestData.kvk_number));
	const [statutoryNameConfirm, setStatutoryNameConfirm] = useState(null);
	const [kvkNumberLabel, setKvkNumberLabel] = useState(uctrans("website.cooperation_request.kvk_number_question"));
	const [isThrottled, setIsThrottled] = useState(false);

	const fetchStatutoryName = useCallback(async () => {
		const loader = createLoader(uctrans("website.cooperation_request.loading"));
		try {
			const response = await axios.post(
				route("website.cooperation-request.kvk", {
					token: cooperationRequestData.token,
					kvk_number: cooperationRequestData.kvk_number,
				}),
			);

			loader.success(uctrans("website.cooperation_request.fetched_data"));
			setStatutoryName(response.data.statutory_name);
			setIsThrottled(response.data.is_throttled);
			setIcon(trafficLightGreen);
		} catch (exception) {
			setIcon(trafficLightRed);

			if (exception.response) {
				if (
					exception.response.status === 422 &&
					Object.prototype.hasOwnProperty.call(exception.response.data, "errors")
				) {
					let first = true;
					forEach(exception.response.data.errors, message => {
						if (first && loader) {
							loader.failure(upperFirst(message[0]));
							first = false;
						} else {
							error(upperFirst(message[0]));
						}
					});
				} else if (exception.response.status === 404) {
					setIsThrottled(exception.response.data.is_throttled);
					setKvkNumberLabel("website.cooperation_request.kvk_number_question_retry");
					loader.failure(uctrans("website.cooperation_request.no_statutory_company_found_based_on_kvk_number"));
				}
			}
		}
	}, [cooperationRequestData.kvk_number, cooperationRequestData.token, setIcon]);

	const processConfirm = useCallback(
		value => {
			setStatutoryNameConfirm(value);
			if (value) {
				// show next button
				updateCooperationRequestData(statutoryName, "statutory_company_name");
				setCanGoNext(true);
			} else {
				// reset to start
				if (isThrottled) {
					Nav.go(route("website.cooperation-request-throttled", { token: cooperationRequestData.token }));
				} else {
					setStatutoryName(null);
					setStatutoryNameConfirm(null);
					updateCooperationRequestData("", "kvk_number");
					setKvkNumberLabel("website.cooperation_request.kvk_number_question_retry");
				}
			}
		},
		[cooperationRequestData.token, isThrottled, setCanGoNext, statutoryName, updateCooperationRequestData],
	);

	useEffect(() => {
		if (hasKvkOriginal) {
			setCanGoNext(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIcon(loepje);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="row">
			<div className="form-1-2">
				<KvkTextInput
					name="kvk_number"
					label={kvkNumberLabel}
					disabled={statutoryNameConfirm === true || hasKvkOriginal}
					value={cooperationRequestData.kvk_number || ""}
					onChange={e => updateCooperationRequestData(e.target.value, "kvk_number")}
				/>

				{!statutoryName && !hasKvkOriginal && (
					<a className="button button-primary mt-5" rel="noopener noreferrer" onClick={fetchStatutoryName}>
						{uctrans("website.cooperation_request.confirm")}
					</a>
				)}

				{statutoryName && (
					<div>
						<br />
						{uctrans("website.cooperation_request.statutory_name_confirm", { statutory_name: statutoryName })}
						<br />

						<div className="form-full">
							<RadioGroup
								name="statutory_name_confirm"
								value={statutoryNameConfirm}
								disabled={statutoryNameConfirm === true}
								onChange={processConfirm}>
								<Radio label={uctrans("general.yes")} value />
								<Radio label={uctrans("general.no")} value={false} />
							</RadioGroup>
						</div>
					</div>
				)}

				{statutoryNameConfirm === true && <span>{uctrans("website.cooperation_request.go_to_next_step")}</span>}
			</div>
		</div>
	);
}
