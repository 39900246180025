import map from "lodash/map";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";

export default function ClientConsentDetail() {
	const staticData = { ...window.data.client_consent.static_data };

	return (
		<>
			<h2 className="inline-block">
				{uctrans("client_consents.singular")} {staticData.consent.loan_number ? staticData.consent.loan_number : "-"}
			</h2>

			<div className="form-container">
				<div className="form-full">
					<ul>
						<li className="flex">
							<span className="w-1/4  font-bold">{uctrans("companies.singular")}</span>
							<span className="w-3/4 ">
								{staticData.consent.company
									? `${staticData.consent.company.name} (${staticData.consent.company.company_number})`
									: "-"}
							</span>
						</li>
						<li className="flex">
							<span className="w-1/4  font-bold">{uctrans("client_consents.fields.loan_number")}</span>
							<span className="w-3/4 ">{staticData.consent.loan_number ? staticData.consent.loan_number : "-"}</span>
						</li>
						<li className="flex">
							<span className="w-1/4  font-bold">{uctrans("client_consents.fields.client_name")}</span>
							<span className="w-3/4 ">
								{staticData.consent.name_first_applicant
									? staticData.consent.name_second_applicant
										? `${staticData.consent.name_first_applicant} & ${staticData.consent.name_second_applicant}`
										: staticData.consent.name_first_applicant
									: "-"}
							</span>
						</li>
						<li className="flex">
							<span className="w-1/4  font-bold">{uctrans("client_consents.fields.starts_at")}</span>
							<span className="w-3/4 ">
								{staticData.consent.starts_at ? format(staticData.consent.starts_at, "dd-MM-y") : "-"}
							</span>
						</li>
						<li className="flex">
							<span className="w-1/4  font-bold">{uctrans("client_consents.fields.expires_at")}</span>
							<span className="w-3/4 ">
								{staticData.consent.expires_at ? format(staticData.consent.expires_at, "dd-MM-y") : "-"}
							</span>
						</li>
						<li className="flex">
							<span className="w-1/4  font-bold">{uctrans("client_consents.fields.status")}</span>
							<span className="w-3/4 ">
								{staticData.consent.view_status_object ? staticData.consent.view_status_object.label : "-"}
							</span>
						</li>
						{!!staticData.consent.has_declaration && (
							<li className="mt-4">
								<a className="link" href={route("website.client-consents.download-decleration", staticData.consent.id)}>
									{uctrans("client_consents.download_declaration")}
								</a>
							</li>
						)}
						<li className="flex">
							{staticData.consent.active ? (
								<span className="font-bold text-green">
									<br />
									{uctrans("client_consents.consent_is_active")}
								</span>
							) : (
								<span className="font-bold text-red">
									<br />
									{uctrans("client_consents.consent_is_not_active")}
								</span>
							)}
						</li>
					</ul>
				</div>
			</div>

			<div className="form-container">
				<div className="form-1-2">
					{map(staticData.consent.site_logs, log => (
						<div key={`log_${log.id}`} className="form-container ">
							<label>
								{log.datetime
									? uctrans("general.on_:date_at_:time_by_:who", {
											date: format(log.datetime, "dd-MM-y"),
											time: format(log.datetime, "HH:mm"),
											who:
												log.actor && typeof log.actor.label !== "undefined" && log.actor.type !== "unknown"
													? `${trans("general.by")} ${log.actor.label}`
													: "",
									  })
									: "-"}
							</label>
							<p>
								{log.type
									? `${log.type.label}${
											(log.type.key === staticData.log_types.REVOKED.key ||
												log.type.key === staticData.log_types.AUTO_DEACTIVATED.key ||
												log.type.key === staticData.log_types.REJECTED.key) &&
											log.revoke_reason
												? `: ${log.revoke_reason.label}`
												: ""
									  }${
											log.type.key === staticData.log_types.DATES_CHANGED.key &&
											log.starts_at !== "" &&
											log.expires_at !== ""
												? `: ${format(log.starts_at, "dd-MM-y")} - ${format(log.expires_at, "dd-MM-y")}`
												: ""
									  }`
									: "-"}
							</p>
						</div>
					))}
				</div>
			</div>
			<div className="mt-4">
				<a className="link" href={staticData.overview_url}>
					{uctrans("client_consents.back_to_my_wallet")}
				</a>
			</div>
		</>
	);
}
