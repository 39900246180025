import { can, route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import DiscussRequestOverviewContent from "../../../../../js/react/components/discussRequests/DiscussRequestOverviewContent";
import IconButton from "../../../../../js/react/components/general/IconButton";

export default function DiscussRequestOverview() {
	return (
		<>
			{can("discuss_request.discuss_request", "create") && (
				<div className="float-right">
					<IconButton
						href={route("website.discuss-requests.create")}
						content={uctrans("general.submit_new_:item", {}, { item: "discuss_requests.singular" })}
						primary
						forWebsite
					/>
				</div>
			)}
			<DiscussRequestOverviewContent
				indexRoute="website.discuss-requests.index"
				editRoute="website.discuss-requests.edit"
			/>
		</>
	);
}
