import { uctrans } from "../../../../../js/lib/Translator";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import NewInterestArchiveYearSelection from "./NewInterestArchiveYearSelection";
import NewInterestArchivePeriodSelection from "./NewInterestArchivePeriodSelection";
import NewInterestOverview from "./NewInterestOverview";
import PropTypes from "prop-types";
import { error } from "../../../../../js/react/components/general/notifications";

export default function NewInterestArchive(props) {
	const [selectedYear, setSelectedYear] = useState(Math.max(...props.years));
	const [periods, setPeriods] = useState([]);
	const [currentPeriod, setCurrentPeriod] = useState(null);
	const [isSelectionExpanded, setIsSelectionExpanded] = useState(true);

	const selectPeriod = useCallback(
		newPeriodId => {
			if (currentPeriod && newPeriodId === currentPeriod.id) {
				return;
			}
			setCurrentPeriod(null);
			axios
				.get(route("website.interest.period", { period: newPeriodId }))
				.then(response => {
					setCurrentPeriod(response.data);
					setIsSelectionExpanded(false);
				})
				.catch(e => {
					if (e.response.status === 404) {
						error(uctrans("website.interests.no_period_found"));
					} else {
						console.error(e);
					}
				});
		},
		[currentPeriod],
	);

	const getPeriodsForYear = useCallback(() => {
		axios
			.get(
				route("website.interest.archive.periods", {
					year: selectedYear,
				}),
			)
			.then(response => {
				setPeriods(response.data);
			})
			.catch(e => {
				setPeriods([]);
				if (e.response.status === 404) {
					error(uctrans("website.interests.no_periods_for_year"));
				} else {
					console.error(e);
				}
			});
	}, [selectedYear]);

	useEffect(() => {
		getPeriodsForYear();
	}, [getPeriodsForYear]);

	return (
		<div className="content-block container">
			<div className="flex flex-col md:flex-row gap-1 md:gap-8">
				<div className="md:w-1/4 lg:w-1/5 flex flex-col gap-2 shaddow bg-white padding">
					<div className="flex justify-between">
						<h2>{uctrans("website.interests.interest_archive")}</h2>
						<button
							onClick={() => setIsSelectionExpanded(previous => !previous)}
							className={`md:hidden ${currentPeriod === null ? "hidden" : ""}`}>
							<EvaIcon
								type={`chevron-${isSelectionExpanded ? "up" : "down"}-outline`}
								fill="#249286"
								height="30"
								width="30"
							/>
						</button>
					</div>
					<div className={`${!(isSelectionExpanded || currentPeriod === null) ? "hidden " : ""}md:block`}>
						<NewInterestArchiveYearSelection
							selected={selectedYear}
							onChange={year => setSelectedYear(year.value)}
							years={props.years}
						/>
						<NewInterestArchivePeriodSelection periods={periods} onChange={selectPeriod} />
						<a
							href={route("website.interest.archive.index", { interest_type_slug: props.oldArchiveSlug })}
							className="button button-primary mt-8">
							{uctrans("website.interests.show_old_archive")}
						</a>
					</div>
				</div>
				<div className="md:w-3/4 lg:w-4/5 shaddow bg-white padding">
					{currentPeriod && (
						<NewInterestOverview period={currentPeriod} title={format(currentPeriod.started_at, "d-M-Y")} />
					)}
				</div>
			</div>
		</div>
	);
}

NewInterestArchive.propTypes = {
	years: PropTypes.array.isRequired,
	oldArchiveSlug: PropTypes.string.isRequired,
};
