import PropTypes from "prop-types";
import { format } from "../../../../../js/lib/Date";
import Tippy from "@tippyjs/react";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
export default function NewInterestArchivePeriodSelection(props) {
	return (
		<div className="flex flex-col gap-1 mt-2">
			{props.periods.map(period => (
				<div key={period.id}>
					<a href="#" onClick={() => props.onChange(period.id)}>
						<EvaIcon type="chevron-right" fill="#249286" height="17" width="17" />
						{format(period.started_at, "dd-MM-Y")}
					</a>
					{period.settings && period.settings.message && (
						<Tippy placement="auto" arrow animation="perspective" content={period.settings.message}>
							<span className="ml-2">
								<EvaIcon type="info" fill="#249286" height="19" width="19" />
							</span>
						</Tippy>
					)}
				</div>
			))}
			{props.periods.length === 0 && <div>Geen renteperioden gevonden</div>}
		</div>
	);
}

NewInterestArchivePeriodSelection.propTypes = {
	periods: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};
