import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { uctrans } from "../../../../lib/Translator";
import EvaIcon from "../EvaIcon";
import Select from "../Select";

export default function LabeledDropDown(props) {
	const [selectedValue, setSelectedValue] = useState();
	const handleFormChange = useCallback(
		selected => {
			setSelectedValue(selected);
			props.onChange(selected);
		},
		[props],
	);

	useEffect(() => {
		setSelectedValue(props.options.find(option => option.value === props.value));
	}, [props.options, props.value]);

	return (
		<>
			<label
				className="text-green-dark font-medium text-base pb-1"
				htmlFor={typeof props.id === "undefined" ? props.name : props.id}>
				{props.translateLabel ? uctrans(props.label) : props.label}{" "}
				{props.hint && (
					<Tippy placement="auto" arrow animation="perspective" content={props.hint}>
						<span className="ml-2">
							<EvaIcon type="info" fill="#249286" height="17" width="17" />
						</span>
					</Tippy>
				)}
			</label>
			<Select
				isClearable
				options={props.options}
				name={props.name}
				value={selectedValue}
				disabled={props.disabled}
				onChange={handleFormChange}
			/>
		</>
	);
}

LabeledDropDown.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	translateLabel: PropTypes.bool,
	id: PropTypes.string,
	options: PropTypes.array,
	hint: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.any,
};

LabeledDropDown.defaultProps = {
	placeholder: uctrans("general.select"),
	disabled: false,
};
