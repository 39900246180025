import tippy from "tippy.js";

const renderDefinitionTippyToClassname = to => {
	let nrRendered = 0;

	const render = () => {
		const elements = document.getElementsByClassName(to);
		for (let i = 0; i < elements.length; i++) {
			const element = elements[i];
			if (element && nrRendered < elements.length) {
				tippy(element, {
					allowHTML: true,
					content(reference) {
						const id = reference.getAttribute("data-definition-id");
						let content = "";
						if (window.data.definitions && window.data.definitions.hasOwnProperty(id)) {
							content = window.data.definitions[id];
						}
						return content;
					},
					interactive: true,
				});
				nrRendered++;
			}
		}
	};

	window.addEventListener("load", render, { once: true });
	render();
};

export { renderDefinitionTippyToClassname };
