import { useEffect, useMemo, useRef, useState } from "react";
import { can } from "../../../../js/helpers";
import EvaIcon from "../../../../js/react/components/general/EvaIcon";
import map from "lodash/map";
import classNames from "classnames";
import MenuSearchBox from "./search/MenuSearchBox";

export default function Menu() {
	const [selectedMenuitemId] = useState(window.data.selectedMenuitemId);
	const [menuItems] = useState(window.data.menuItems);
	const [menuIsActive, setMenuIsActive] = useState(false);
	const [subMenuIsActive, setSubMenuIsActive] = useState(false);
	const [extraSubMenuIsActive, setExtraSubMenuIsActive] = useState(false);
	const [activeMenuItem, setActiveMenuItem] = useState({ subMenuItems: [] });
	const [activeSubMenuItem, setActiveSubMenuItem] = useState({ subMenuItems: [] });
	const menuRef = useRef(null);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = event => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setMenuIsActive(false);
			setSubMenuIsActive(false);
		}
	};
	const hamburgerMenuClick = () => {
		setMenuIsActive(!menuIsActive);
	};

	const menuClick = menuItem => {
		if (menuItem.type === "submenu") {
			if (menuItem.submenu_items !== null) {
				setSubMenuIsActive(subMenuIsActive ? activeMenuItem !== menuItem : true);
				setActiveMenuItem(menuItem);
				setExtraSubMenuIsActive(false);
				setActiveSubMenuItem({});
			}
		}
	};

	const subMenuClick = menuItem => {
		if (menuItem.type === "extra_submenu") {
			// we only want to hide/display the menu on click if the same menu item is clicked, otherwise show the other extra_submenu with true
			setExtraSubMenuIsActive(menuItem.title === activeSubMenuItem.title ? !extraSubMenuIsActive : true);
			setActiveSubMenuItem(menuItem);
		}
	};

	const menuItemClassNames = menuItem =>
		classNames("menu-item", {
			current: `${menuItem.id}` === `${selectedMenuitemId}`,
			active: menuItem === activeMenuItem && subMenuIsActive,
			"is-expanded": subMenuIsActive && menuItem === activeMenuItem,
		});

	const subMenuItemClassNames = subMenuItem =>
		classNames("submenu-item", {
			active: subMenuItem === activeSubMenuItem && extraSubMenuIsActive,
		});

	const menuClassNames = classNames("primary-menu-container bg-primary relative z-30", {
		active: menuIsActive,
	});
	const subMenuClassNames = classNames("submenu", {
		active: subMenuIsActive,
	});

	// 'none', 'parent', 'own'

	const promo = useMemo(() => {
		if (subMenuIsActive && extraSubMenuIsActive) {
			if (activeSubMenuItem.extra_submenu_promo !== null) {
				if (activeSubMenuItem.extra_submenu_promo.type === "own") {
					return activeSubMenuItem.extra_submenu_promo;
				} else if (activeSubMenuItem.extra_submenu_promo.type === "parent") {
					return activeMenuItem.submenu_promo;
				}
			}
		} else if (subMenuIsActive && !extraSubMenuIsActive) {
			if (activeMenuItem.submenu_promo !== null) {
				if (activeMenuItem.submenu_promo.type === "own" || activeMenuItem.submenu_promo.type === null) {
					return activeMenuItem.submenu_promo;
				}
			}
		}
		return null;
	}, [activeMenuItem.submenu_promo, activeSubMenuItem.extra_submenu_promo, extraSubMenuIsActive, subMenuIsActive]);

	return (
		<>
			<div id="hamburger-icon-container" className="absolute right-4 z-30" onClick={() => hamburgerMenuClick()}>
				{menuIsActive ? (
					<EvaIcon type="close" width="30" height="30" fill="#31353e" />
				) : (
					<EvaIcon type="menu" width="30" height="30" fill="#31353e" />
				)}
			</div>
			<div ref={menuRef} className={menuClassNames}>
				<div className="container mx-auto menu">
					<ul className="primary-menu">
						{map(menuItems, (menuItem, index) => (
							<li className={menuItemClassNames(menuItem)} key={index}>
								{menuItem.type === "submenu" && menuItem.submenu_items !== null ? (
									<a onClick={() => menuClick(menuItem)}>
										{menuItem.title}{" "}
										<span className="icon-dropdown">
											<EvaIcon type="arrow-ios-downward-outline" fill="#ffffff" height="24" width="24" />
										</span>
									</a>
								) : menuItem.type === "link" && menuItem.link !== null ? (
									menuItem.link.newtab ? (
										<a href={menuItem.link.url} target="_blank" rel="noopener noreferrer">
											{menuItem.title}
										</a>
									) : (
										<a href={menuItem.link.url}>{menuItem.title}</a>
									)
								) : (
									<span>{menuItem.title}</span>
								)}
							</li>
						))}
						{can("website-search") && (
							<li className="menu-search-box">
								<MenuSearchBox />
							</li>
						)}
					</ul>

					<ul className={subMenuClassNames}>
						<li>
							<div className="container mx-auto flex relative">
								<div className="submenu-list">
									<h3 className="text-white pl-2">{activeMenuItem.title}</h3>
									<ul>
										{map(activeMenuItem.submenu_items, (subMenuItem, index) => (
											<li className={subMenuItemClassNames(subMenuItem)} key={index}>
												<div>
													<h5 className="text-white mb-0 cursor-pointer">
														{subMenuItem.type === "extra_submenu" ? (
															<a onClick={() => subMenuClick(subMenuItem)}>{subMenuItem.title} </a>
														) : subMenuItem.type === "link" && subMenuItem.link !== null ? (
															subMenuItem.link.newtab ? (
																<a href={subMenuItem.link.url} target="_blank" rel="noopener noreferrer">
																	{subMenuItem.title}
																</a>
															) : (
																<a href={subMenuItem.link.url}>{subMenuItem.title}</a>
															)
														) : (
															<span>{subMenuItem.title}</span>
														)}
													</h5>
												</div>
												{extraSubMenuIsActive && activeSubMenuItem === subMenuItem && (
													<div className="extra-submenus">
														<div className="first-extra-submenu submenu-list">
															<h3 className="text-white">{activeSubMenuItem.first_extra_submenu_title}</h3>
															<div>
																<ul>
																	{map(activeSubMenuItem.first_extra_submenu_items, (extraSubMenuItem, index) => (
																		<li className="text-white no-underline mb-3 font-condensed" key={index}>
																			<div>
																				{extraSubMenuItem.newtab ? (
																					<a
																						className="link-arrow-white"
																						href={extraSubMenuItem.url}
																						target="_blank"
																						rel="noopener noreferrer">
																						{extraSubMenuItem.text}
																					</a>
																				) : (
																					<a
																						className="link-arrow-white"
																						href={extraSubMenuItem.url}
																						rel="noopener noreferrer">
																						{extraSubMenuItem.text}
																					</a>
																				)}
																			</div>
																		</li>
																	))}
																</ul>
															</div>
														</div>
														<div className="second-extra-submenu submenu-list">
															<h3 className="text-white">{activeSubMenuItem.second_extra_submenu_title}</h3>
															<div>
																<ul>
																	{map(activeSubMenuItem.second_extra_submenu_items, (extraSubMenuItem, index) => (
																		<li className="text-white no-underline mb-3 font-condensed" key={index}>
																			<div>
																				{extraSubMenuItem.newtab ? (
																					<a
																						className="link-arrow-white"
																						href={extraSubMenuItem.url}
																						target="_blank"
																						rel="noopener noreferrer">
																						{extraSubMenuItem.text}
																					</a>
																				) : (
																					<a
																						className="link-arrow-white"
																						href={extraSubMenuItem.url}
																						rel="noopener noreferrer">
																						{extraSubMenuItem.text}
																					</a>
																				)}
																			</div>
																		</li>
																	))}
																</ul>
															</div>
														</div>
													</div>
												)}
											</li>
										))}
									</ul>
								</div>

								{promo !== null && (
									<div className="promo">
										<div className="flex items-center justify-end ml-auto">
											{promo.image !== null && (
												<div className="submenu-image text-right">
													<img src={promo.image.url} alt={promo.image.alt} />
												</div>
											)}
											<div className="submenu-image-text text-white px-4">
												{promo.title !== null && <h3 className="text-white text-lg">{promo.title}</h3>}
												{promo.html !== null && (
													<p className="mb-3 font-condensed" dangerouslySetInnerHTML={{ __html: promo.html }} />
												)}
												{promo.button !== null && (
													<a
														href={promo.button.url}
														target={promo.button.newtab ? "_blank" : "_self"}
														className="button button-primary-smaller"
														rel="noreferrer">
														{promo.button.text}
													</a>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="close-menu">
								<a onClick={() => setSubMenuIsActive(false)} />
							</div>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
}
