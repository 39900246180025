import PropTypes from "prop-types";
import { useState } from "react";
import CommunicationPreferences from "../../../../../js/react/components/communication/CommunicationPreferences";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { can, route } from "../../../../../js/helpers";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";

export default function MyUserProfileForm(props) {
	const [formData, setFormData] = useState(
		typeof props.MyProfileFormData === "undefined"
			? cloneDeep(window.data.my_profile_form_data)
			: props.MyProfileFormData,
	);
	const [staticData] = useState(
		typeof props.MyProfileStaticData === "undefined"
			? cloneDeep(window.data.my_profile_static_data)
			: props.MyProfileStaticData,
	);

	const inputHandler = useInputHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const onSuccess = () => {
		window.location.reload();
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	return (
		!isEmpty(formData) && (
			<AjaxForm
				method="PUT"
				submitUrl={route("website.my-user-profile.update")}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "my_profile.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "my_profile.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<h3>{uctrans("my_profile.fields.general_info")}</h3>
				<div className="form-full">
					<div className="form-1-6">
						<label htmlFor="type">{uctrans("my_profile.fields.salutation")}</label>
						<Select
							value={staticData.salutation_types.filter(({ value }) => Number(value) === Number(formData.salutation))}
							options={staticData.salutation_types}
							name="salutation"
							onChange={selectHandler}
							isClearable={false}
						/>
					</div>
				</div>
				<div className="form-full">
					<div className="form-1-3">
						<LabeledTextInput
							name="first_name"
							value={formData.first_name}
							onChange={inputHandler}
							label="my_profile.fields.first_name"
						/>
					</div>
					<div className="form-1-6">
						<LabeledTextInput
							name="insertion"
							value={formData.insertion}
							onChange={inputHandler}
							label="my_profile.fields.insertion"
						/>
					</div>
					<div className="form-1-3">
						<LabeledTextInput
							name="last_name"
							value={formData.last_name}
							onChange={inputHandler}
							label="my_profile.fields.last_name"
							required
						/>
					</div>
				</div>
				<h3>{uctrans("my_profile.fields.contact_info")}</h3>
				<div className="form-full">
					<div className="form-1-2">
						<LabeledTextInput
							name="email"
							label="my_profile.fields.email_address"
							value={formData.email}
							onChange={inputHandler}
							required
							disabled={!can("change-email")}
							hint={uctrans(can("change-email") ? "general.email" : "my_profile.fields.only_by_managers")}
						/>
					</div>
				</div>
				<div className="form-full">
					<div className="form-1-3">
						<LabeledTextInput
							name="mobile"
							label="my_profile.fields.mobile"
							value={formData.mobile}
							onChange={inputHandler}
						/>
					</div>
				</div>
				<div className="form-full mt-4">
					<h3 className="form-full mb-4">{uctrans("my_profile.fields.communication_preferences")}</h3>

					<div className="form-full mb-4">
						<Translate content="users.profile_communication_preferences_description" />
					</div>

					<div className="form-full mt-4">
						<CommunicationPreferences
							staticNewsLetterSubscriptionTypes={staticData.newsletter_subscription_types}
							unsubscribeReasons={staticData.unsubscribe_reasons}
							communicationPreferenceData={formData.unsubscribe_preferences}
							onPreferenceChange={value => handleFormValueChange("unsubscribe_preferences", value)}
						/>
					</div>
				</div>
				<h3 className="mt-6">{uctrans("my_profile.fields.password")}</h3>
				<div className="form-full flex mb-2">
					<div className="w-32">
						<label>{uctrans("my_profile.fields.change_password")}</label>
					</div>
					<div className="flex-1 flex flex-wrap ml-8">
						<RadioGroup
							name="change_password"
							value={formData.change_password}
							horizontal
							onChange={value => handleFormValueChange("change_password", value)}>
							<Radio label={uctrans("general.yes")} value={1} />
							<Radio label={uctrans("general.no")} value={0} />
						</RadioGroup>
					</div>
				</div>
				{formData.change_password ? (
					<>
						<div className="form-container">
							<div className="form-1-3">
								<label htmlFor="current_password">
									<Translate content="passwords.current-password" />
								</label>
								<input
									id="current_password"
									type="password"
									name="current_password"
									value={formData.current_password}
									onChange={inputHandler}
									autoComplete="off"
								/>
							</div>
						</div>
						<div className="form-container">
							<div className="form-1-3">
								<label htmlFor="new_password">
									<Translate content="passwords.new-password" />
								</label>
								<input
									id="raw_password"
									type="password"
									name="raw_password"
									onChange={inputHandler}
									autoComplete="off"
								/>
							</div>
							<div className="form-1-3">
								<label htmlFor="raw_password_confirmation">
									<Translate content="passwords.confirm-new-password" />
								</label>
								<input
									id="raw_password_confirmation"
									type="password"
									name="raw_password_confirmation"
									onChange={inputHandler}
									autoComplete="off"
								/>
							</div>
						</div>
					</>
				) : null}
				<div className="form-full">
					<div className="row">
						<div className="w-full md:w-1/2 lg:w-1/3 column">
							<SubmitBar translateLabel={false} buttonContent={uctrans("general.save")} addAnotherAvailable={false} />
						</div>
					</div>
				</div>
			</AjaxForm>
		)
	);
}
MyUserProfileForm.propTypes = {
	MyProfileFormData: PropTypes.object,
	MyProfileStaticData: PropTypes.object,
};
