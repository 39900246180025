import Tippy from "@tippyjs/react";
import axios from "axios";
import { useState } from "react";
import { can, route } from "../../../../../js/helpers";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import ContactpersonCommunicationRequestedBySelect from "../../../../../js/react/components/communication/ContactpersonCommunicationRequestedBySelect";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import ConfirmDialog from "../../../../../js/react/components/general/ConfirmDialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";

export default function MyExistingColleaguesOverview() {
	const overviewData = { ...window.data.overview };
	const [colleagueToAdd, setColleagueToAdd] = useState(null);
	const [currentOverview, setOverview] = useState(null);
	const [formData, setFormData] = useState({});

	const handleAdd = async () => {
		const loader = createLoader(uctrans("my_profile.my_existing_colleagues.create.loader"));
		try {
			await axios.put(route("website.my-existing-colleagues.update", { colleague: colleagueToAdd.id }), formData);
			loader.success(uctrans("my_profile.my_existing_colleagues.create.loader_complete"));
			setColleagueToAdd(null);
			currentOverview.loadData();
		} catch (error) {
			console.error(error);
			setColleagueToAdd(null);
			loader.failure(error.message);
		}
	};

	return (
		<>
			<AbnOverview
				initialData={overviewData}
				indexUrl={route("website.my-existing-colleagues")}
				deleteRouteName="website.my-collegues.delete"
				modelTranslations={{
					plural: trans("my_profile.my_existing_colleagues.plural"),
					singular: trans("my_profile.my_existing_colleagues.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="name"
							renderHeaderContent={() => uctrans("my_profile.my_existing_colleagues.fields.name")}
							renderCellContent={colleague => (
								<span>
									{colleague.name}
									{colleague.is_current_user && <small>&nbsp;({uctrans("general.yourself")})</small>}
								</span>
							)}
						/>
						<DataColumn
							width={3}
							sortable="email"
							renderHeaderContent={() => uctrans("my_profile.my_existing_colleagues.fields.email")}
							renderCellContent={colleague => colleague.email}
						/>
						<DataColumn
							width={3}
							sortable="website_role"
							renderHeaderContent={() => uctrans("my_profile.my_existing_colleagues.fields.website_role")}
							renderCellContent={colleague => colleague.website_role}
						/>
						<DataColumn
							width={3}
							sortable="employments"
							renderHeaderContent={() => uctrans("my_profile.my_existing_colleagues.fields.workingby")}
							renderCellContent={person => person.employments && person.employments.label}
						/>
						{can("contactperson", "current_company_delete") || can("contactperson", "current_company_update") ? (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(colleague, overview) => (
									<div className="overview-actions">
										{!colleague.in_current_company && can("contactperson", "current_company_update") && (
											<a
												className="mr-3"
												onClick={() => {
													setColleagueToAdd(colleague);
													setOverview(overview);
												}}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.add")}>
													<span>
														<EvaIcon type="plus-outline" fill="#249286" height="20" width="20" />
													</span>
												</Tippy>
											</a>
										)}

										{colleague.in_current_company && can("contactperson", "current_company_delete") && (
											<a className="mr-3" onClick={() => overview.askDelete(colleague)}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.delete")}>
													<span>
														<EvaIcon type="close-outline" fill="#f9acaa" height="20" width="20" />
													</span>
												</Tippy>
											</a>
										)}
									</div>
								)}
							/>
						) : (
							<DataColumn width={1} renderHeaderContent={() => ""} renderCellContent={() => ""} />
						)}
					</DataTable>
				)}
			/>

			{colleagueToAdd && (
				<ConfirmDialog
					onConfirm={() => handleAdd()}
					onCancel={() => setColleagueToAdd(null)}
					isOpen={!!colleagueToAdd}
					title={uctrans("my_profile.my_existing_colleagues.add", {}, { label: colleagueToAdd.name })}>
					{window.data.user && (
						<div>
							<br />
							<ContactpersonCommunicationRequestedBySelect
								name="communication_creation_requested_by_contactperson_id"
								formData={formData}
								setFormData={setFormData}
								requestedByTypeOptions={window.data.communication_creation_requested_by_type_options}
								fromCrm={false}
							/>
							<br />
						</div>
					)}
				</ConfirmDialog>
			)}
		</>
	);
}
