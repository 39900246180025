import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import PropTypes from "prop-types";
import Nav from "../../../../../js/lib/Nav";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";

export default function MessagesDashboardBlock({ title }) {
	const [NewsletterData, setNewsletterData] = useState(null);
	const [initialized, setInitialized] = useState(false);

	const [displayType, setDisplayType] = useState(window.data.contactperson ? "mine" : "all");

	const fetchNewsletters = useCallback(async () => {
		const response = await axios.get(route("website.dashboard.data", { type: "messages" }));
		setNewsletterData(response.data);
	}, []);

	useEffect(() => {
		if (!initialized) {
			fetchNewsletters();
			setInitialized(true);
		}
	}, [fetchNewsletters, initialized]);

	return (
		<div className="flex flex-col h-full">
			<h3>
				{initialized && NewsletterData != null && !!NewsletterData.url ? (
					<a href={NewsletterData.url}>{title}</a>
				) : (
					title
				)}
			</h3>

			{initialized && NewsletterData !== null && NewsletterData !== "" && (
				<>
					{!!window.data.current_site_company && (
						<div className="flex w-full justify-between mb-5">
							<span
								className={`cursor-pointer ${displayType === "all" ? "font-bold" : "text-grey-dark"}`}
								onClick={() => setDisplayType("all")}>
								{uctrans("communication.display_types.all")}
							</span>
							<span className="border-l border-gray-dark" />
							<span
								className={`cursor-pointer ${displayType === "mine" ? "font-bold" : "text-grey-dark"}`}
								onClick={() => setDisplayType("mine")}>
								{uctrans("communication.display_types.mine")}
							</span>
							<span className="border-l border-gray-dark" />
							<span
								className={`cursor-pointer ${displayType === "company" ? "font-bold" : "text-grey-dark"}`}
								onClick={() => setDisplayType("company")}>
								{uctrans("communication.display_types.company")}
							</span>
						</div>
					)}
					<ul className="mb-1">
						{NewsletterData.messages &&
						NewsletterData.messages[displayType] &&
						NewsletterData.messages[displayType].length ? (
							<>
								{NewsletterData.messages[displayType].map((message, i) => (
									<li key={i} className={`message-${i % 2 ? "odd message-text" : "even message-text"}`}>
										<div>
											<p className="message-date">{format(message.when, "dd/MM")}</p>
											<br />
											<a className="mb-1" href={route("website.messages.show", message.id)}>
												<span>
													{Number(message.read) !== Number(1) ? (
														<b>{`${message.title.slice(0, 30)}...`}</b>
													) : (
														`${message.title.slice(0, 30)}...`
													)}
												</span>
											</a>
										</div>
									</li>
								))}
							</>
						) : (
							<h5>{uctrans("communication.no_messages_to_show")}</h5>
						)}
					</ul>
					<div className="button-holder">
						<div className="mb-3">
							<a className="button button-secondary" onClick={() => Nav.go(NewsletterData.url)}>
								{uctrans("communication.to_messages")}
							</a>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

MessagesDashboardBlock.propTypes = {
	title: PropTypes.string,
};
