import { uctrans } from "../../../../../js/lib/Translator";
import { ChangeIcon } from "./InterestOverviewChangeIcon";
import PropTypes from "prop-types";

export default function ChangeLegend({ previousDate }) {
	return (
		<div className="flex flex-col gap-2">
			<div className="font-bold">{uctrans("website.interests.legend")}</div>
			<div className="flex gap-2">
				<ChangeIcon interest={{ rate: 2, previous_rate: 1 }} />
				{uctrans("website.interests.interest_rise:date", { date: previousDate })}
			</div>
			<div className="flex gap-2">
				<ChangeIcon interest={{ rate: 2, previous_rate: 2 }} />
				{uctrans("website.interests.interest_no_change")}
			</div>
			<div className="flex gap-2">
				<ChangeIcon interest={{ rate: 2, previous_rate: 3 }} />
				{uctrans("website.interests.interest_decline:date", { date: previousDate })}
			</div>
		</div>
	);
}

ChangeLegend.propTypes = {
	previousDate: PropTypes.string.isRequired,
};
