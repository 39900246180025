import Tippy from "@tippyjs/react";
import find from "lodash/find";
import PropTypes from "prop-types";
import { useState } from "react";
import { parse } from "../../../lib/Date";
import useGenericHandler from "../../hooks/useGenericHandler";
import AddressForm from "../addresses/AddressForm";
import useDateHandler from "../../hooks/useDateHandler";
import useInputHandler from "../../hooks/useInputHandler";
import useSelectHandler from "../../hooks/useSelectHandler";
import { uctrans } from "../../../../js/lib/Translator";
import AjaxForm from "../../../../js/react/components/general/AjaxForm";
import Nav from "../../../../js/lib/Nav";
import { route } from "../../../../js/helpers";
import DatePicker from "../general/DatePicker";
import EvaIcon from "../general/EvaIcon";
import FileInput from "../general/form/FileInput";
import LabeledTextarea from "../general/form/LabeledTextarea";
import LabeledTextInput from "../general/form/LabeledTextInput";
import Radio from "../general/form/radio/Radio";
import RadioGroup from "../general/form/radio/RadioGroup";
import Select from "../general/Select";
import Translate from "../general/Translate";

export default function DiscussRequestCreateFormContent(props) {
	const staticData = window.data.discuss_request_static_data;

	const [formData, setFormData] = useState({ ...window.data.discuss_request_values });
	const [category, setCategory] = useState(
		find(
			staticData.category_options,
			categoryOption => categoryOption.value === window.data.discuss_request_values.category_id,
		),
	);
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);
	const dateHandler = useDateHandler(setFormData);

	const onSuccess = () => {
		Nav.go(staticData.overview_url);
	};

	const handleFormDataChange = (key, value) => {
		setFormData({
			...formData,
			[key]: value,
		});
	};

	const handleCategoryChange = categoryId => {
		setCategory(find(staticData.category_options, categoryOption => categoryOption.value === categoryId));
		handleFormDataChange("category_id", categoryId);
	};

	return (
		<>
			<div className="flex justify-between">
				<h2 className="inline-block">{uctrans("general.submit_:item", {}, { item: "discuss_requests.singular" })}</h2>

				<a className="link" href={staticData.overview_url}>
					{uctrans("general.back_to_overview_:page", {}, { page: "discuss_requests.plural" })}
				</a>
			</div>
			<AjaxForm
				method="POST"
				submitUrl={route(props.storeRoute)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "discuss_requests.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "discuss_requests.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="form-container w-full lg:w-3/4">
					<div className="form-container">
						<div className="form-1-2">
							<label htmlFor="treating_contactperson_id">
								<Translate content="discuss_requests.fields.treating_contactperson_id" />{" "}
								<span className="text-orange">*</span>
							</label>
							<Select
								placeholder={uctrans("general.select_:item", {}, { item: "general.colleague" })}
								value={staticData.contactperson_options.filter(
									({ value }) => Number(value) === Number(formData.treating_contactperson_id),
								)}
								options={staticData.contactperson_options}
								name="treating_contactperson_id"
								onChange={selectHandler}
								isClearable={false}
							/>
						</div>
						<div className="form-1-2">
							<LabeledTextInput
								label="discuss_requests.fields.phonenumber"
								value={formData.submitter_phone}
								name="submitter_phone"
								onChange={inputHandler}
								required
							/>
						</div>
					</div>

					<div className="form-container">
						<div className="form-full">
							<label htmlFor="category_id">
								<Translate content="discuss_requests.fields.category_id" /> <span className="text-orange">*</span>
							</label>
							<RadioGroup name="category_id" value={formData.category_id} onChange={handleCategoryChange}>
								{staticData.category_options.map(category => {
									if (category.description !== "" && category.description !== null) {
										return (
											<Radio value={category.value} key={category.value} inline>
												<span>{category.label}</span>
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													interactive
													content={<span dangerouslySetInnerHTML={{ __html: category.description }} />}>
													<span className="ml-2">
														<EvaIcon type="info" fill="#249286" height="19" width="19" />
													</span>
												</Tippy>
											</Radio>
										);
									} else {
										return <Radio label={category.label} value={category.value} key={category.value} />;
									}
								})}
							</RadioGroup>
						</div>
					</div>
					{!!(category && category.non_residents_policy) && (
						<div
							className="text-xs italic mb-3"
							dangerouslySetInnerHTML={{ __html: staticData.non_residents_warning }}
						/>
					)}

					<h3>{uctrans("discuss_requests.client_data")}</h3>

					<div className="form-container">
						<div className="form-2-3">
							<LabeledTextInput
								label="discuss_requests.fields.last_name"
								value={formData.client_name}
								name="client_name"
								onChange={inputHandler}
								required
							/>
						</div>
						<div className="form-1-3">
							<label htmlFor="client_date_of_birth">
								<Translate content="discuss_requests.fields.date_of_birth" /> <span className="text-orange">*</span>
							</label>
							<DatePicker
								initialValue={formData.client_date_of_birth ? parse(formData.client_date_of_birth) : null}
								onChange={date => dateHandler(date, "client_date_of_birth")}
							/>
						</div>
					</div>
					<div className="form-container">
						<label htmlFor="client_address_type">
							<Translate content="discuss_requests.fields.address_type" /> <span className="text-orange">*</span>
						</label>
						<div className="form-full">
							<RadioGroup
								name="client_address_type"
								value={formData.client_address_type}
								onChange={value => handleFormDataChange("client_address_type", value)}>
								{staticData.address_type_options.map(addressType => (
									<Radio label={addressType.label} value={addressType.value} key={addressType.value} />
								))}
							</RadioGroup>
						</div>
					</div>
					<div className="form-container">
						<AddressForm
							addressFormData={formData.client_address}
							required
							onChange={address => handleFormDataChange("client_address", address)}
							useAddition
							useCountry={!!(category && category.non_residents_policy)}
						/>
					</div>
					{!!(category && category.non_residents_policy) && (
						<>
							<div className="form-container">
								<div className="form-full">
									<label htmlFor="is_abn_client">
										<Translate content="discuss_requests.fields.is_abn_client" /> <span className="text-orange">*</span>
									</label>
									<RadioGroup
										name="is_abn_client"
										value={formData.is_abn_client}
										onChange={value => handleFormDataChange("is_abn_client", value)}>
										<Radio label={uctrans("general.yes")} value={1} />
										<Radio label={uctrans("general.no")} value={0} />
									</RadioGroup>
								</div>
							</div>
							<div className="form-container">
								<div className="form-full">
									<label htmlFor="is_shell_buza">
										<Translate content="discuss_requests.fields.is_shell_buza" /> <span className="text-orange">*</span>
									</label>
									<RadioGroup
										name="is_shell_buza"
										value={formData.is_shell_buza}
										onChange={value => handleFormDataChange("is_shell_buza", value)}>
										<Radio label={uctrans("general.yes")} value={1} />
										<Radio label={uctrans("general.no")} value={0} />
									</RadioGroup>
								</div>
							</div>
							<div className="form-container">
								<div className="form-full">
									<label htmlFor="housebank_discount_desirable">
										<Translate content="discuss_requests.fields.housebank_discount_desirable" />{" "}
										<span className="text-orange">*</span>
									</label>
									<RadioGroup
										name="housebank_discount_desirable"
										value={formData.housebank_discount_desirable}
										onChange={value => handleFormDataChange("housebank_discount_desirable", value)}>
										<Radio label={uctrans("general.yes")} value={1} />
										<Radio label={uctrans("general.no")} value={0} />
									</RadioGroup>
								</div>
							</div>
						</>
					)}
					<h3>{uctrans("general.description")}</h3>
					{!!(category && category.non_residents_policy) && (
						<div
							className="text-xs italic mb-3"
							dangerouslySetInnerHTML={{ __html: staticData.pied_a_terre_warning }}
						/>
					)}
					<div className="form-container">
						<div className="form-full">
							<LabeledTextarea
								label="discuss_requests.fields.short_description"
								required
								value={formData.description}
								name="description"
								onChange={inputHandler}
								maxLength={250}
								showMaxLengthCounter
							/>
						</div>
					</div>

					<div className="form-container">
						<div className="form-full">
							<LabeledTextarea
								label="discuss_requests.fields.explanation"
								value={formData.explanation}
								name="explanation"
								onChange={inputHandler}
							/>
						</div>
					</div>

					<h3>{uctrans("discuss_requests.attachments")}</h3>
					<div className="form-container mb-3">
						<FileInput
							storeRoute={props.fileStoreRoute}
							newFileDownloadRoute={props.newFileDownloadRoute}
							initialFiles={formData.files}
							onChange={files => genericHandler(files, "attachments")}
							maxFiles={100}
						/>
					</div>
					<div className="form-container">
						<div className="form-full">
							<div className="submit-bar">
								<div className="submit-container">
									<button className="button background-color-success button-primary" type="submit">
										<span>
											{typeof window.data.contactperson !== "undefined"
												? uctrans("discuss_requests.save_and_send_to_mid_office")
												: uctrans("discuss_requests.save_and_send_to_company")}
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}

DiscussRequestCreateFormContent.propTypes = {
	fileStoreRoute: PropTypes.string.isRequired,
	newFileDownloadRoute: PropTypes.string,
	storeRoute: PropTypes.string.isRequired,
};
