import { useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import PageLimitSelect from "../../../../../js/react/components/general/overview/PageLimitSelect";
import Pagination from "../../../../../js/react/components/general/overview/Pagination";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import Spinner from "../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../js/react/components/general/Translate";

export default function MessageOverview() {
	const overviewData = { ...window.data.messageOverview };
	const [displayType, setDisplayType] = useState(
		window.data.displayType || (window.data.contactperson ? "mine" : "all"),
	);
	const [displayStatus, setDisplayStatus] = useState(window.data.displayStatus || "all");
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());

	useEffect(() => {
		setOverviewDataUpdatedAt(new Date().getTime());
	}, [displayType, displayStatus]);

	const onFilterChange = overview => {
		const data = { ...overview.data };
		data.metadata.pagination.page = 1;
		overview.setData(data);
	};

	const onDisplayTypeChange = (overview, value) => {
		setDisplayType(value);
		onFilterChange(overview);
	};

	const onDisplayStatusChange = (overview, value) => {
		setDisplayStatus(value);
		onFilterChange(overview);
	};

	return (
		<AbnOverview
			initialData={overviewData ? overviewData.overview : undefined}
			indexUrlAdditionalParameters={{ displayType, displayStatus }}
			dataUpdatedAt={overviewDataUpdatedAt}
			indexUrl={route("website.messages.data")}
			modelTranslations={{
				plural: trans("communication.messages"),
				singular: trans("communication.message"),
			}}
			renderFilters={() => (
				<Filters>
					<SearchBarFilter type="text" name="search" placeholder={uctrans("communication.search_in_messages")} />
				</Filters>
			)}
			renderControls={overview => (
				<div className="overview-controls">
					<div className="overview-controls flex w-full justify-between">
						<div className="flex content-center flex-wrap ">
							<div className="flex">
								<Pagination />
							</div>
							<div className="overview-info flex mt-3">
								{overview.data.metadata.pagination.total_count === 1 ? (
									uctrans("overview.1_result")
								) : (
									<Translate
										content="overview.:count_results"
										replaces={{ count: overview.data.metadata.pagination.total_count }}
									/>
								)}
							</div>
						</div>
						<div className={`${!overview.loading ? "hidden" : ""}`}>
							<Spinner width={25} />
						</div>
						<div className="flex">
							<div className="flex justify-between mr-5 mt-3">
								<div className="mr-5">
									<span className="mr-3">{uctrans("communication.display_types.tone")}:</span>
									<span
										className={`cursor-pointer ${displayStatus === "all" ? "font-bold" : "text-grey-dark"}`}
										onClick={() => onDisplayStatusChange(overview, "all")}>
										{uctrans("communication.display_status.all_messages")}
									</span>
									<span className="border-l border-gray-dark mx-2" />
									<span
										className={`cursor-pointer ${displayStatus === "read" ? "font-bold" : "text-grey-dark"}`}
										onClick={() => onDisplayStatusChange(overview, "read")}>
										{uctrans("communication.display_status.read")}
									</span>
									<span className="border-l border-gray-dark mx-2" />
									<span
										className={`cursor-pointer ${displayStatus === "unread" ? "font-bold" : "text-grey-dark"}`}
										onClick={() => onDisplayStatusChange(overview, "unread")}>
										{uctrans("communication.display_status.unread")}
									</span>
								</div>
								{!!window.data.current_site_company && (
									<div>
										<span className="mr-3">{uctrans("communication.display_types.tone")}:</span>
										<span
											className={`cursor-pointer ${displayType === "all" ? "font-bold" : "text-grey-dark"}`}
											onClick={() => onDisplayTypeChange(overview, "all")}>
											{uctrans("communication.display_types.all_messages")}
										</span>
										<span className="border-l border-gray-dark mx-2" />
										<span
											className={`cursor-pointer ${displayType === "mine" ? "font-bold" : "text-grey-dark"}`}
											onClick={() => onDisplayTypeChange(overview, "mine")}>
											{uctrans("communication.display_types.mine")}
										</span>
										<span className="border-l border-gray-dark mx-2" />
										<span
											className={`cursor-pointer ${displayType === "company" ? "font-bold" : "text-grey-dark"}`}
											onClick={() => {
												onDisplayTypeChange(overview, "company");
											}}>
											{uctrans("communication.display_types.company")}
										</span>
									</div>
								)}
							</div>
							<PageLimitSelect />
						</div>
					</div>
				</div>
			)}
			renderBody={() => (
				<DataTable>
					<DataColumn
						width={3}
						sortable="when"
						renderHeaderContent={() => uctrans("communication.communication_log.fields.date")}
						renderCellContent={message => format(message.when, "dd-MM-yyyy, HH:mm")}
					/>
					<DataColumn
						width={3}
						sortable="title"
						renderHeaderContent={() => uctrans("communication.communication_log.fields.title")}
						renderCellContent={message => (
							<a href={route("website.messages.show", message.id)} className={!message.read ? "font-bold" : ""}>
								{message.title}
							</a>
						)}
					/>
					<DataColumn
						width={3}
						sortable="receivers"
						renderHeaderContent={() => uctrans("communication.communication_log.fields.email")}
						renderCellContent={message => message.email_address}
					/>
					<DataColumn width={1} renderHeaderContent={() => ""} renderCellContent={() => ""} />
				</DataTable>
			)}
		/>
	);
}
