import { useState } from "react";
import WebsiteSearchbar from "../WebsiteSearchbar";
import SearchResults from "./SearchResults";

export default function MenuSearchBox() {
	const [searchTerm, setSearchterm] = useState("");

	return (
		<>
			<WebsiteSearchbar
				name="filters[term]"
				onChange={e => setSearchterm(e.target.value)}
				value={searchTerm}
				searchValue={setSearchterm}
			/>
			<SearchResults searchTerm={searchTerm} />
		</>
	);
}
