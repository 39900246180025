import PropTypes from "prop-types";
import { useState } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import CmsHtml from "../../../../../js/react/components/CmsHtml";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import LabeledTextarea from "../../../../../js/react/components/general/form/LabeledTextarea";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import axios from "axios";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";

export default function ClientTreatmentLandingPage(props) {
	const {
		landing_page,
		submit_url,
		already_enrolled,
		cancel_submission_url,
		update_optional_fields_url,
		reject_url,
		enrolled_participant,
	} = props;
	const [formData, setFormData] = useState(enrolled_participant);
	const inputHandler = useInputHandler(setFormData);

	const onSuccess = response => {
		if (response.data.redirect_link) {
			Nav.go(response.data.redirect_link);
		}
	};

	const cancelSubmission = async () => {
		const result = await axios.put(cancel_submission_url);

		if (result.status === 200) {
			Nav.go(result.data.redirect_link);
		}
	};

	const rejectInvite = async () => {
		const result = await axios.post(reject_url);

		if (result && result.data.success) {
			Nav.go(result.data.redirect_link);
		}
	};

	const updateAlternativeFields = async () => {
		const loader = createLoader(
			uctrans(`general.:items_are_being_saved`, {}, { items: "client_treatment.landingpage.fields.your_info" }),
		);
		try {
			await axios.put(update_optional_fields_url, {
				alternative_email: formData.alternative_email,
				notes: formData.notes,
			});
			loader.success(uctrans("general.saved_:items", {}, { items: "client_treatment.landingpage.fields.your_info" }));
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	};

	return (
		<AjaxForm
			method="POST"
			submitUrl={submit_url}
			loaderText={uctrans(
				"general.:item_is_being_processed",
				{},
				{ item: "website.landing_page.your_enrollment_request" },
			)}
			successText={uctrans("general.processed_:item", {}, { item: "website.landing_page.your_enrollment_request" })}
			onSuccess={onSuccess}
			data={{ ...formData }}>
			<div className="form-container">
				{!already_enrolled ? (
					!!landing_page.contents &&
					!!landing_page.contents.top_content_blocks_invited && (
						<div className="form-full">
							<CmsHtml html={landing_page.contents.top_content_blocks_invited} />
						</div>
					)
				) : !!landing_page.contents && !!landing_page.contents.top_content_blocks_confirmed ? (
					<div className="form-full">
						<CmsHtml html={landing_page.contents.top_content_blocks_confirmed} />
					</div>
				) : (
					!!landing_page.contents &&
					!!landing_page.contents.top_content_blocks_invited && (
						<div className="form-full">
							<CmsHtml html={landing_page.contents.top_content_blocks_invited} />
						</div>
					)
				)}

				{!!landing_page.use_alternative_email && (
					<div className="form-full mb-2">
						{!!landing_page.alternative_email_explanation && (
							<span dangerouslySetInnerHTML={{ __html: landing_page.alternative_email_explanation }} />
						)}
						<div className="form-1-3">
							<LabeledTextInput
								name="alternative_email"
								value={formData.alternative_email ? formData.alternative_email : ""}
								onChange={inputHandler}
								label={landing_page.alternative_email_title}
								translateLabel={false}
							/>
						</div>
					</div>
				)}
				{!!landing_page.contents && !!landing_page.contents.content_blocks_between_email_and_content && (
					<div className="form-full">
						<CmsHtml html={landing_page.contents.content_blocks_between_email_and_content} />
					</div>
				)}
				{!!landing_page.use_content_explanation && (
					<div className="form-full mb-2">
						{!!landing_page.content_explanation && (
							<span dangerouslySetInnerHTML={{ __html: landing_page.content_explanation }} />
						)}
						<div className="form-1-3">
							<LabeledTextarea
								name="notes"
								value={formData.notes ? formData.notes : ""}
								onChange={inputHandler}
								label={landing_page.content_explanation_title}
								translateLabel={false}
							/>
						</div>
					</div>
				)}

				{already_enrolled && (!!landing_page.use_alternative_email || !!landing_page.use_content_explanation) && (
					<div className="form-full">
						<button
							type="button"
							className="button button-tertiary mr-2"
							disabled={!formData.notes && !formData.alternative_email}
							onClick={() => updateAlternativeFields()}>
							<b>{uctrans("website.landing_page.extra_fields_save")}</b>
						</button>
					</div>
				)}

				{!!landing_page.contents && !!landing_page.contents.content_blocks_between_content_and_buttons && (
					<div className="form-full">
						<CmsHtml html={landing_page.contents.content_blocks_between_content_and_buttons} />
					</div>
				)}
				{already_enrolled ? (
					<>
						<div className="form-full">{uctrans("website.landing_page.already_enrolled_text")}</div>
						<div className="form-full">
							<a className="button button-secondary mr-2" onClick={() => cancelSubmission()}>
								<b>{uctrans("website.landing_page.cancel_text")}</b>
							</a>
						</div>
					</>
				) : (
					<div className="form-full">
						<button className="button button-secondary mr-2" type="submit">
							<b>{landing_page.present_text}</b>
						</button>

						<a className="button button-tertiary ml-2" onClick={() => rejectInvite()}>
							<b>{landing_page.absent_text}</b>
						</a>
					</div>
				)}
				{!!landing_page.contents && !!landing_page.contents.bottom_content_blocks && (
					<div className="form-full">
						<CmsHtml html={landing_page.contents.bottom_content_blocks} />
					</div>
				)}
			</div>
		</AjaxForm>
	);
}

ClientTreatmentLandingPage.propTypes = {
	landing_page: PropTypes.object,
	submit_url: PropTypes.string,
	reject_url: PropTypes.string,
	update_optional_fields_url: PropTypes.string,
	cancel_submission_url: PropTypes.string,
	already_enrolled: PropTypes.bool,
	enrolled_participant: PropTypes.object,
};
