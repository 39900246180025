import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import EvaIcon from "../../../../js/react/components/general/EvaIcon";

export default function IconLinks({ iconLinks }) {
	return (
		<>
			{iconLinks.map((iconLink, index) => {
				if (iconLink && iconLink.link) {
					return (
						<Tippy
							disabled={!iconLink.mouseover}
							key={index}
							placement="left"
							arrow
							animation="perspective"
							duration="400"
							content={iconLink.mouseover ? iconLink.mouseover : "-"}>
							<a href={iconLink.link.url} rel="noreferrer noopener" target={iconLink.link.newtab ? "_blank" : "_self"}>
								<span className="w-20 md:h-16 mr-3 md:mr-0 md:pt-3 pt-1 bg-primary rounded text-white block">
									<span className="w-full md:flex items-center justify-center hidden">
										<EvaIcon type={iconLink.type} fill="#ffffff" width="24" height="24" />
									</span>
									<span className="w-full flex items-center justify-center text-2xs">{iconLink.title}</span>
								</span>
							</a>
						</Tippy>
					);
				}
				return null;
			})}
		</>
	);
}

IconLinks.propTypes = {
	iconLinks: PropTypes.array,
};
