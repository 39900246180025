import { useCallback, useContext, useEffect, useState } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import CooperationRequestContext from "../CooperationRequestContext";
import axios from "axios";
import office from "../../../../../../static/img/samenwerkingsverzoek/pr-office.svg";

export default function StepEstablishment() {
	const {
		cooperationRequestData,
		updateCooperationRequestData,
		updateMultipleCooperationCompletionFields,
		pre_processed_data,
		setCanGoNext,
		setIcon,
	} = useContext(CooperationRequestContext);

	const [tradeNames, setTradeNames] = useState(null);
	const [chooseTradeName, setChooseTradeName] = useState(false);
	const [establishmentSelected, setEstablishmentSelected] = useState(false);

	const selectEstablishment = useCallback(
		async value => {
			updateCooperationRequestData(value, "kvk_location_number");
			setChooseTradeName(false);
			setTradeNames(null);

			try {
				const response = await axios.post(
					route("website.cooperation-request.select-establishment", {
						token: cooperationRequestData.token,
						kvk_location_number: value,
					}),
				);

				const updateFields = {
					kvk_location_number: value,
					company_street: response.data.selected_establishment.street,
					company_housenumber: response.data.selected_establishment.housenumber,
					company_housenumber_addition: response.data.selected_establishment.housenumber_addition,
					company_zipcode: response.data.selected_establishment.zipcode,
					company_city: response.data.selected_establishment.city_name,
					company_phone: response.data.selected_establishment.phonenumber,
					company_email_address: response.data.selected_establishment.email_address,
					company_website: response.data.selected_establishment.website
						? response.data.selected_establishment.website[0]
						: null,
				};

				setTradeNames(response.data.trade_names);

				if (response.data.choose_trade_name) {
					setChooseTradeName(true);
				} else {
					updateFields.company_name = response.data.trade_names;
				}

				if (response.data.selected_establishment) {
					updateMultipleCooperationCompletionFields(updateFields);

					if (!response.data.choose_trade_name) {
						setEstablishmentSelected(true);
					}
				}
			} catch (exception) {
				console.error(exception);
			}
		},
		[cooperationRequestData.token, updateCooperationRequestData, updateMultipleCooperationCompletionFields],
	);

	useEffect(() => {
		setIcon(office);
	}, [setIcon]);

	useEffect(() => {
		if (establishmentSelected === true) {
			setCanGoNext(true);
		}
	}, [establishmentSelected, setCanGoNext]);

	return (
		<div>
			{pre_processed_data.establishment_options && (
				<div>
					<p>{uctrans("website.cooperation_request.select_your_establishment")}</p>
					<RadioGroup
						name="kvk_location_number"
						value={cooperationRequestData.kvk_location_number}
						onChange={value => selectEstablishment(value)}>
						{Object.values(pre_processed_data.establishment_options).map(option => (
							<Radio
								key={option.value}
								label={`${option.label} ${!option.unknown ? "- <strong>bestaande samenwerking</strong>" : ""} ${
									option.is_main_company ? " (hoofdvestiging)" : ""
								}`}
								disabled={!option.unknown}
								value={option.value}
								dangerouslySetInnerHTML
							/>
						))}
					</RadioGroup>

					{chooseTradeName && (
						<div>
							<br />
							<h4>{uctrans("website.cooperation_request.select_tradename")}</h4>
							<span className="italic">{uctrans("website.cooperation_request.warning_tradenames")}</span>
							<RadioGroup
								name="company_name"
								value={cooperationRequestData.company_name}
								onChange={value => {
									updateCooperationRequestData(value, "company_name");
									setEstablishmentSelected(true);
								}}>
								{tradeNames.map((tradename, key) => (
									<Radio key={key} label={tradename} value={tradename} />
								))}
							</RadioGroup>
						</div>
					)}

					{establishmentSelected && (
						<div className="mt-5">
							<h4>{uctrans("website.cooperation_request.edit_kvk_fields")}</h4>
							<span>{uctrans("website.cooperation_request.kvk_fields_for_administration_explanation")}</span>
							<br />
							<strong>
								{uctrans("website.cooperation_request.kvk_fields_for_administration_explanation_warning")}
							</strong>
							<br />
							<br />
							<div>
								<div className="form-full">
									<LabeledTextInput
										name="company_street"
										value={cooperationRequestData.company_street || ""}
										onChange={e => updateCooperationRequestData(e.target.value, "company_street")}
										label="website.cooperation_request.street"
										required
									/>
									<LabeledTextInput
										name="company_housenumber"
										value={cooperationRequestData.company_housenumber || ""}
										onChange={e => updateCooperationRequestData(e.target.value, "company_housenumber")}
										label="website.cooperation_request.company_housenumber"
										required
									/>
									<LabeledTextInput
										name="company_housenumber_addition"
										value={cooperationRequestData.company_housenumber_addition || ""}
										onChange={e => updateCooperationRequestData(e.target.value, "company_housenumber_addition")}
										label="website.cooperation_request.company_housenumber_addition"
									/>
									<LabeledTextInput
										name="company_zipcode"
										value={cooperationRequestData.company_zipcode || ""}
										onChange={e => updateCooperationRequestData(e.target.value, "company_zipcode")}
										label="website.cooperation_request.company_zipcode"
										required
									/>
									<LabeledTextInput
										name="company_city"
										value={cooperationRequestData.company_city || ""}
										onChange={e => updateCooperationRequestData(e.target.value, "company_city")}
										label="website.cooperation_request.company_city"
										required
									/>
									<LabeledTextInput
										name="company_phone"
										value={cooperationRequestData.company_phone || ""}
										onChange={e => updateCooperationRequestData(e.target.value, "company_phone")}
										label="website.cooperation_request.company_phone"
										required
									/>
									<LabeledTextInput
										name="company_email_address"
										value={cooperationRequestData.company_email_address || ""}
										onChange={e => updateCooperationRequestData(e.target.value, "company_email_address")}
										label="website.cooperation_request.company_email_address"
										required
									/>
									<LabeledTextInput
										name="company_website"
										value={cooperationRequestData.company_website || ""}
										onChange={e => updateCooperationRequestData(e.target.value, "company_website")}
										label="website.cooperation_request.company_website"
										required
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
