import PropTypes from "prop-types";

export default function VideoPlayer(props) {
	try {
		// eslint-disable-next-line no-undef
		const kalturaPlayer = KalturaPlayer.setup({
			targetId: props.kaltura_id,
			provider: {
				partnerId: props.kaltura_partner_id,
				uiConfId: props.kaltura_ui_config_id,
			},
			playback: {
				autoplay: false,
			},
		});
		kalturaPlayer.loadMedia({ entryId: props.kaltura_id });
	} catch (e) {
		console.error(e.message);
	}

	return null;
}

VideoPlayer.propTypes = {
	kaltura_id: PropTypes.string,
	kaltura_ui_config_id: PropTypes.string,
	kaltura_partner_id: PropTypes.string,
};
