import map from "lodash/map";
import PropTypes from "prop-types";
import { formatInterest, ChangeIcon } from "./InterestOverviewChangeIcon";

export default function NewInterestBridgingLoans(props) {
	if (!props.bridgingLoans) return null;

	const renderBridgingLoanData = (bridgingLoan, index) => (
		<div className="w-full border-primary border-b-2 border-t-2 py-2 flex gap-4 justify-center" key={index}>
			<div className="font-bold">{bridgingLoan.name}</div>
			<div>
				{formatInterest(bridgingLoan.interest.rate)}
				{props.isFuture && <ChangeIcon interest={bridgingLoan.interest} />}
			</div>
		</div>
	);

	return map(props.bridgingLoans, (bridgingLoan, index) => renderBridgingLoanData(bridgingLoan, index));
}

NewInterestBridgingLoans.propTypes = {
	bridgingLoans: PropTypes.array.isRequired,
	isFuture: PropTypes.bool.isRequired,
};
