import Tippy from "@tippyjs/react";
import { useCallback, useState } from "react";
import { can, route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import EnergySaverForm from "../../../../../js/react/components/clientConsents/EnergySaverForm";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Dialog from "../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import CheckboxMultiFilter from "../../../../../js/react/components/general/overview/CheckboxMultiFilter";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import TextFilter from "../../../../../js/react/components/general/overview/TextFilter";
import ClientConsentManage from "../../../../../js/react/components/clientConsents/ClientConsentManage";
import LeadIcon from "../../../../../js/react/components/clientConsents/LeadIcon";
import LeadOverviewItem from "../../../../../js/react/components/clientConsents/LeadOverviewItem";
import EnergyPlugSVG from "../../../../../static/img/client_leads/energy_plug.svg";

export default function ClientConsentOverview() {
	const overviewData = { ...window.data.overview };
	const [manageDialogConsent, setManageDialogConsent] = useState(null);
	const [currentConsentEbc, setCurrentConsentEbc] = useState(null);
	const [consentEbc, setConsentEbc] = useState(false);

	const handleExport = overviewParameters => {
		Nav.go(route(`website.client-consents.export`, overviewParameters));
	};

	const closeConsentDialog = useCallback(() => {
		setCurrentConsentEbc(null);
		setConsentEbc(false);
	}, []);

	return (
		<AbnOverview
			initialData={overviewData}
			indexUrl={route("website.client-consents.data")}
			modelTranslations={{
				plural: trans("client_consents.plural"),
				singular: trans("client_consents.singular"),
			}}
			renderFilters={overview => (
				<Filters>
					<TextFilter
						hideIcon
						className="form-1-2"
						name="search"
						label={uctrans("general.search")}
						placeholder={uctrans("client_consents.search_placeholder")}
					/>
					<SelectFilter
						className="form-1-2"
						name="view_status"
						label={uctrans("client_consents.fields.consent_status")}
						options={overview.data.metadata.filterdata.view_status_options}
					/>

					<CheckboxMultiFilter
						name="lead_types"
						label={uctrans("client_consents.leads.lead_type_filter")}
						className={`overview-filter form-1-2 ${overview.getActiveFilterCount() > 0 ? "-mr-32" : "-mr-16"}`}
						checkMarkClassName="q-full"
						options={overviewData.metadata.filterdata.lead_type_options || []}
						collapsible
						renderCheckmarkLabel={option => (
							<div className="flex">
								<LeadIcon
									iconUrl={route("website.files.public-download", {
										idtoken: option.icon.id_token,
									})}
									type={option.string_key}
									className="-mt-3"
								/>
								{option.label}
							</div>
						)}
						useSelectAll
					/>
					<CheckboxMultiFilter
						name="lead_statuses"
						label={uctrans("client_consents.leads.lead_status_filter")}
						className="overview-filter form-1-2"
						checkMarkClassName="q-full"
						options={overviewData.metadata.filterdata.lead_status_options || []}
						collapsible
						useSelectAll
					/>
				</Filters>
			)}
			renderBody={() => (
				<>
					<DataTable
						renderInner={dataTable => (
							<>
								<div className="overview-header dark">
									<div
										className={`overview-header-cell text-white w-${dataTable.gridWidthPerColum[0]}/24`}
										onClick={() => DataTable.headerClick(dataTable.overview, dataTable.columnComponents[0])}>
										{dataTable.component.renderHeader(dataTable.overview, dataTable.columnComponents[0])}
									</div>
									<div
										className={`overview-header-cell text-white w-${dataTable.gridWidthPerColum[1]}/24`}
										onClick={() => DataTable.headerClick(dataTable.overview, dataTable.columnComponents[1])}>
										{dataTable.component.renderHeader(dataTable.overview, dataTable.columnComponents[1])}
									</div>
									<div
										className={`overview-header-cell text-white w-${
											dataTable.gridWidthPerColum[2] + dataTable.gridWidthPerColum[3]
										}/24 text-center`}>
										{uctrans("client_consents.singular")}
									</div>
									<div
										className={`overview-header-cell text-white w-${dataTable.gridWidthPerColum[4]}/24 text-center`}
										onClick={() => DataTable.headerClick(dataTable.overview, dataTable.columnComponents[4])}>
										{dataTable.component.renderHeader(dataTable.overview, dataTable.columnComponents[4])}
									</div>
									<div
										className={`overview-header-cell text-white w-${dataTable.gridWidthPerColum[5]}/24 text-center`}
										onClick={() => DataTable.headerClick(dataTable.overview, dataTable.columnComponents[5])}>
										{dataTable.component.renderHeader(dataTable.overview, dataTable.columnComponents[5])}
									</div>
								</div>
								<div className="overview-header">
									<div
										className={`overview-header-cell  w-${
											dataTable.gridWidthPerColum[0] + dataTable.gridWidthPerColum[1]
										}/24`}
									/>
									<div
										className={`overview-header-cell text-white w-${dataTable.gridWidthPerColum[2]}/24`}
										onClick={() => DataTable.headerClick(dataTable.overview, dataTable.columnComponents[2])}>
										{dataTable.component.renderHeader(dataTable.overview, dataTable.columnComponents[2])}
									</div>
									<div
										className={`overview-header-cell text-white w-${dataTable.gridWidthPerColum[3]}/24`}
										onClick={() => DataTable.headerClick(dataTable.overview, dataTable.columnComponents[3])}>
										{dataTable.component.renderHeader(dataTable.overview, dataTable.columnComponents[3])}
									</div>
									<div
										className={`overview-header-cell  w-${
											dataTable.gridWidthPerColum[4] + dataTable.gridWidthPerColum[5] + dataTable.gridWidthPerColum[6]
										}/24`}
									/>
								</div>
								{dataTable.overview.data.items.map((item, i) => (
									<div className="overview-item" key={i}>
										{dataTable.columnComponents.map((column, j) => (
											<div
												className={`overview-item-cell w-${dataTable.gridWidthPerColum[j]}/24 ${
													column.props.borderLeft ? "border-l" : ""
												}`}
												key={j}>
												{DataTable.renderCell(dataTable.overview, column, item)}
											</div>
										))}
									</div>
								))}
							</>
						)}>
						<DataColumn
							width={8}
							sortable="name_first_applicant"
							renderHeaderContent={() => uctrans("client_consents.fields.client_name")}
							renderCellContent={clientConsent =>
								clientConsent.active ? (
									<a
										className="link"
										href={
											clientConsent.leads.length === 0
												? `${window.data.customer_card_url}${clientConsent.loan_number}`
												: null
										}
										onClick={clientConsent.leads.length > 0 ? () => setManageDialogConsent(clientConsent) : null}>
										{clientConsent.overview_label}
									</a>
								) : (
									clientConsent.overview_label
								)
							}
						/>
						<DataColumn
							width={3}
							sortable="loan_number"
							renderHeaderContent={() => uctrans("client_consents.fields.loan_number")}
							renderCellContent={clientConsent => (clientConsent.loan_number ? clientConsent.loan_number : "-")}
						/>
						<DataColumn
							width={2}
							sortable="view_status"
							renderHeaderContent={() => uctrans("client_consents.fields.view_status")}
							renderCellContent={clientConsent => clientConsent.view_status_label}
						/>
						<DataColumn
							width={3}
							sortable="expires_at"
							renderHeaderContent={() => uctrans("client_consents.fields.expires_at")}
							renderCellContent={clientConsent => (
								<div className="w-full flex justify-start">
									{clientConsent.expires_at ? format(clientConsent.expires_at, "dd-MM-y") : "-"}
									<a className="link ml-2" href={route("website.client-consents.show", clientConsent.id)}>
										<Tippy
											placement="top"
											arrow
											animation="perspective"
											duration="400"
											content={uctrans("client_consents.show_consent")}>
											<span>
												<EvaIcon type="info" width="18" height="18" fill="#009286" />
											</span>
										</Tippy>
									</a>
								</div>
							)}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("client_consents.leads.plural")}
							renderCellContent={clientConsent =>
								!!clientConsent.active && (
									<div className="w-full flex justify-center">
										{clientConsent.leads.map((lead, i) => (
											<LeadOverviewItem
												key={i}
												lead={lead}
												fromCrm={false}
												onClick={() => setManageDialogConsent(clientConsent)}
											/>
										))}
									</div>
								)
							}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("client_consents.energy_savers_check")}
							renderCellContent={clientConsent =>
								can("client_consent.client_consent", "view") &&
								clientConsent.leads.some(lead => lead.type === "sustainability") && (
									<div className="w-full flex justify-center">
										<a className="link" onClick={() => setCurrentConsentEbc(clientConsent)}>
											<Tippy
												placement="top"
												animation="perspective"
												duration="400"
												content={uctrans("client_consents.do_energy_savers_check")}>
												<img src={EnergyPlugSVG} height={40} width={40} alt="energie_bespaar_check" />
											</Tippy>
										</a>
									</div>
								)
							}
						/>
					</DataTable>
					<Dialog width={800} isOpen={manageDialogConsent != null} onClose={() => setManageDialogConsent(null)}>
						<ClientConsentManage clientConsent={manageDialogConsent} />
					</Dialog>

					{window.data.energy_saving_refer_url && currentConsentEbc && (
						<EnergySaverForm
							closeDialog={closeConsentDialog}
							consentId={currentConsentEbc.id}
							consentEbc={consentEbc}
							onChange={checked => setConsentEbc(checked)}
							isOpen={currentConsentEbc !== null}
							energySavingReferUrl={window.data.energy_saving_refer_url}
						/>
					)}
				</>
			)}
			renderFooter={overview => (
				<a className="button button-primary mt-10" onClick={() => handleExport(overview.getParameters())}>
					{uctrans("client_consents.export")}
				</a>
			)}
		/>
	);
}
