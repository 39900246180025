import { useCallback, useContext, useMemo } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import CooperationRequestContext from "./CooperationRequestContext";
import StepAfm from "./steps/StepAfm";
import StepCompleted from "./steps/StepCompleted";
import StepContactperson from "./steps/StepContactperson";
import StepEstablishment from "./steps/StepEstablishment";
import StepKifid from "./steps/StepKifid";
import StepKvk from "./steps/StepKvk";
import StepRequested from "./steps/StepRequested";
import StepSystemQuestionnaire from "./steps/StepSystemQuestionnaire";

export default function CooperationRequestQuestionnaireFormFields() {
	const { previous_step, current_step, steps, goBack, canGoBack, canGoNext, submitForm, title, icon } =
		useContext(CooperationRequestContext);

	const questionnaireStep = useCallback(() => {
		switch (steps[current_step]) {
			case "REQUESTED":
				return <StepRequested />;
			case "KVK":
				return <StepKvk />;
			case "AFM":
				return <StepAfm />;
			case "KIFID":
				return <StepKifid />;
			case "ESTABLISHMENT":
				return <StepEstablishment />;
			case "CONTACTPERSON":
				return <StepContactperson />;
			case "SYSTEM_QUESTIONNAIRE":
				return <StepSystemQuestionnaire />;
			case "COMPLETE":
				return <StepCompleted />;
			default:
				return "unknown step";
		}
	}, [steps, current_step]);

	const questionnaireIcon = useMemo(() => {
		// Don't render the icon if we don't have an icon nor if we're on the
		// system questionnaire step as it's icons can be configured in the CMS
		if (!icon || steps[current_step] === "SYSTEM_QUESTIONNAIRE") return null;
		return <img src={icon} alt="questionnaire icon" />;
	}, [current_step, icon, steps]);

	return (
		<div>
			{questionnaireIcon}

			{steps[current_step] !== "SYSTEM_QUESTIONNAIRE" && steps[current_step] !== "COMPLETE" && title && (
				<h3>{title}</h3>
			)}

			{questionnaireStep()}

			<div className="form-full">
				<div className="row">
					<div className="w-full md:w-1/2 lg:w-1/3 column space-x-3">
						{previous_step && canGoBack && (
							<a className="button button-secondary" rel="noopener noreferrer" onClick={goBack}>
								{uctrans("website.cooperation_request.previous")}
							</a>
						)}
						{canGoNext && (
							<a className="button button-secondary" rel="noopener noreferrer" onClick={submitForm}>
								{uctrans("website.cooperation_request.next")}
							</a>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
