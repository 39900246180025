import PropTypes from "prop-types";
import { useLayoutEffect, useRef } from "react";

export default function CmsHtml({ html }) {
	const contentRef = useRef(null);

	useLayoutEffect(() => {
		window.eva.replace();
	});

	return <span className="html-content" ref={contentRef} dangerouslySetInnerHTML={{ __html: html }} />;
}
CmsHtml.propTypes = {
	html: PropTypes.string,
};

CmsHtml.defaultProps = {
	html: "",
};
