import PropTypes from "prop-types";
import { useState } from "react";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import axios from "axios";
import CmsHtml from "../../../../../js/react/components/CmsHtml";
import Spinner from "../../../../../js/react/components/general/Spinner";

export default function ClientTreatmentEnrollmentBlocks({ blockData }) {
	const [loading, setLoading] = useState(false);
	const [rejected, setRejected] = useState(false);
	const { rejection_content } = window.data;

	const dashboardImage = blockData && blockData.dashboard_image;

	const rejectInvite = async () => {
		setLoading(true);
		const result = await axios.post(blockData.reject_url);

		if (result && result.data.success) {
			setLoading(false);
			setRejected(true);
		}
	};

	const firstSubscribedEvent = blockData.firstSubscribedEvent ?? false;

	return rejected ? (
		<CmsHtml html={rejection_content} />
	) : (
		<>
			{firstSubscribedEvent ? (
				<h3>{uctrans("client_treatment.landingpage.fields.dashboard_block.first_event")}</h3>
			) : (
				<h3>{uctrans("client_treatment.landingpage.fields.dashboard_block.invite")}</h3>
			)}
			{dashboardImage && <div className="mr-2 mb-2" dangerouslySetInnerHTML={{ __html: dashboardImage }} />}

			{!!blockData.name && (
				<span>
					<b>{uctrans("client_treatment.landingpage.fields.dashboard_block.event")}: </b>
					{blockData.name}
				</span>
			)}
			{!!blockData.start && (
				<span>
					<b>{uctrans("client_treatment.landingpage.fields.dashboard_block.date")}: </b>
					{format(blockData.start, "dd-MM-y HH:mm")}
				</span>
			)}
			{!!blockData.location && (
				<span>
					<b>{uctrans("client_treatment.landingpage.fields.dashboard_block.location")}: </b>
					{blockData.location}
				</span>
			)}

			<br />
			{loading ? (
				<Spinner width={25} />
			) : (
				<div className="button-holder">
					<a className="button button-secondary mb-3" href={blockData.landing_page_url}>
						{firstSubscribedEvent ? (
							<b>{uctrans("client_treatment.landingpage.fields.dashboard_block.more_info")}</b>
						) : (
							<b>{uctrans("client_treatment.landingpage.fields.dashboard_block.more_info_and_subscription")}</b>
						)}
					</a>
					{!!blockData.webinar_url && (
						<>
							<br />
							<span>
								<a className="button button-secondary mb-3" href={blockData.webinar_url}>
									<b>{uctrans("client_treatment.landingpage.fields.dashboard_block.webinar_visit")}</b>
								</a>
							</span>
						</>
					)}
					{!firstSubscribedEvent && (
						<>
							<br />
							<a className="button button-tertiary mb-3" onClick={() => rejectInvite()}>
								<b>{uctrans("client_treatment.landingpage.fields.dashboard_block.no_i_do_not_come")}</b>
							</a>
						</>
					)}
				</div>
			)}
		</>
	);
}

ClientTreatmentEnrollmentBlocks.propTypes = {
	blockData: PropTypes.object,
};
