import Tippy from "@tippyjs/react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import MaleAvatar from "../../../../../static/img/callcenter/callcenter-agent-male.png";
import FemaleAvatar from "../../../../../static/img/callcenter/callcenter-agent-female.png";
import BuildingIcon from "../../../../../static/img/contact_icons/building_Office.svg";
import DeviceIcon from "../../../../../static/img/contact_icons/device_MobilePhone.svg";
import MailIcon from "../../../../../static/img/contact_icons/tools_EmailEnvelop.svg";
import { Carousel } from "react-responsive-carousel";

export default function ContactPage() {
	const contactData = window.data.contactData ? { ...window.data.contactData } : {};

	const chunkArrayInGroups = (arr, size) => {
		const grouped = [];
		for (let i = 0; i < arr.length; i += size) {
			grouped.push(arr.slice(i, i + size));
		}
		return grouped;
	};

	return (
		<div className="content-block container spacer">
			{!!contactData.team.title && <h2 className="text-center">{contactData.team.title}</h2>}
			{!!contactData.team.subtitle && (
				<h4 className="text-center text-black">
					<strong>{contactData.team.subtitle}</strong>
				</h4>
			)}
			<div className="flex space-x-10">
				{!!contactData.team && (
					<>
						<div className="shadow bg-white padding inner-block-content spacer vertical-spacer mb-6 w-1/2">
							<h4>{contactData.team.contact_title}</h4>
							<div className="text-sm mr-auto flex-wrap w-full">
								<strong>{contactData.team.team_intro_1}</strong>
								<br />
								{contactData.team.team_contact && (
									<>
										<p>{contactData.team.team_contact}</p>
										<br />
										<br />
									</>
								)}

								{(contactData.team.address_1 || contactData.team.address_1) && (
									<div className="flex items-start">
										<div className="ml-2.5 mr-7">
											<img src={BuildingIcon} alt="" />
										</div>
										{!!contactData.team.address_1 && (
											<>
												{contactData.team.address_1}
												<br />
											</>
										)}
										{!!contactData.team.address_2 && (
											<>
												{contactData.team.address_2}
												<br />
											</>
										)}
									</div>
								)}
								<br />
								{(contactData.team.phone || contactData.team.option || contactData.team.pincode) && (
									<div className="flex items-start">
										<div className="ml-3.5 mr-8">
											<img src={DeviceIcon} alt="" />
										</div>
										<div>
											{contactData.team.phone}
											<br />
											{contactData.team.pincode ? `Pincode: ${contactData.team.pincode}` : ""}
										</div>
									</div>
								)}
								{contactData.team.email && (
									<div className="flex items-start">
										<div className="mr-2">
											<img src={MailIcon} alt="" height={40} width={70} />
										</div>
										<a className="mt-6" href={`mailto:${contactData.team.email}`}>
											{contactData.team.email}
										</a>
									</div>
								)}
								{contactData.team.has_ms_bookings_url && (
									<div className="flex items-start">
										<div className="ml-2 mt-2">
											<a
												className="button button-primary mr-1"
												href={route("website.team-microsoft-bookings")}
												target="_blank"
												rel="noreferrer">
												{uctrans("website.contact_team_via_ms_bookings")}
											</a>
										</div>
									</div>
								)}
							</div>
							<br />
						</div>
						{!!contactData.team.other_users && (
							<div className="shadow bg-white padding inner-block-content spacer vertical-spacer mb-6 w-1/2">
								<h4 className="block text-center">Hypotheekacceptanten</h4>
								<div className="flex flex-col items-start teams-block">
									<div className="text-sm mr-auto flex-wrap w-full">
										<ul className="flex justify-start flex-wrap flex-row">
											<Carousel showArrows showThumbs={false} showStatus={false} emulateTouch>
												{!!contactData.team.users &&
													chunkArrayInGroups(contactData.team.users, 8).map((users, index) => (
														<div key={index} className="slider-container">
															{users.map(user => (
																<li key={user.id} className="slider-container-item">
																	{user.has_avatar ? (
																		<img
																			className="team-img"
																			src={route("website.user-avatar-thumbnail", user.id)}
																			alt=""
																		/>
																	) : (
																		<img className="team-img" src={user.is_male ? MaleAvatar : FemaleAvatar} />
																	)}
																	{user.personal_info ? (
																		<Tippy
																			placement="top"
																			arrow
																			animation="perspective"
																			duration="400"
																			content={<span dangerouslySetInnerHTML={{ __html: user.personal_info }} />}>
																			<h6>{user.name}</h6>
																		</Tippy>
																	) : (
																		<h6>{user.name}</h6>
																	)}
																</li>
															))}
														</div>
													))}
											</Carousel>
										</ul>
									</div>
								</div>

								{!!contactData.business_team &&
									contactData.business_team.users &&
									contactData.business_team.users.length > 0 && (
										<>
											<h4 className="block mt-6 text-center">Zakelijk inkomen specialisten</h4>
											<div className="flex flex-col items-start teams-block">
												<div className="text-sm mr-auto flex-wrap w-full">
													<ul className="flex justify-start flex-wrap flex-row">
														<Carousel
															showArrows
															showThumbs={false}
															showStatus={false}
															emulateTouch
															autoPlay
															infiniteLoop
															interval={7000}>
															{!!contactData.business_team.users &&
																chunkArrayInGroups(contactData.business_team.users, 4).map((users, index) => (
																	<div key={index} className="slider-container">
																		{users.map(user => (
																			<li key={user.id} className="slider-container-item">
																				{user.has_avatar ? (
																					<img
																						className="team-img"
																						src={route("website.user-avatar-thumbnail", user.id)}
																						alt=""
																					/>
																				) : (
																					<img className="team-img" src={user.is_male ? MaleAvatar : FemaleAvatar} />
																				)}
																				{user.personal_info ? (
																					<Tippy
																						placement="top"
																						arrow
																						animation="perspective"
																						duration="400"
																						content={<span dangerouslySetInnerHTML={{ __html: user.personal_info }} />}>
																						<h6>{user.name}</h6>
																					</Tippy>
																				) : (
																					<h6>{user.name}</h6>
																				)}
																			</li>
																		))}
																	</div>
																))}
														</Carousel>
													</ul>
												</div>
											</div>
										</>
									)}
							</div>
						)}
					</>
				)}
			</div>

			{!!contactData.as.name && <h2 className="text-center">{contactData.as.name}</h2>}
			{!!contactData.as.title && (
				<h4 className="text-center text-black">
					<strong>{contactData.as.title}</strong>
				</h4>
			)}
			<div className="flex space-x-10">
				{!!contactData.team && (
					<>
						<div className="shadow bg-white padding inner-block-content spacer vertical-spacer mb-6 w-1/2">
							<h4>{contactData.team.head_title}</h4>
							<div className="flex flex-col items-start">
								<div className="text-sm mr-auto flex-wrap w-full">
									<strong>{contactData.team.team_intro_2}</strong>
									<br />
									{contactData.team.as_contact && (
										<>
											<p>{contactData.team.as_contact}</p>
											<br />
											<br />
										</>
									)}

									{(contactData.as.address_1 || contactData.as.address_2) && (
										<div className="flex items-start">
											<div className="ml-2.5 mr-7">
												<img src={BuildingIcon} alt="" />
											</div>
											{!!contactData.as.address_1 && (
												<>
													{contactData.as.address_1}
													<br />
												</>
											)}
											{!!contactData.as.address_2 && (
												<>
													{contactData.as.address_2}
													<br />
												</>
											)}
										</div>
									)}
									<br />
									{(contactData.as.phone || contactData.as.option || contactData.as.picode) && (
										<div className="flex items-start">
											<div className="ml-3.5 mr-8">
												<img src={DeviceIcon} alt="" />
											</div>
											<div>
												{contactData.as.phone}
												<br />
											</div>
										</div>
									)}
									{contactData.as.email && (
										<div className="flex items-start">
											<div className="mr-2">
												<img src={MailIcon} alt="" height={40} width={70} />
											</div>
											<a className="mt-6" href={`mailto:${contactData.as.email}`}>
												{contactData.as.email}
											</a>
										</div>
									)}

									<br />
									{!!contactData.pp && (
										<div className="text-sm mr-auto flex-wrap w-full">
											<strong>{contactData.pp.name}</strong>
											<br />
											<br />

											{(contactData.pp.address_1 || contactData.pp.address_2) && (
												<div className="flex items-start">
													<div className="ml-2.5 mr-7">
														<img src={BuildingIcon} alt="" />
													</div>
													{!!contactData.pp.address_1 && (
														<>
															{contactData.pp.address_1}
															<br />
														</>
													)}
													{!!contactData.pp.address_2 && (
														<>
															{contactData.pp.address_2}
															<br />
														</>
													)}
												</div>
											)}
											{(contactData.pp.phone || contactData.pp.option || contactData.pp.picode) && (
												<div className="flex items-start">
													<div className="ml-3.5 mr-8">
														<img src={DeviceIcon} alt="" />
													</div>
													<div>
														{contactData.pp.phone}
														<br />
														(optie {contactData.pp.option})
													</div>
												</div>
											)}
											{contactData.pp.email && (
												<div className="flex items-start">
													<div className="mr-2">
														<img src={MailIcon} alt="" height={40} width={70} />
													</div>
													<a className="mt-6" href={`mailto:${contactData.pp.email}`}>
														{contactData.pp.email}
													</a>
												</div>
											)}
										</div>
									)}
								</div>
								<br />
							</div>
						</div>
						{!!contactData.as && (
							<div className="shadow bg-white padding inner-block-content spacer vertical-spacer mb-6 w-1/2">
								<h4>{contactData.as.team_name}</h4>
								<div className="flex flex-col items-start">
									<div className="text-sm mr-auto flex-wrap w-full teams-block">
										<ul className="flex justify-start flex-wrap flex-row">
											<Carousel showArrows showThumbs={false} showStatus={false} emulateTouch>
												{!!contactData.as.users &&
													chunkArrayInGroups(contactData.as.users, 8).map((users, index) => (
														<div key={index} className="slider-container">
															{users.map(user => (
																<li key={user.id} className="slider-container-item">
																	{user.has_avatar ? (
																		<img
																			className="team-img"
																			src={route("website.user-avatar-thumbnail", user.id)}
																			alt=""
																		/>
																	) : (
																		<img className="team-img" src={user.is_male ? MaleAvatar : FemaleAvatar} />
																	)}
																	{user.personal_info ? (
																		<Tippy
																			placement="top"
																			arrow
																			animation="perspective"
																			duration="400"
																			content={<span dangerouslySetInnerHTML={{ __html: user.personal_info }} />}>
																			<h6>{user.name}</h6>
																		</Tippy>
																	) : (
																		<h6>{user.name}</h6>
																	)}
																</li>
															))}
														</div>
													))}
											</Carousel>
										</ul>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
}
