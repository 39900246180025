import PropTypes from "prop-types";
import Dialog from "../../../../js/react/components/general/Dialog";
import classNames from "classnames";

export default function WebsitePopups({ popups }) {
	const getButton = part => (
		<a
			href={part.link && part.link.url}
			target={part.link && part.link.newtab ? "_blank" : null}
			rel="noreferrer"
			className={classNames("button", {
				"button-primary": part.type === "primary",
				"button-secondary": part.type === "secondary",
				"button-tertiary": part.type === "tertiary",
			})}>
			{part.text}
		</a>
	);

	return popups.map((popup, key) => (
		<Dialog isOpen key={key}>
			{popup.type && popup.type === "birthday" ? (
				<div className="text-center">
					<h1>{popup.title}</h1>
					{popup.image && (
						<div className="w-full">
							<img className="m-auto" src={popup.image.url} alt={popup.image.alt} />
						</div>
					)}
					<div className="html-content mt-4" dangerouslySetInnerHTML={{ __html: popup.content }} />
				</div>
			) : (
				<>
					<h4>{popup.title}</h4>
					{popup.image && (
						<div className="w-full">
							{popup.image.use_image_link && popup.image.link ? (
								<a href={popup.image.link.url} target={popup.image.link.newtab ? "_blank" : null} rel="noreferrer">
									<img className="m-auto" src={popup.image.url} alt={popup.image.alt} />
								</a>
							) : (
								<img className="m-auto" src={popup.image.url} alt={popup.image.alt} />
							)}
						</div>
					)}
					<div className="html-content mt-4" dangerouslySetInnerHTML={{ __html: popup.content }} />
					{popup.parts &&
						popup.parts.map((part, index) => (
							<>
								{part.parttype === "button" && (
									<div className="mt-1" key={index}>
										{part.parttype === "button" && part.contents && getButton(part.contents)}
									</div>
								)}
								{part.parttype === "button_set" && part.contents && (
									<div className="mt-1" key={index}>
										<div className="button-holder">
											{Object.values(part.contents).map((element, index) => (
												<div className="mb-3" key={index}>
													{getButton(element)}
												</div>
											))}
										</div>
									</div>
								)}
							</>
						))}
				</>
			)}
		</Dialog>
	));
}

WebsitePopups.propTypes = {
	popups: PropTypes.array.isRequired,
};
