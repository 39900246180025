import { useEffect, useRef, useState } from "react";
import { can, route } from "../../../../js/helpers";
import { uctrans } from "../../../../js/lib/Translator";
import EvaIcon from "../../../../js/react/components/general/EvaIcon";

export default function UserMenu() {
	const loggedInUserType = window.data.logged_in_user_type ?? null;
	const loggedInUser = loggedInUserType ? window.data[loggedInUserType] : null;
	const currentsiteCompany = window.data.current_site_company ?? null;

	const [menuOpen, setMenuOpen] = useState(false);
	const menuRef = useRef(null);

	const iconWidth = "18";

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	const handleClick = e => {
		if (!(menuRef && menuRef.current && e.target && menuRef.current.contains(e.target))) {
			setMenuOpen(false);
		}
	};

	return loggedInUser ? (
		<>
			<div className="flex cursor-pointer items-center mt-1 -mb-1" onClick={toggleMenu}>
				<div>
					{loggedInUser.avatar_url ? (
						<div className="relative w-12 h-12">
							<img className="rounded-full border border-gray-100" src={loggedInUser.avatar_url} alt="user image" />
							{loggedInUser.message_count ? (
								<div className="absolute -top-1 -left-1 h-5 w-5 my-1 rounded-full bg-secondary z-2 text-grey-darker text-xs align-middle justify-center items-center inline-flex font-bold">
									{loggedInUser.message_count}
								</div>
							) : null}
						</div>
					) : (
						<div className="relative border-primary bg-grey-light text-primary w-12 h-12 rounded-full inline-flex items-center align-middle justify-center text-xl">
							{loggedInUser.avatar_initials}
							{loggedInUser.message_count ? (
								<div className="absolute -top-1 -left-2 h-5 w-5 my-1 rounded-full bg-secondary  z-2 text-grey-darker text-xs align-middle justify-center items-center inline-flex font-bold">
									{loggedInUser.message_count}
								</div>
							) : null}
						</div>
					)}
				</div>
				<div className="align-middle text-sm ml-2">
					<span className="text-primary-dark font-medium">{loggedInUser.label}</span>
					<br />
					{currentsiteCompany ? (
						<span>{currentsiteCompany.name}</span>
					) : can("company", "select_companies") ? (
						<span className="text-grey">{uctrans("website.no_company")}</span>
					) : null}
				</div>
			</div>
			{menuOpen && (
				<div
					ref={menuRef}
					className="absolute border border-gray bg-white mt-3 font-condensed text-base user-menu-submenu">
					<div className="p-6">
						<ul>
							{(currentsiteCompany || loggedInUserType !== "contactperson") && window.data.my_profile_page_url ? (
								<li className="mb-2">
									<a href={window.data.my_profile_page_url}>
										<h5 className="font-normal">
											<EvaIcon type="person" width={iconWidth} height={iconWidth} fill="#009286" />{" "}
											{uctrans("website.my_profile")}
										</h5>
									</a>
								</li>
							) : null}
							{(currentsiteCompany || loggedInUserType !== "contactperson") && window.data.messages_page_url ? (
								<li className="mb-2 relative">
									<a href={window.data.messages_page_url}>
										<h5 className="font-normal">
											<EvaIcon type="message-square" width={iconWidth} height={iconWidth} fill="#009286" />{" "}
											{uctrans("website.messages")}
										</h5>
										{loggedInUser.message_count ? (
											<div className="absolute -top-1 -left-3 h-5 w-5 rounded-full bg-secondary z-2 text-grey-darker text-xs align-middle justify-center items-center flex font-bold">
												{loggedInUser.message_count}
											</div>
										) : null}
									</a>
								</li>
							) : null}
							{(currentsiteCompany || loggedInUserType !== "contactperson") && window.data.app_info_page_url ? (
								<li className="mb-2">
									<a href={window.data.app_info_page_url}>
										<h5 className="font-normal">
											<EvaIcon type="smartphone" width={iconWidth} height={iconWidth} fill="#009286" />{" "}
											{uctrans("website.intermediary_app")}
										</h5>
									</a>
								</li>
							) : null}
							{currentsiteCompany ? (
								<>
									{can("company", "select_companies") &&
									(can("company", "select_all_companies") ||
										(loggedInUserType === "contactperson" && loggedInUser.active_employments.length > 1)) ? (
										<li className="mb-2">
											<a href={route("website.deselect-company")}>
												<h5 className="font-normal">
													<EvaIcon type="swap" width={iconWidth} height={iconWidth} fill="#009286" />{" "}
													{uctrans("website.change_company")}
												</h5>
											</a>
										</li>
									) : null}
									{window.data.my_company_page_url ? (
										<li className="mb-2">
											<a href={window.data.my_company_page_url}>
												<h5 className="font-normal">
													<EvaIcon type="home" width={iconWidth} height={iconWidth} fill="#009286" />{" "}
													{uctrans("website.company_info")}
												</h5>
											</a>
										</li>
									) : null}
									{window.data.organisation_intermediaries_url ? (
										<li className="mb-2">
											<a href={window.data.organisation_intermediaries_url}>
												<h5 className="font-normal">
													<EvaIcon type="flag" width={iconWidth} height={iconWidth} fill="#009286" />{" "}
													{uctrans("website.organisation_intermediaries")}
												</h5>
											</a>
										</li>
									) : null}
									{window.data.my_colleagues_page_url ? (
										<li className="mb-2">
											<a href={window.data.my_colleagues_page_url}>
												<h5 className="font-normal">
													<EvaIcon type="people" width={iconWidth} height={iconWidth} fill="#009286" />{" "}
													{uctrans("website.my_colleagues")}
												</h5>
											</a>
										</li>
									) : null}
									<li className="my-4">
										<hr />
									</li>
									{currentsiteCompany.company_number ? (
										<li>
											<h6 className="font-normal mb-0 flex justify-between">
												{uctrans("intermediaries.fields.company_number")}:{" "}
												<b className="font-bold">{currentsiteCompany.company_number}</b>
											</h6>
										</li>
									) : null}
									<li>
										<h6 className="font-normal mb-0 flex justify-between">
											{uctrans("website.pincode_phone")}: <b className="font-bold">{currentsiteCompany.pincode}</b>
										</h6>
									</li>
								</>
							) : can("company", "select_companies") ? (
								<li className="mb-2">
									<a href={route("website.company-selection")}>
										<h5 className="font-normal">
											<EvaIcon type="home" width={iconWidth} height={iconWidth} fill="#009286" />{" "}
											{uctrans("website.choose_company")}
										</h5>
									</a>
								</li>
							) : null}
						</ul>
					</div>
					<div className="bg-grey-light">
						<div className="px-6 py-3">
							<a href={route("website.auth.logout")}>
								<h5 className="font-normal mb-0">
									<EvaIcon type="log-out" width={iconWidth} height={iconWidth} fill="#009286" />{" "}
									{uctrans("website.logout")}
								</h5>
							</a>
						</div>
					</div>
				</div>
			)}
		</>
	) : null;
}
