import { useCallback, useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import SearchBar from "../../../../../js/react/components/general/SearchBar";
import Spinner from "../../../../../js/react/components/general/Spinner";
import axios from "axios";

export default function ErrorSearchBox() {
	const [searchTerm, setSearchterm] = useState("");
	const [searching, setSearching] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	const handleSearch = useCallback(async () => {
		if (searchTerm !== "") {
			setSearching(true);
			axios
				.post(route("website.search.site-search-results"), {
					term: searchTerm,
					limit: 25,
				})
				.then(response => {
					setSearchResults(response.data);
					setSearching(false);
				})
				.catch(error => {
					console.error(`Errors during search: ${error}`);
				});
		} else {
			setSearchResults([]);
			setSearching(false);
		}
	}, [searchTerm]);

	return (
		<>
			<SearchBar hideIcon name="filters[term]" onChange={e => setSearchterm(e.target.value)} value={searchTerm} />
			<div className={`search-results ${searchTerm === "" ? "hidden" : ""}`}>
				<div className="searc-results-container">
					<div className="search-results-content">
						{searching && (
							<>
								<h5>{uctrans("website.search.site_searching_title")}</h5>
								<div className="search-loader">
									<Spinner width={25} />
								</div>
							</>
						)}
						{!searching && !!searchResults.length && (
							<>
								<h5>{uctrans("website.search.site_found_title")}</h5>
								<ul>
									{searchResults.map((searchResult, key) => (
										<li key={key}>
											<a href={searchResult.url}>{searchResult.label}</a>
										</li>
									))}
								</ul>
							</>
						)}
						{!searching && !searchResults.length && (
							<>
								<h5>{uctrans("website.search.site_not_found_title")}</h5>
								<span className="italic">{uctrans("website.search.site_not_found_message")}</span>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
