import axios from "axios";
import assign from "lodash/assign";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import head from "lodash/head";
import intersectionBy from "lodash/intersectionBy";
import last from "lodash/last";
import map from "lodash/map";
import matchesProperty from "lodash/matchesProperty";
import PropTypes from "prop-types";
import { Component } from "react";
import { route } from "../../../../js/helpers";
import Nav from "../../../../js/lib/Nav";
import { uctrans } from "../../../../js/lib/Translator";
import {
	checkQuestionDependency,
	removeAnswerLogic,
	replaceTags,
	setAnswerLogic,
	typeHasAnswerOptions,
} from "../../../../js/questionnairehelpers";
import { createLoader, error } from "../../../../js/react/components/general/notifications";
import PageList from "./PageList";
import QuestionnaireContext from "./QuestionnaireContext";

class Questionnaire extends Component {
	constructor(props) {
		super(props);
		this.state = {
			questionnaire: this.props.questionnaire,
			questionnairetags: this.props.questionnairetags,
			questionnaire_tags: this.props.questionnaire_tags,
			questionnaireStarted: this.props.questionnaireStarted,
			questionnaire_completion_data: this.props.questionnaire_completion_data,
			clientTreatmentParticipantIdToken: this.props.clientTreatmentParticipantIdToken,
			relationManagerSurveyIdToken: this.props.relationManagerSurveyIdToken,
			communicationLogIdToken: this.props.communicationLogIdToken,
			currentPageId: this.props.currentPageId,
			changesOnPage: this.props.changesOnPage,
			loading: this.props.loading,
			dataLoaded: this.props.dataLoaded,
			primordial: this.props.primordial,
		};

		this.startQuestionnaire = this.startQuestionnaire.bind(this);
		this.switchPage = this.switchPage.bind(this);
		this.hasNextPage = this.hasNextPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.getNextPage = this.getNextPage.bind(this);
		this.hasPreviousPage = this.hasPreviousPage.bind(this);
		this.previousPage = this.previousPage.bind(this);
		this.getPreviousPage = this.getPreviousPage.bind(this);
		this.selectInitialPageId = this.selectInitialPageId.bind(this);
		this.getCurrentPage = this.getCurrentPage.bind(this);
		this.setAnswer = this.setAnswer.bind(this);
		this.removeAnswer = this.removeAnswer.bind(this);
		this.saveAnswers = this.saveAnswers.bind(this);
		this.checkRequiredQuestionsOnPage = this.checkRequiredQuestionsOnPage.bind(this);
		this.checkQuestionVisibility = this.checkQuestionVisibility.bind(this);
	}

	componentDidMount() {
		this.checkQuestionVisibility(() => {
			this.setState(
				{
					currentPageId: this.selectInitialPageId(),
				},
				() => {
					this.setState({
						dataLoaded: true,
					});
				},
			);
		});
	}

	selectInitialPageId() {
		if (this.props.initialPage) {
			const firstPage = head(this.state.questionnaire.pages);
			return firstPage ? firstPage.id : null;
		}

		if (this.state.questionnaire_completion_data && this.state.questionnaire_completion_data.current_page_id) {
			const currentPage = find(this.state.questionnaire.pages, [
				"id",
				this.state.questionnaire_completion_data.current_page_id,
			]);
			if (currentPage) {
				return this.state.questionnaire_completion_data.current_page_id;
			}
		}
		const firstPage = head(this.state.questionnaire.pages);
		return firstPage ? firstPage.id : null;
	}

	checkQuestionVisibility(callback) {
		const questionnaire = cloneDeep(this.state.questionnaire);
		const BreakException = {};
		try {
			questionnaire.pages.forEach(page => {
				page.questions.forEach(question => {
					if (!question.active) {
						question.visible = false;
					} else {
						const answers = this.state.questionnaire_completion_data
							? this.state.questionnaire_completion_data.answers
							: [];
						checkQuestionDependency(question, answers, questionnaire.dependency_types);
						if (!question.visible && this.state.questionnaire_completion_data) {
							const invisibleQuestionWithAnswer = find(this.state.questionnaire_completion_data.answers, [
								"question_id",
								question.id,
							]);
							if (invisibleQuestionWithAnswer) {
								if (invisibleQuestionWithAnswer.answer_options && invisibleQuestionWithAnswer.answer_options > 0) {
									this.removeAnswer(question, head(invisibleQuestionWithAnswer.answer_options));
								} else {
									this.removeAnswer(question, null);
								}
								throw BreakException;
							}
						}
					}
				});
			});
		} catch (e) {
			if (e !== BreakException) {
				throw e;
			}
		}
		this.setState(
			{
				questionnaire,
			},
			callback,
		);
	}

	removeAnswer(question, value) {
		this.setState({
			changesOnPage: true,
		});

		this.setState(
			state => {
				const answers = [...state.questionnaire_completion_data.answers];

				const questionnaire_completion_data = { ...state.questionnaire_completion_data };
				questionnaire_completion_data.answers = removeAnswerLogic(
					answers,
					question,
					value,
					state.questionnaire.question_types,
				);

				return {
					questionnaire_completion_data,
				};
			},
			() => {
				if (typeHasAnswerOptions(question.type, this.state.questionnaire.question_types)) {
					this.checkQuestionVisibility();
				}
			},
		);
	}

	setAnswer(question, value) {
		this.setState({
			changesOnPage: true,
		});

		if (value === "") {
			return this.removeAnswer(question, null);
		}

		this.setState(
			state => {
				const answers = [...state.questionnaire_completion_data.answers];

				const questionnaire_completion_data = { ...state.questionnaire_completion_data };
				questionnaire_completion_data.answers = setAnswerLogic(
					answers,
					question,
					value,
					state.questionnaire.question_types,
				);

				return {
					questionnaire_completion_data,
				};
			},
			() => {
				if (typeHasAnswerOptions(question.type, this.state.questionnaire.question_types)) {
					this.checkQuestionVisibility();
				}
			},
		);
	}

	startQuestionnaire() {
		if (this.state.loading) {
			return;
		}
		this.setState({ loading: true });
		const data = {
			questionnaire: this.state.questionnaire.id_token,
			communication_log_id_token: this.state.communicationLogIdToken,
		};

		if (typeof this.state.clientTreatmentParticipantIdToken !== "undefined") {
			assign(data, {
				participantIdToken: this.state.clientTreatmentParticipantIdToken,
			});
		} else if (typeof this.state.relationManagerSurveyIdToken !== "undefined") {
			assign(data, {
				relationManagerSurveyIdToken: this.state.relationManagerSurveyIdToken,
			});
		}

		const loader = createLoader(uctrans("website.questionnaires.starting_questionnaire"));
		axios({
			method: "GET",
			url: route("website.questionnaire.generate-completion", data),
		})
			.then(result => {
				if (result.data && result.data.success) {
					window.onbeforeunload = null;
					if (typeof this.state.relationManagerSurveyIdToken !== "undefined") {
						Nav.go(
							route("website.relation-manager-survey", {
								questionnaire_id_token: this.state.questionnaire.id_token,
								relation_manager_survey_id_token: this.state.relationManagerSurveyIdToken,
							}),
						);
					} else {
						window.location.replace(
							route("website.questionnaire.completion", {
								questionnaire: this.state.questionnaire.id_token,
								questionnaire_completion: result.data.questionnaire_completion_data.id_token,
							}),
						);
					}
				} else {
					loader.failure(uctrans("website.questionnaires.questionnaire_could_not_be_started"));
				}
			})
			.catch(err => {
				console.error(err);
			});
	}

	saveAnswers(callback) {
		const requiredResult = this.checkRequiredQuestionsOnPage(this.getCurrentPage());

		let loader = null;
		if (requiredResult) {
			if (this.state.changesOnPage || !this.hasNextPage()) {
				loader = createLoader(uctrans("website.questionnaires.saving_answers"));
			}
		}

		if (requiredResult && !this.state.changesOnPage && this.hasNextPage()) {
			// No changes on the page, and no required questions missing, so just go to next page
			callback();
		} else if (requiredResult) {
			this.setState({ loading: true });

			const routeData = {
				questionnaire: this.state.questionnaire.id_token,
				questionnaire_completion: this.state.questionnaire_completion_data.id_token,
				communication_log_id_token: this.state.communicationLogIdToken,
			};

			if (typeof this.state.clientTreatmentParticipantIdToken !== "undefined") {
				assign(routeData, {
					participantIdToken: this.state.clientTreatmentParticipantIdToken,
				});
			} else if (typeof this.state.relationManagerSurveyIdToken !== "undefined") {
				assign(routeData, {
					relationManagerSurveyIdToken: this.state.relationManagerSurveyIdToken,
				});
			}

			axios({
				method: "PUT",
				url: route("website.questionnaire.save-answers", routeData),
				data: {
					questionnaire_completion_data: this.state.questionnaire_completion_data,
					finished: !this.hasNextPage(),
					next_page_id: this.getNextPage() ? this.getNextPage().id : null,
				},
			})
				.then(response => {
					if (response.data) {
						this.setState(
							{
								questionnaire_completion_data: response.data.questionnaire_completion_data,
							},
							() => {
								loader.success(uctrans("website.questionnaires.answers_saved"));
								callback();
							},
						);
					} else {
						loader.failure(uctrans("website.questionnaires.answers_could_not_be_saved"));
					}
				})
				.catch(error => {
					console.warn(error);
					loader.failure(uctrans("website.questionnaires.answers_could_not_be_saved"));
				})
				.then(() => {
					this.setState({ loading: false });
				});
		}
	}

	checkRequiredQuestionsOnPage(page) {
		let validates = true;
		const requiredQuestions = filter(page.questions, ["required", true]);
		const visibleRequiredQuestions = [];
		requiredQuestions.forEach(requiredQuestion => {
			if (requiredQuestion.visible) {
				visibleRequiredQuestions.push(requiredQuestion);
			}
		});

		const requiredQuestionsIds = map(visibleRequiredQuestions, requiredQuestion => ({
			question_id: requiredQuestion.id,
		}));
		const answersForRequiredQuestions = intersectionBy(
			this.state.questionnaire_completion_data.answers,
			requiredQuestionsIds,
			"question_id",
		);

		const requiredQuestionIdsFromAnswers = map(answersForRequiredQuestions, answer => ({
			question_id: answer.question_id,
		}));

		const requiredAnsweredQuestions = intersectionBy(
			requiredQuestionIdsFromAnswers,
			requiredQuestionsIds,
			"question_id",
		);

		if (this.state.questionnaire.linked_field_types !== null) {
			const ZipcodeQuestion = page.questions.find(
				question =>
					Number(question.linked_field_type) === Number(this.state.questionnaire.linked_field_types.ZIPCODE.key),
			);

			if (ZipcodeQuestion != null) {
				// find answer for id
				const ZipcodeAnswer = this.state.questionnaire_completion_data.answers.find(
					answer => Number(answer.question_id) === Number(ZipcodeQuestion.id),
				);

				if (ZipcodeAnswer && !/^[1-9][0-9]{3}[A-Z]{2}$/i.test(ZipcodeAnswer.open_content)) {
					validates = false;
					error(uctrans("website.questionnaires.zipcode_invalid"));
				}
			}

			// KVK Question validation
			const KVKQuestion = page.questions.find(
				question =>
					Number(question.linked_field_type) === Number(this.state.questionnaire.linked_field_types.KVK_NUMBER.key),
			);
			if (KVKQuestion != null) {
				// find answer for id
				const KVKAnswer = this.state.questionnaire_completion_data.answers.find(
					answer => Number(answer.question_id) === Number(KVKQuestion.id),
				);

				if (KVKAnswer && KVKAnswer.open_content.length !== 8) {
					validates = false;
					error(uctrans("website.questionnaires.kvk_number_invalid"));
				}
			}

			// AFM Question validation
			const AFMQuestion = page.questions.find(
				question =>
					Number(question.linked_field_type) === Number(this.state.questionnaire.linked_field_types.AFM_NUMBER.key),
			);
			if (AFMQuestion != null) {
				// find answer for id
				const AFMAnswer = this.state.questionnaire_completion_data.answers.find(
					answer => Number(answer.question_id) === Number(AFMQuestion.id),
				);

				if (AFMAnswer && AFMAnswer.open_content.length !== 8) {
					validates = false;
					error(uctrans("website.questionnaires.afm_number_invalid"));
				}
			}

			// AFM Question validation
			const KifidQuestion = page.questions.find(
				question =>
					Number(question.linked_field_type) === Number(this.state.questionnaire.linked_field_types.KIFID_NUMBER.key),
			);
			if (KifidQuestion != null) {
				// find answer for id
				const KifidAnswer = this.state.questionnaire_completion_data.answers.find(
					answer => Number(answer.question_id) === Number(KifidQuestion.id),
				);

				if (KifidAnswer && KifidAnswer.open_content.replace(".", "").length !== 9) {
					validates = false;
					error(uctrans("website.questionnaires.kifid_number_invalid"));
				}
			}
		}

		if (requiredQuestionsIds.length !== requiredAnsweredQuestions.length) {
			error(uctrans("website.questionnaires.question_is_required"));
			validates = false;
		}
		return validates;
	}

	hasPreviousPage() {
		return this.state.primordial || this.getCurrentPage() !== head(this.state.questionnaire.pages);
	}

	hasNextPage() {
		return this.getCurrentPage() !== last(this.state.questionnaire.pages);
	}

	getPreviousPage() {
		let newPage = null;
		if (!this.getCurrentPage()) {
			newPage = head(this.state.questionnaire.pages);
		} else {
			const currentPageIndex = findIndex(this.state.questionnaire.pages, { id: this.state.currentPageId });
			if (typeof this.state.questionnaire.pages[currentPageIndex - 1] !== "undefined") {
				newPage = this.state.questionnaire.pages[currentPageIndex - 1];
			} else {
				newPage = this.getCurrentPage();
			}
		}
		return newPage;
	}

	getNextPage() {
		let newPage = null;
		if (!this.getCurrentPage()) {
			newPage = head(this.state.questionnaire.pages);
		} else {
			const currentPageIndex = findIndex(this.state.questionnaire.pages, { id: this.state.currentPageId });
			if (typeof this.state.questionnaire.pages[currentPageIndex + 1] !== "undefined") {
				newPage = this.state.questionnaire.pages[currentPageIndex + 1];
			} else {
				newPage = this.getCurrentPage();
			}
		}
		return newPage;
	}

	previousPage() {
		this.saveAnswers(() => {
			if (this.getPreviousPage() === this.getCurrentPage()) {
				this.props.goPrimordial();
			}

			const newPage = this.getPreviousPage();
			this.setState(
				{
					currentPageId: newPage.id,
					changesOnPage: false,
				},
				() => {
					window.scroll({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
				},
			);
		});
	}

	nextPage() {
		this.saveAnswers(() => {
			const newPage = this.getNextPage();
			if (newPage === this.getCurrentPage()) {
				// last page was validated and we can redirect to finished page
				if (this.props.onComplete === null) {
					window.location.replace(route("website.questionnaire.finished"));
				} else {
					this.props.onComplete();
				}
			}
			this.setState(
				{
					currentPageId: newPage.id,
					changesOnPage: false,
				},
				() => {
					window.scroll({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
				},
			);
		});
	}

	switchPage(pageId) {
		this.saveAnswers(() => {
			const navigateToPage = find(this.state.questionnaire.pages, matchesProperty("id", pageId));
			this.setState({
				currentPageId: navigateToPage ? navigateToPage.id : this.selectInitialPageId(),
				changesOnPage: false,
			});
		});
	}

	getCurrentPage() {
		return find(this.state.questionnaire.pages, { id: this.state.currentPageId });
	}

	render() {
		if (!this.state.dataLoaded) {
			return null;
		}
		return (
			<QuestionnaireContext.Provider
				value={{
					questionnaire: this.state.questionnaire,
					replaceTags: content => replaceTags(content, this.state.questionnaire_tags),
					switchPage: this.switchPage,
					hasNextPage: this.hasNextPage,
					nextPage: this.nextPage,
					hasPreviousPage: this.hasPreviousPage,
					previousPage: this.previousPage,
					isLoading: this.state.loading,
					currentPage: this.getCurrentPage(),
					setAnswer: this.setAnswer,
					removeAnswer: this.removeAnswer,
					questionnaire_tags: this.state.questionnaire_tags,
					questionnaireTags: this.state.questionnairetags,
					checkRequiredQuestionsOnPage: this.checkRequiredQuestionsOnPage,
					answers: this.state.questionnaire_completion_data ? this.state.questionnaire_completion_data.answers : [],
					clientTreatmentParticipantIdToken: this.state.clientTreatmentParticipantIdToken,
				}}>
				<div className="w-3/4">
					{!this.state.questionnaire.active && <div>{uctrans("website.questionnaires.questionnaire_is_inactive")}</div>}
					{this.state.questionnaire.active && !this.state.questionnaireStarted && (
						<div className="container">
							<div className="md:w-full inline-block">
								<h3>{this.state.questionnaire.name}</h3>
								{!!this.state.questionnaire.description && (
									<p
										dangerouslySetInnerHTML={{
											__html: replaceTags(this.state.questionnaire.description, this.state.questionnaire_tags),
										}}
									/>
								)}
							</div>

							<div className="md:w-full mt-5 inline-block">
								<a className="button button-primary" onClick={this.startQuestionnaire}>
									{this.state.questionnaire.start_button_text || uctrans("website.questionnaires.start_questionnaire")}
								</a>
							</div>
						</div>
					)}
					{this.state.questionnaireStarted && (
						<div className="container">
							<div className="md:w-full inline-block">
								<PageList
									showPageNumbers={this.props.showPageNumbers}
									confirmTitle={this.props.confirmTitle}
									confirmBody={this.props.confirmBody}
								/>
							</div>
						</div>
					)}
				</div>
			</QuestionnaireContext.Provider>
		);
	}
}

Questionnaire.propTypes = {
	questionnaire: PropTypes.object,
	questionnairetags: PropTypes.object,
	questionnaire_tags: PropTypes.object,
	questionnaireStarted: PropTypes.bool,
	questionnaire_completion_data: PropTypes.object,
	clientTreatmentParticipantIdToken: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	relationManagerSurveyIdToken: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	communicationLogIdToken: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	currentPageId: PropTypes.number,
	changesOnPage: PropTypes.bool,
	loading: PropTypes.bool,
	dataLoaded: PropTypes.bool,
	onComplete: PropTypes.func,
	showPageNumbers: PropTypes.bool,
	confirmTitle: PropTypes.string,
	confirmBody: PropTypes.string,
	primordial: PropTypes.bool,
	goPrimordial: PropTypes.func,
	initialPage: PropTypes.bool,
};

Questionnaire.defaultProps = {
	questionnaire: window.data.questionnaire_static_data && window.data.questionnaire_static_data.questionnaire,
	questionnairetags: window.data.questionnaire_static_data && window.data.questionnaire_static_data.questionnairetags,
	questionnaire_tags: window.data.questionnaire_static_data && window.data.questionnaire_static_data.questionnaire_tags,
	questionnaireStarted: !!window.data.questionnaire_completion_data,
	questionnaire_completion_data: { ...window.data.questionnaire_completion_data },
	clientTreatmentParticipantIdToken: window.data.client_treatment_participant_id_token,
	relationManagerSurveyIdToken: window.data.relation_manager_survey_id_token,
	communicationLogIdToken: window.data.communication_log_id_token,
	currentPageId: null,
	changesOnPage: false,
	loading: false,
	dataLoaded: false,
	onComplete: null,
	showPageNumbers: true,
	confirmTitle: undefined,
	confirmBody: undefined,
	primordial: false,
	goPrimordial: undefined,
	initialPage: false,
};

export default Questionnaire;
