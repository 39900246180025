import { lazy, Suspense } from "react";
import Spinner from "../../../../js/react/components/general/Spinner";

const QRCodeLibrary = lazy(() => import(/* webpackChunkName: "qrcode" */ "qrcode.react"));

export default function AppRegistrationQrCode() {
	const userEmail =
		typeof window.data.user !== "undefined"
			? window.data.user.email
			: typeof window.data.contactperson !== "undefined"
			  ? window.data.contactperson.email
			  : window.data.admin.email;
	const userActivationCode =
		typeof window.data.user !== "undefined"
			? window.data.user.app_activation_code
			: typeof window.data.contactperson !== "undefined"
			  ? window.data.contactperson.app_activation_code
			  : window.data.admin.app_activation_code;

	return (
		<Suspense fallback={<Spinner />}>
			<QRCodeLibrary
				value={JSON.stringify({ user_email: userEmail, app_activation_code: userActivationCode })}
				size={125}
			/>
		</Suspense>
	);
}
