import axios from "axios";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { route } from "../../../../js/helpers";
import Dialog from "../../../../js/react/components/general/Dialog";
import Spinner from "../../../../js/react/components/general/Spinner";

export default function QrLogin() {
	const [qrData, setQrData] = useState(null);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);

	const qrLogin = useCallback(async () => {
		try {
			const startResponse = await axios.post(route("website.auth.qr.start"));
			setQrData(startResponse.data);

			// Start long poll for change in status
			try {
				const checkResponse = await axios.get(route("website.auth.qr.check"));
				setSuccess(true);
				setTimeout(() => {
					window.location = checkResponse.data.redirect_to;
				}, 300);
			} catch (error) {
				if (error.response.status === 404 || error.response.status === 401) {
					setError(error.response.data.error);
				} else {
					console.error(error);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		window.location.search
			.substr(1)
			.split("&")
			.forEach(queryParam => {
				const [param, value] = queryParam.split("=");
				if (param === "qr" && value === "1") {
					qrLogin();
				}
			});
	}, [qrLogin]);

	return (
		<>
			<button className="button button-qr" onClick={qrLogin}>
				<span className="button-wr-code-text">Inloggen met app</span>
			</button>

			<Dialog title="Inloggen met je telefoon" isOpen={qrData !== null} onClose={() => setQrData(null)}>
				<div>
					{qrData && (
						<>
							<div className="flex">
								<div className="relative">
									<div
										className={classNames({ "opacity-25": error || success })}
										dangerouslySetInnerHTML={{ __html: qrData.qr }}
									/>
									{success && (
										<div className="absolute" style={{ bottom: "calc(50% - 45px)", right: "calc(50% - 45px)" }}>
											<Spinner width={90} height={90} />
										</div>
									)}
								</div>
								<div className="mt-2">
									<strong>Scan deze code met de Intermediair app</strong>
									<br />
									Deze code is geldig tot: {qrData.valid_until} uur
									{error && <div className="text-red">{error}</div>}
								</div>
							</div>
							<div>
								Nog geen Intermediair app op je telefoon?
								<br />
								<a href="/intermediairapp">Klik hier</a> voor meer informatie
							</div>
						</>
					)}
				</div>
			</Dialog>
		</>
	);
}
