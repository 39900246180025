import { useContext, useEffect } from "react";
import CmsHtml from "../../../../../../js/react/components/CmsHtml";
import CooperationRequestContext from "../CooperationRequestContext";

export default function StepCompleted() {
	const { pre_processed_data, setCanGoBack, setCanGoNext } = useContext(CooperationRequestContext);

	useEffect(() => {
		setCanGoBack(false);
		setCanGoNext(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <CmsHtml html={pre_processed_data && pre_processed_data[0]} />;
}
