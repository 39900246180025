import { useState } from "react";
import { uctrans } from "../../../../js/lib/Translator";
import DataColumn from "../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../js/react/components/general/abn-overview/AbnOverview";
import Filters from "../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../js/react/components/general/overview/SearchBarFilter";

export default function AllCompanySelectionOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		index_link: window.data.index_link,
	});

	return (
		<AbnOverview
			initialData={overviewData.overview}
			indexUrl={overviewData.index_link}
			modelTranslations={{
				plural: uctrans("intermediaries.plural"),
				singular: uctrans("intermediaries.singular"),
			}}
			renderFilters={() => (
				<Filters>
					<SearchBarFilter type="text" name="search" />
				</Filters>
			)}
			renderBody={() => (
				<DataTable>
					<DataColumn
						width={4}
						sortable="name"
						renderHeaderContent={() => uctrans("intermediaries.fields.name")}
						renderCellContent={company => {
							if (company.select_link) {
								return (
									<a className="link" href={company.select_link}>
										{company.name}
									</a>
								);
							}
							return company.name;
						}}
					/>
					<DataColumn
						width={3}
						sortable="company_number"
						renderHeaderContent={() => uctrans("intermediaries.fields.company_number")}
						renderCellContent={company => company.company_number}
					/>
					<DataColumn
						width={4}
						renderHeaderContent={() => uctrans("intermediaries.fields.parent_id")}
						renderCellContent={company =>
							company.organisation && (company.organisation.label !== null) !== "undefined"
								? company.organisation.label
								: "-"
						}
					/>
					<DataColumn
						width={3}
						sortable="address.city"
						renderHeaderContent={() => uctrans("addresses.fields.city")}
						renderCellContent={company =>
							company.address && company.address.label !== null ? company.address.label : "-"
						}
					/>
					<DataColumn
						width={4}
						sortable="relationmanager.name"
						renderHeaderContent={() => uctrans("intermediaries.fields.relationmanager_id")}
						renderCellContent={company =>
							company.relationmanager && company.relationmanager.label !== null ? company.relationmanager.label : "-"
						}
					/>
				</DataTable>
			)}
		/>
	);
}
