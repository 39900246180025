import { useCallback, useState } from "react";
import { route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { error } from "../../../../../js/react/components/general/notifications";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";

export default function RequestCooperationRequestForm() {
	const [formData, setFormData] = useState({});
	const inputHandler = useInputHandler(setFormData);

	const onError = useCallback(response => {
		if (response.response && Number(response.response.status) === 429) {
			error(uctrans("website.cooperation_request.requested_too_many_times"));
		}
	}, []);

	return (
		<div>
			<h3>{uctrans("website.cooperation_request.request_cooperation")}</h3>
			<span>{uctrans("website.cooperation_request.cooperation_request_description")}</span>
			<div>
				<AjaxForm
					method="POST"
					onError={onError}
					submitUrl={route("website.cooperation-request")}
					data={formData}
					onSuccess={() => Nav.go(route("website.cooperation-request.signup-complete"))}
					useFlashMessage
					loaderText={uctrans("website.cooperation_request.validating")}
					successText={uctrans("website.cooperation_request.email_is_being_sent_to_you")}>
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-2">
									<LabeledTextInput
										name="email"
										value={formData.email || ""}
										onChange={inputHandler}
										label="website.cooperation_request.email"
										required
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar
										translateLabel={false}
										buttonContent={uctrans("website.cooperation_request.send")}
										addAnotherAvailable={false}
									/>
								</div>
							</div>
						</div>
					</div>
				</AjaxForm>
			</div>
		</div>
	);
}
