import AppDeviceOverview from "../../../../../js/react/components/app/AppDeviceOverview";

import isEmpty from "lodash/isEmpty";

export default function MyAppInfo() {
	const myAppInfoData = window.data.my_app_info_data ? window.data.my_app_info_data : null;

	return (
		!isEmpty(myAppInfoData) && (
			<AppDeviceOverview
				devices={myAppInfoData.app_devices}
				deleteRoute="website.app-devices.delete"
				deleteRouteParams={{}}
				showText={false}
			/>
		)
	);
}
