import PropTypes from "prop-types";
import { useState } from "react";
import { format } from "../../../../../js/lib/Date";
import CommunicationPreferences from "../../../../../js/react/components/communication/CommunicationPreferences";
import ContactpersonCommunicationRequestedBySelect from "../../../../../js/react/components/communication/ContactpersonCommunicationRequestedBySelect";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import ImageThumbnailFileInput from "../../../../../js/react/components/general/form/ImageThumbnailFileInput";
import InitialsTextInput from "../../../../../js/react/components/general/form/InitialsTextInput";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import SelectInterestList from "../../../../../js/react/components/interests/SelectInterestList";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { can, route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";

import cloneDeep from "lodash/cloneDeep";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";

export default function MyColleguesForm(props) {
	const creating = window.data.collegue_form_data.id === -1;

	const [formData, setFormData] = useState(
		typeof props.MyCompanyFormData === "undefined"
			? cloneDeep(window.data.collegue_form_data)
			: props.MyCompanyFormData,
	);
	const [staticData] = useState(
		typeof props.MyCompanyStaticData === "undefined"
			? cloneDeep(window.data.collegue_static_data)
			: props.MyCompanyStaticData,
	);

	const [selectedOccupation, setSelectedOccupation] = useState(
		staticData.occupations.find(occupancy => Number(occupancy.id) === Number(formData.occupation_id)),
	);

	const inputHandler = useInputHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const onSuccess = () => {
		if (creating) {
			Nav.go(route("website.my-collegues.index"));
		} else {
			window.location.reload();
		}
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const handleOccupationChange = (selectedValue, metaData) => {
		selectHandler(selectedValue, metaData);
		if (selectedValue !== null) {
			const { value } = selectedValue;
			const result = staticData.occupations.find(occupancy => Number(occupancy.id) === Number(value));
			if (typeof result !== "undefined" && result.is_open) {
				setSelectedOccupation(result);
			} else {
				setSelectedOccupation("");
			}
		} else {
			setSelectedOccupation("");
		}
	};

	const hasPrivacyPreference = type =>
		formData.privacy_preferences.find(preference => Number(preference.type) === Number(type));

	return (
		<AjaxForm
			method={creating ? "POST" : "PUT"}
			submitUrl={creating ? route("website.my-collegues.store") : route("website.my-collegues.update", formData.id)}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "my_profile.my_collegues.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "my_profile.my_collegues.singular" })}
			onSuccess={onSuccess}
			data={{ ...formData }}
			useFlashMessage>
			{creating ? (
				<h1>{uctrans("my_profile.my_collegues.create_new_collegue")}</h1>
			) : (
				<h1>{uctrans("my_profile.fields.edit_collegue")}</h1>
			)}
			<h3>{uctrans("my_profile.fields.general_info")}</h3>
			<div className="form-full">
				<div className="form-1-6">
					<label htmlFor="type">{uctrans("my_profile.fields.salutation")}</label>
					<Select
						value={staticData.salutation_types.filter(({ value }) => Number(value) === Number(formData.salutation))}
						options={staticData.salutation_types}
						name="salutation"
						onChange={selectHandler}
						isClearable={false}
					/>
				</div>
				<div className="form-1-6">
					<InitialsTextInput
						name="initials"
						value={formData.initials}
						onChange={inputHandler}
						label="my_profile.fields.initials"
					/>
				</div>
			</div>
			<div className="form-container">
				<div className="form-1-3">
					<LabeledTextInput
						name="first_name"
						value={formData.first_name}
						onChange={inputHandler}
						label="my_profile.fields.first_name"
					/>
				</div>
			</div>
			<div className="form-container">
				<div className="form-1-6">
					<LabeledTextInput
						name="insertion"
						value={formData.insertion}
						onChange={inputHandler}
						label="my_profile.fields.insertion"
					/>
				</div>
			</div>
			<div className="form-container">
				<div className="form-1-3">
					<LabeledTextInput
						name="last_name"
						value={formData.last_name}
						onChange={inputHandler}
						label="my_profile.fields.last_name"
						required
					/>
				</div>
			</div>

			<div className="form-full">
				<div className="form-1-2">
					<label htmlFor="occupation">
						<Translate content="my_profile.fields.occupation" />
					</label>
					<Select
						placeholder={uctrans("general.select_:item", {}, { item: "my_profile.fields.occupation" })}
						value={staticData.occupations_select.filter(
							({ value }) => Number(value) === Number(formData.occupation_id),
						)}
						options={staticData.occupations_select}
						name="occupation_id"
						onChange={handleOccupationChange}
					/>
				</div>
				{selectedOccupation && selectedOccupation.is_open && (
					<div className="form-1-2">
						<LabeledTextInput
							name="occupation_value"
							value={formData.occupation_value}
							onChange={inputHandler}
							label={selectedOccupation.open_title}
						/>
					</div>
				)}
			</div>

			{!creating ? (
				<div className="form-full">
					<div className="form-1-2">
						<label htmlFor="date_of_birth">
							<Translate content="my_profile.fields.date_of_birth" />
						</label>
						<span className="font-bold">
							{hasPrivacyPreference(staticData.privacy_preference_types.DATE_OF_BIRTH.key)
								? uctrans("my_profile.fields.date_of_birth_not_allowed")
								: formData.date_of_birth
								  ? format(formData.date_of_birth, "dd-MM-y")
								  : "-"}
						</span>
					</div>
				</div>
			) : null}
			{!creating ? (
				<div className="form-container">
					<div className="form-1-2">
						<label htmlFor="date_of_birth">
							<Translate content="my_profile.fields.photo" />
						</label>
						{hasPrivacyPreference(staticData.privacy_preference_types.PHOTO.key) ? (
							uctrans("my_profile.fields.photo_capture_not_allowed")
						) : (
							<ImageThumbnailFileInput disabled image={formData.photo} />
						)}
					</div>
				</div>
			) : null}

			{creating && window.data.user && (
				<ContactpersonCommunicationRequestedBySelect
					name="communication_creation_requested_by_contactperson_id"
					formData={formData}
					setFormData={setFormData}
					requestedByTypeOptions={staticData.communication_creation_requested_by_type_options}
					creating={creating}
					fromCrm={false}
				/>
			)}

			<h3>{uctrans("my_profile.fields.contact_info")}</h3>
			<div className="form-container">
				<div className="form-1-3">
					<LabeledTextInput
						name="email"
						label="my_profile.fields.email_address"
						value={formData.email}
						onChange={inputHandler}
						required
						disabled={!can("contactperson", "current_company_update_email")}
						hint={uctrans(
							can("contactperson", "current_company_update_email")
								? "general.email"
								: "my_profile.fields.only_by_managers",
						)}
					/>
				</div>
			</div>
			<div className="form-container">
				<div className="form-1-3">
					<LabeledTextInput
						name="landline"
						label="my_profile.fields.landline"
						value={formData.landline}
						onChange={inputHandler}
					/>
				</div>
			</div>
			<div className="form-container">
				<div className="form-1-2">
					<LabeledTextInput
						name="mobile"
						label="my_profile.fields.mobile"
						value={formData.mobile}
						onChange={inputHandler}
					/>
				</div>
			</div>
			<div className="form-container">
				<Checkmark
					onChange={inputHandler}
					name="mobile_for_login_only"
					checked={formData.mobile_for_login_only}
					label={uctrans("my_profile.fields.mobile_for_login_only")}
				/>
			</div>
			<div className="form-full">
				<label htmlFor="interests">
					<Translate content="my_profile.fields.interests" />
				</label>
				{!creating ? (
					hasPrivacyPreference(staticData.privacy_preference_types.INTEREST.key) ? (
						uctrans("my_profile.fields.interests_not_allowed")
					) : (
						<SelectInterestList
							disabled
							initialInteresttypes={formData.interests}
							initialCategories={staticData.interest_categories}
							onChange={selectedInterests => handleFormValueChange("interests", selectedInterests)}
						/>
					)
				) : null}
			</div>
			<div className="form-full">
				<h3>{uctrans("my_profile.fields.communication_preferences")}</h3>
				<CommunicationPreferences
					staticNewsLetterSubscriptionTypes={staticData.newsletter_subscription_types}
					unsubscribeReasons={staticData.unsubscribe_reasons}
					communicationPreferenceData={formData.unsubscribe_preferences}
					onPreferenceChange={value => handleFormValueChange("unsubscribe_preferences", value)}
				/>
			</div>
			<h3>{uctrans("my_profile.fields.website")}</h3>
			<div className="form-full">
				<div className="form-1-3">
					<label htmlFor="website_permissiongroup_id">
						<Translate content="my_profile.fields.website_permissiongroup_id" />
						<span className="text-orange"> *</span>
					</label>
					<Select
						placeholder={uctrans("general.select_:item", {}, { item: "my_profile.fields.occupation" })}
						value={staticData.website_permissiongroup_options.filter(
							({ value }) => Number(value) === Number(formData.website_permissiongroup_id),
						)}
						options={staticData.website_permissiongroup_options}
						name="website_permissiongroup_id"
						isClearable={false}
						onChange={value => handleFormValueChange("website_permissiongroup_id", value.value)}
					/>
				</div>
			</div>
			<div className="form-full">
				<label>{uctrans("my_profile.fields.change_password")}</label>
				<RadioGroup
					name="change_password"
					value={formData.change_password}
					horizontal
					onChange={value => handleFormValueChange("change_password", value)}>
					<Radio label={uctrans("general.yes")} value={1} />
					<Radio label={uctrans("general.no")} value={0} />
				</RadioGroup>
			</div>
			{formData.change_password ? (
				<>
					<div className="form-container">
						<div className="form-1-3">
							<label htmlFor="current_password">
								<Translate content="my_profile.my_collegues.fields.your_current_password" />
							</label>
							<input
								id="current_password"
								type="password"
								name="current_password"
								value={formData.current_password}
								onChange={inputHandler}
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="form-container">
						<div className="form-1-3">
							<label htmlFor="new_password">
								<Translate content="my_profile.my_collegues.fields.users_new_password" />
							</label>
							<input id="raw_password" type="password" name="raw_password" onChange={inputHandler} autoComplete="off" />
						</div>
						<div className="form-1-3">
							<label htmlFor="raw_password_confirmation">
								<Translate content="my_profile.my_collegues.fields.users_new_password_confirmation" />
							</label>
							<input
								id="raw_password_confirmation"
								type="password"
								name="raw_password_confirmation"
								onChange={inputHandler}
								autoComplete="off"
							/>
						</div>
					</div>
				</>
			) : null}
			<div className="form-full">
				<Checkmark
					onChange={inputHandler}
					name="send_collegue_email_with_new_password_instructions"
					checked={formData.send_collegue_email_with_new_password_instructions}
					label={uctrans("my_profile.fields.send_collegue_email_with_new_password_instructions")}
				/>
			</div>
			<div className="form-full">
				<div className="row">
					<div className="w-full md:w-1/2 lg:w-1/3 column">
						<SubmitBar translateLabel={false} buttonContent={uctrans("general.save")} addAnotherAvailable={false} />
					</div>
				</div>
			</div>
		</AjaxForm>
	);
}
MyColleguesForm.propTypes = {
	MyCompanyFormData: PropTypes.object,
	MyCompanyStaticData: PropTypes.object,
};
