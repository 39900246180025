import { route } from "../../../../js/helpers";
import { trans, uctrans } from "../../../../js/lib/Translator";
import AbnOverview from "../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../js/react/components/general/overview/SearchBarFilter";

export default function SearchOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<AbnOverview
			initialData={overviewData}
			indexUrl={route("website.search.index.json")}
			modelTranslations={{
				plural: trans("general.searchresult"),
				singular: trans("general.searchresults"),
			}}
			usePageLimitSelect={false}
			renderFilters={() => (
				<Filters displayFilterResetter={false}>
					<SearchBarFilter type="text" name="term" placeholder={uctrans("general.search")} />
				</Filters>
			)}
			renderBody={() => (
				<DataTable>
					<DataColumn
						width={12}
						renderHeaderContent={() => uctrans("general.title")}
						renderCellContent={searchResult => <a href={searchResult.url}>{searchResult.label}</a>}
					/>
					<DataColumn
						width={12}
						renderHeaderContent={() => uctrans("general.url")}
						renderCellContent={searchResult => <a href={searchResult.url}>{searchResult.url}</a>}
					/>
				</DataTable>
			)}
		/>
	);
}
