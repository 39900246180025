import axios from "axios";
import highchartsGlobalConfig from "../../../../../js/config/highcharts_global";
import each from "lodash/each";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import HighChart from "../../../../../crm/js/react/components/report/graph/HighChart";
import { route, numberFormat } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";

export default function CommercialDashboardBlock({ title }) {
	const [commercialData, setCommercialData] = useState([]);
	const [initialized, setInitialized] = useState(false);
	const [graphSeries, setSeries] = useState([]);
	const [statusBlocks, setStatusBlocks] = useState([]);

	const fetchCommercialData = useCallback(async () => {
		const response = await axios.get(route("website.dashboard.data", { type: "commercial" }));
		setCommercialData(response.data);
	}, []);

	useEffect(() => {
		if (!initialized) {
			fetchCommercialData();
			setInitialized(true);
		}
	}, [fetchCommercialData, initialized]);

	useEffect(() => {
		const colors = ["#96C147", "#F58A00", "#F3300A"];

		const newSeries = [];
		const newStatusBlocks = [];

		let i = 0;
		each(commercialData.status, (statusData, statusCode) => {
			const serieData = [];
			let totalSum = 0;

			serieData.push([0, totalSum]);
			each(statusData, (monthData, month) => {
				totalSum += parseInt(monthData.sum);
				serieData.push([parseInt(month), totalSum]);
			});
			newSeries.push({
				data: serieData,
				color: colors[i],
				marker: {
					enabled: false,
					symbol: "circle",
				},
			});

			newStatusBlocks.push({
				statusCode,
				color: colors[i],
				label: commercialData.status_labels[statusCode],
				sum: `€ ${numberFormat(commercialData.total[statusCode].sum, 0)}`,
				count: numberFormat(commercialData.total[statusCode].count, 0),
			});

			i++;
		});
		setSeries(newSeries);
		setStatusBlocks(newStatusBlocks);
	}, [commercialData]);
	return (
		<>
			<h3>
				{initialized && commercialData != null && !!commercialData.url ? (
					<a href={commercialData.url}>{title}</a>
				) : (
					title
				)}
			</h3>

			{graphSeries.length === 0 && <span className="italic">{uctrans("website.dashboard.no_commercial_results")}</span>}

			{initialized && (
				<>
					<HighChart
						chartConfig={{
							chart: {
								height: "200px",

								backgroundColor: null,
							},
							credits: {
								enabled: false,
							},
							legend: {
								enabled: false,
							},
							title: {
								text: null,
							},
							yAxis: {
								title: {
									text: null,
								},
								labels: {
									enabled: false,
									formatter: data => `€ ${numberFormat(data.value / 1000, 0)}`,
								},
							},
							xAxis: {
								min: 0.5,
								max: 12,
								categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
								labels: {
									formatter: data => (data.value === 0 ? "" : highchartsGlobalConfig.lang.shortMonths[data.value - 1]),
								},
								startOnTick: false,
								endOnTick: false,
								minPadding: 0,
								maxPadding: 0,
								align: "left",
							},
							tooltip: {
								headerFormat: "",
								pointFormat: "€ {point.y:,.0f}",
							},
							series: graphSeries,
						}}
					/>
					<div className="flex justify-between text-2xs mb-3">
						{statusBlocks.map(blockData => (
							<div key={blockData.statusCode}>
								<div className="flex items-center">
									<span
										style={{
											height: "10px",
											width: "10px",
											backgroundColor: blockData.color,
											borderRadius: "50%",
											display: "inline-block",
										}}
									/>
									<span className="ml-2">{blockData.label}</span>
								</div>
								<div className="ml-4">{blockData.sum}</div>
								<div className="ml-4">
									{uctrans("website.dashboard.count")}: {blockData.count}
								</div>
							</div>
						))}
					</div>
					{initialized && commercialData != null && !!commercialData.url && (
						<a className="link-arrow mb-1 mt-auto" href={commercialData.url}>
							{uctrans("website.dashboard.to_report")}
						</a>
					)}
				</>
			)}
		</>
	);
}

CommercialDashboardBlock.propTypes = {
	title: PropTypes.string,
};
