import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { uctrans } from "../../../../js/lib/Translator";
import Dialog from "../../../../js/react/components/general/Dialog";
import { textCopyHelper } from "../../../../js/helpers";
import CopyIconSVG from "../../../../static/img/copy-icon.svg";

export default function AppRegistrationActivationCode(props) {
	const [showMessage, setShowMessage] = useState(false);

	const copyTextHandler = useCallback(() => {
		textCopyHelper(props.registration_code);
		setShowMessage(true);
		setTimeout(() => setShowMessage(false), 3000);
	}, [props.registration_code]);

	return (
		<div className="flex justify-center">
			{props.registration_code}
			<button onClick={copyTextHandler}>
				<img className="copy-icon cursor-pointer" src={CopyIconSVG} alt="copy_icon" />
			</button>
			<Dialog width={355} isOpen={showMessage} onClose={() => setShowMessage(false)}>
				<p>{uctrans("website.copy_activation_code")}</p>
			</Dialog>
		</div>
	);
}

AppRegistrationActivationCode.propTypes = {
	registration_code: PropTypes.string,
};
