import Mark from "mark.js";

const highlightSearchtermInElement = (element, searchTerm) => {
	const markContainer = new Mark(element);
	markContainer.mark(searchTerm, {
		element: "span",
		className: "searchterm-highlight",
	});
};

const highlightSearchtermsInMainContent = searchTerm => {
	const render = () => {
		highlightSearchtermInElement(document.querySelector("#content"), searchTerm);
	};
	document.addEventListener("DOMContentLoaded", render, { once: true });
};

export { highlightSearchtermsInMainContent, highlightSearchtermInElement };
