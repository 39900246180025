import { useCallback, useRef, useState } from "react";
import { route } from "../../../../../js/helpers";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { error } from "../../../../../js/react/components/general/notifications";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import CooperationRequestContext from "./CooperationRequestContext";
import CooperationRequestQuestionnaireFormFields from "./CooperationRequestQuestionnaireFormFields";
import axios from "axios";

export default function CooperationRequest() {
	const [formData, setFormData] = useState({ ...window.data.cooperation_request_form_data });
	const [canGoNext, setCanGoNext] = useState(false);
	const [canGoBack, setCanGoBack] = useState(true);
	const [questionnaireStaticData, setQuestionnaireStaticData] = useState({
		...window.data.cooperation_request_static_data,
	});
	const inputHandler = useGenericHandler(setFormData);
	const formRef = useRef(null);
	const [icon, setIcon] = useState(null);

	const updateMultipleFormDataFields = useCallback(
		fields => {
			setFormData({ ...formData, ...fields });
		},
		[formData],
	);

	const submitForm = useCallback(() => {
		formRef.current && formRef.current.handleForm();
	}, [formRef]);

	const onSuccess = response => {
		if (response.data) {
			setCanGoNext(false);
			setFormData(response.data.cooperation_request_form_data);
			setQuestionnaireStaticData(response.data.cooperation_request_static_data);
		}
	};

	const goBack = useCallback(async () => {
		try {
			const response = await axios.put(route("website.cooperation-request.back", formData.token));
			if (response && response.data) {
				setFormData(response.data.cooperation_request_form_data);
				setQuestionnaireStaticData(response.data.cooperation_request_static_data);
			}
		} catch (e) {
			console.error(e);
			error(e.message);
		}
	}, [formData.token]);

	const goNext = useCallback(async () => {
		try {
			submitForm();
		} catch (e) {
			console.error(e);
			error(e.message);
		}
	}, [submitForm]);

	return (
		<AjaxForm
			method="put"
			submitUrl={route("website.cooperation-request.save-answers", { token: formData.token })}
			data={{ ...formData }}
			ref={formRef}
			onSuccess={onSuccess}>
			<CooperationRequestContext.Provider
				value={{
					cooperationRequestData: formData,
					updateCooperationRequestData: inputHandler,
					updateMultipleCooperationCompletionFields: updateMultipleFormDataFields,
					submitForm,
					goBack,
					canGoBack,
					setCanGoBack,
					goNext,
					canGoNext,
					setCanGoNext,
					icon,
					setIcon,
					...questionnaireStaticData,
				}}>
				<CooperationRequestQuestionnaireFormFields />
			</CooperationRequestContext.Provider>
		</AjaxForm>
	);
}
