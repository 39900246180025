import Tippy from "@tippyjs/react";
import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { uctrans } from "../../../../../js/lib/Translator";
import Nav from "../../../../../js/lib/Nav";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Select from "../../../../../js/react/components/general/Select";
import Spinner from "../../../../../js/react/components/general/Spinner";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";

export default function InterestArchive() {
	const {
		interest_types: interestTypes,
		interest_type: interestType,
		years,
		data_route,
		default_selected_year,
	} = window.data;

	const [periods, setPeriods] = useState(window.data.periods ?? []);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		year: default_selected_year,
	});

	const selectHandler = useSelectHandler(setFormData);

	useEffect(() => {
		fetchInterestArchive();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const fetchInterestArchive = useCallback(async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(data_route, { params: formData });
			setPeriods(data.periods);
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	}, [data_route, formData]);

	const onInterestTypeChange = interestType => {
		Nav.go(route("website.interest.archive.index", interestType.slug));
	};

	return (
		<div className="content-block container spacer">
			<div className="shadow bg-white padding">
				<div className="flex flex-col lg:flex-row">
					<div className="lg:w-1/2">
						<h3>{uctrans("website.interests.interest_archive")}</h3>
					</div>
				</div>
				<div className="flex flex-col lg:flex-row">
					<div className="lg:w-1/3 mt-6 lg:mt-0">
						<div className="bg-primary-lightest p-6 mr-8">
							<fieldset className="interest-selection" id="rentekortingen_fieldset">
								<h6>{uctrans("website.interests.interest_type")}</h6>
								{interestTypes.map((item, index) => (
									<div className="form-full mb-0" key={index}>
										<label className="container-radio">
											<input
												type="radio"
												value={item.id}
												checked={item.id === interestType.id}
												onChange={() => onInterestTypeChange(item)}
											/>
											<span className="checkmark"></span>
											<span>{item.name}</span>
										</label>
									</div>
								))}
							</fieldset>
						</div>
					</div>
					<div className="lg:w-1/3 mt-6 lg:mt-0">
						<label htmlFor="year">{uctrans("report.year")}</label>
						<Select
							name="year"
							value={years.filter(({ value }) => `${value}` === `${formData.year}`)}
							options={years}
							isClearable={false}
							onChange={selectHandler}
						/>
					</div>
				</div>
				<div className="flex flex-col lg:flex-row mt-4">
					{loading ? (
						<div className="flex justify-center p-6">
							<Spinner width={50} />
						</div>
					) : periods.length > 0 ? (
						<ul className="p-0 m-0" style={{ listStyleType: "none" }}>
							{periods &&
								periods.map(period => (
									<li key={period.id}>
										&#x2192; &nbsp;
										<a href={route("website.interest.period.show", [interestType.slug, period.id])}>
											{period.archive_text !== "" ? (
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													content={
														<span>
															{period.archive_text.split("\n").map((line, index) => (
																<span key={index}>
																	{line} <br />{" "}
																</span>
															))}
														</span>
													}>
													<span>
														{format(period.start_date, "dd-MM-y")}{" "}
														<EvaIcon type="info" width="18" height="18" fill="#009286" />
													</span>
												</Tippy>
											) : (
												format(period.start_date, "dd-MM-y")
											)}
										</a>
									</li>
								))}
						</ul>
					) : (
						<p>{uctrans("website.interests.empty_archive")}</p>
					)}
				</div>
			</div>
		</div>
	);
}
