import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import ClientConsentManage from "../../../../../js/react/components/clientConsents/ClientConsentManage";
import LeadOverviewItem from "../../../../../js/react/components/clientConsents/LeadOverviewItem";
import Dialog from "../../../../../js/react/components/general/Dialog";
import axios from "axios";

export default function ClientLeadsDashboardBlock({ title, blockData }) {
	const [manageDialogConsent, setManageDialogConsent] = useState(null);

	const [clientConsents, setClientConsents] = useState(blockData.client_consents);
	const [clientConsentCount, setClientConsentCount] = useState(blockData.client_consent_count);
	const [htmlContent, setHtmlContent] = useState(null);

	useEffect(() => {
		setHtmlContent(
			blockData.htmlContent ? blockData.htmlContent.replace("{ACTIVE_LEAD_COUNT}", clientConsentCount ?? 0) : null,
		);
	}, [clientConsentCount, blockData.htmlContent]);

	const reload = useCallback(async () => {
		const response = await axios.get(route("website.dashboard.data", { type: "client_leads" }));
		setClientConsents(response.data.client_consents);
		setClientConsentCount(response.data.client_consent_count);
	}, []);

	return (
		<>
			<h3>
				<a href={`${blockData.url}?${blockData.url_filters}`}>{title}</a>
			</h3>

			<table className="table colors_odd table-fixed mb-4">
				<tbody>
					<tr>
						<td className="font-bold w-18/24">{uctrans("client_consents.client")}</td>
						<td className="text-right w-6/24 font-bold">{uctrans("client_consents.leads.singular")}</td>
					</tr>
					{clientConsentCount > 0 ? (
						clientConsents.map(clientConsent => (
							<tr
								key={clientConsent.id}
								className="cursor-pointer"
								onClick={() => {
									setManageDialogConsent(clientConsent);
								}}>
								<td className="truncate align-left ">
									{clientConsent.name_first_applicant
										? clientConsent.name_second_applicant
											? `${clientConsent.name_first_applicant} & ${clientConsent.name_second_applicant}`
											: clientConsent.name_first_applicant
										: "-"}
								</td>
								<td style={{ padding: 0, paddingRight: "0.75rem" }}>
									<div className="w-full flex justify-end mr-2">
										{clientConsent.leads.map((lead, i) => (
											<LeadOverviewItem key={i} lead={lead} onClick={() => {}} fromCrm={false} />
										))}
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={2}>
								<i className="italic">{uctrans("client_consents.no_client_consents_with_open_leads")}</i>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{!!htmlContent && <span style={{ marginTop: "auto" }} dangerouslySetInnerHTML={{ __html: htmlContent }} />}
			<Dialog width={800} isOpen={manageDialogConsent != null} onClose={() => setManageDialogConsent(null)}>
				<ClientConsentManage
					clientConsent={manageDialogConsent}
					forWebsite
					onChange={reload}
					useOverviewContext={false}
				/>
			</Dialog>
		</>
	);
}

ClientLeadsDashboardBlock.propTypes = {
	title: PropTypes.string,
	blockData: PropTypes.object,
};
