import DashboardBlock from "./DashboardBlock";

export default function WebsiteDashboard() {
	const blocks = window.data.dashboard_blocks ?? [];
	return blocks.length ? (
		<div className="content-block">
			<div className="container flex flex-col md:flex-row justify-between flex-wrap">
				{blocks.map(({ title, type, blockData = {} }, thingYouPutInALock) => (
					<DashboardBlock key={thingYouPutInALock} title={title} type={type} blockData={blockData} />
				))}
			</div>
			<div className="divider" />
		</div>
	) : null;
}
