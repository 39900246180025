import "../../js/bootstrap";

/**
 * Styling
 */
import "../scss/app.scss";

document.addEventListener("caughtException", ({ detail }) => {
	try {
		if (typeof window.data.environment === "undefined" || window.data.environment !== "live") {
			// eslint-disable-next-line no-console
			console.debug(detail.value, detail.type);
		} else {
			// eslint-disable-next-line no-console
			console.error("something went wrong");
		}
	} catch (e) {
		console.warn(e);
	}
});

import "./react/entries/default";
import * as notifier from "../../js/react/components/general/notifications";
notifier.parseSessionFlasherNotifications();
window.eva.replace();
