import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import { route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import Select from "../../../../../js/react/components/general/Select";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";
import TrackingDownloadLink from "../TrackingDownloadLink";

export default function ReportDownload() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];
	const selectHandler = useSelectHandler(setFormValues);

	return (
		<div className="flex w-full start pt-2 gap-3">
			<div className="w-1/6">
				<Select
					name="year"
					value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.year}`)}
					options={formInfo.years}
					isClearable={false}
					onChange={selectHandler}
				/>
			</div>

			<div className="w-1/4">
				<TrackingDownloadLink
					button
					elementStyle="primary"
					onClick={() => Nav.go(route("website.report.pdf", formValues.year))}
					className="button button-primary"
					text={uctrans("report.download_report")}
					targetUrl={route("website.report.pdf", formValues.year)}
				/>
			</div>
		</div>
	);
}
