import PropTypes from "prop-types";
import { useState } from "react";
import AddressForm from "../../../../../js/react/components/addresses/AddressForm";
import CommunicationPreferences from "../../../../../js/react/components/communication/CommunicationPreferences";
import ImageThumbnailFileInput from "../../../../../js/react/components/general/form/ImageThumbnailFileInput";
import KifidTextInput from "../../../../../js/react/components/general/form/KifidTextInput";
import KvkTextInput from "../../../../../js/react/components/general/form/KvkTextInput";
import PincodeTextInput from "../../../../../js/react/components/general/form/PincodeTextInput";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { route, can } from "../../../../../js/helpers";
import cloneDeep from "lodash/cloneDeep";

export default function MyCompanyForm(props) {
	const [formData, setFormData] = useState(
		typeof props.MyCompanyFormData === "undefined"
			? cloneDeep(window.data.my_company_form_data)
			: props.MyCompanyFormData,
	);
	const [staticData] = useState(
		typeof props.MyCompanyStaticData === "undefined"
			? cloneDeep(window.data.my_company_static_data)
			: props.MyCompanyStaticData,
	);

	const inputHandler = useInputHandler(setFormData);

	const onSuccess = () => {
		window.location.reload();
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const handleAddressInputChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData.addresses[key] = value;
		setFormData(newFormData);
	};

	const softwarePackageOptions = staticData.software_packages.map(action => ({
		value: action.id,
		label: action.name,
	}));

	const selectedSoftwarePackage =
		Number(formData.software_package_id) !== Number(0)
			? staticData.software_packages.find(action => Number(action.id) === Number(formData.software_package_id))
			: null;

	return (
		<AjaxForm
			method="PUT"
			submitUrl={route("website.my-company.update", formData.id)}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "my_company.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "my_company.singular" })}
			onSuccess={onSuccess}
			data={{ ...formData }}
			useFlashMessage>
			<h3>{uctrans("my_company.fields.general_info")}</h3>
			<div className="form-full">
				<div className="form-2-3">
					<LabeledTextInput
						name="name"
						value={formData.name}
						onChange={inputHandler}
						label="my_company.fields.name"
						disabled
					/>
				</div>
				<div className="form-1-3">
					<LabeledTextInput
						disabled
						name="tp_number"
						value={formData.tp_number}
						onChange={inputHandler}
						label="my_company.fields.tp_number"
					/>
				</div>
			</div>
			<div className="form-full">
				<div className="form-1-3">
					<LabeledTextInput
						disabled
						name="organisation"
						value={formData.organisation}
						onChange={inputHandler}
						label="my_company.fields.organisation"
					/>
				</div>
				<div className="form-1-3">
					<KifidTextInput
						disabled
						name="kifid_number"
						value={formData.kifid_number}
						onChange={inputHandler}
						label="my_company.fields.kifid_number"
					/>
				</div>
			</div>
			<div className="form-full">
				<div className="form-1-3">
					<LabeledTextInput
						disabled
						name="relation_manager"
						value={formData.relation_manager}
						onChange={inputHandler}
						label="my_company.fields.relation_manager"
					/>
				</div>
				<div className="form-1-3">
					<LabeledTextInput
						name="team"
						value={formData.team}
						onChange={inputHandler}
						label="my_company.fields.team"
						disabled
					/>
				</div>
			</div>
			<div className="form-full">
				<div className="form-1-3">
					<PincodeTextInput
						disabled
						name="pincode"
						value={formData.pincode}
						onChange={inputHandler}
						label="my_company.fields.pincode"
					/>
				</div>
			</div>
			<div className="form-full">
				<div className="form-1-3">
					<KvkTextInput
						disabled
						name="kvk_number"
						value={formData.kvk_number}
						onChange={inputHandler}
						label="my_company.fields.kvk_number"
					/>
				</div>
				<div className="form-1-3">
					<LabeledTextInput
						disabled
						name="afm_number"
						value={formData.afm_number}
						onChange={inputHandler}
						label="my_company.fields.afm_number"
					/>
				</div>
			</div>

			<div className="form-full">
				<div className="form-1-2">
					<label htmlFor="company_logo">
						<Translate content="companies.company_logo" />
					</label>
					<ImageThumbnailFileInput
						storeRoute="website.files.store"
						newFileDownloadRoute="website.files.download"
						existingFileDownloadRoute="website.my-company.company-logo-download"
						maxFiles={1}
						acceptedExtensions={["jpg", "png"]}
						image={formData.company_logo}
						onChange={files => handleFormValueChange("company_logo", files)}
					/>
				</div>
			</div>

			<h3>{uctrans("my_company.fields.adres_info")}</h3>
			<h5>{uctrans("my_company.fields.visiting_address")}</h5>
			<AddressForm
				disabled={!can("company", "current_company_update")}
				addressFormData={formData.addresses[staticData.address_types.VISITING]}
				onChange={value => handleAddressInputChange(staticData.address_types.VISITING, value)}
				useAddition
			/>

			<h5>{uctrans("my_company.fields.postal_address")}</h5>
			<AddressForm
				disabled={!can("company", "current_company_update")}
				addressFormData={formData.addresses[staticData.address_types.POSTAL]}
				onChange={value => handleAddressInputChange(staticData.address_types.POSTAL, value)}
				useAddition
			/>

			<h3>{uctrans("my_company.fields.contact")}</h3>
			<div className="form-full">
				<LabeledTextInput
					disabled={!can("company", "current_company_update")}
					name="email_address"
					value={formData.email_address}
					onChange={inputHandler}
					label="my_company.fields.email"
					required
				/>
			</div>
			<div className="form-full">
				<LabeledTextInput
					disabled={!can("company", "current_company_update")}
					name="phonenumber"
					label="my_company.fields.phonenumber"
					value={formData.phonenumber}
					onChange={e => handleFormValueChange(e.target.name, e.target.value)}
				/>
			</div>
			<div className="form-full">
				<LabeledTextInput
					disabled={!can("company", "current_company_update")}
					name="website"
					label="general.website"
					value={formData.website}
					onChange={e => handleFormValueChange(e.target.name, e.target.value)}
				/>
			</div>
			<div className="form-full my-6">
				<h3>{uctrans("my_company.fields.other_information")}</h3>
				<label htmlFor="software_package_id">
					<Translate content="my_company.fields.software_package_id" />
				</label>
				<Select
					disabled={!can("company", "current_company_update")}
					placeholder={uctrans("general.select_:item", {}, { item: "intermediaries.fields.software_package_id" })}
					value={softwarePackageOptions.filter(({ value }) => Number(value) === Number(formData.software_package_id))}
					options={softwarePackageOptions}
					name="software_package_id"
					onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : null)}
				/>
				{selectedSoftwarePackage && selectedSoftwarePackage.is_open ? (
					<div className="form-full">
						<LabeledTextInput
							disabled={!can("company", "current_company_update")}
							translateLabel={false}
							label={selectedSoftwarePackage.open_title}
							name="software_package_value"
							value={formData.software_package_value}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
						/>
					</div>
				) : null}
			</div>
			<div className="form-full mt-4">
				<h3>{uctrans("my_profile.fields.communication_preferences")}</h3>

				{can("company", "current_company_update") && (
					<div className="form-full mb-4">
						<Translate content="my_company.communication_preferences_description" />
					</div>
				)}

				<div className="form-full mt-4">
					<CommunicationPreferences
						disabled={!can("company", "current_company_update")}
						staticNewsLetterSubscriptionTypes={staticData.newsletter_subscription_types}
						unsubscribeReasons={staticData.unsubscribe_reasons}
						communicationPreferenceData={formData.unsubscribe_preferences}
						onPreferenceChange={value => handleFormValueChange("unsubscribe_preferences", value)}
					/>
				</div>

				{can("company", "current_company_update") && (
					<div className="form-full mt-4">
						<div className="row">
							<div className="w-full md:w-1/2 lg:w-1/3 column">
								<SubmitBar item="my_company.singular" addAnotherAvailable={false} />
							</div>
						</div>
					</div>
				)}
			</div>
		</AjaxForm>
	);
}
MyCompanyForm.propTypes = {
	MyCompanyFormData: PropTypes.object,
	MyCompanyStaticData: PropTypes.object,
};
