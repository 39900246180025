import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { numberFormat } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";

export const formatInterest = number => `${numberFormat((number * 100) / 100, 2)} %`;

export const ChangeIcon = ({ interest }) => {
	let iconData;
	const difference = interest.rate - interest.previous_rate;
	if (difference > 0) {
		iconData = {
			icon_color: "#ff0000",
			icon_type: "arrow-upward-outline",
			message: `+${formatInterest(difference)}`,
		};
	} else if (difference < 0) {
		iconData = {
			icon_color: "#249286",
			icon_type: "arrow-downward-outline",
			message: `${formatInterest(difference)}`,
		};
	} else {
		iconData = {
			icon_color: "#999999",
			icon_type: "minus-outline",
			message: uctrans("website.interests.interest_no_change"),
		};
	}

	return (
		<Tippy placement="bottom" arrow animation="perspective" content={iconData.message}>
			<span className="ml-2">
				<EvaIcon type={iconData.icon_type} fill={iconData.icon_color} height="17" width="17" />
			</span>
		</Tippy>
	);
};

ChangeIcon.propTypes = {
	interest: PropTypes.object.isRequired,
};
