import { format } from "../../../../../js/lib/Date";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";

export default function InterestOverviewPeriodTab({ period, active, selectPeriod }) {
	return (
		<button
			className={`px-10 py-4 transition-colors ${active ? "bg-primary text-white" : "bg-grey-light"}`}
			key={period.id}
			onClick={() => selectPeriod(period.id)}>
			{uctrans("website.interests.interest_for")} {format(period.started_at, "dd-MM-y")}
		</button>
	);
}

InterestOverviewPeriodTab.propTypes = {
	period: PropTypes.object.isRequired,
	active: PropTypes.bool.isRequired,
	selectPeriod: PropTypes.func.isRequired,
};
