import findIndex from "lodash/findIndex";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import QuestionnaireContext from "./QuestionnaireContext";
import QuestionList from "./QuestionList";
import { uctrans } from "../../../../js/lib/Translator";
import ConfirmDialog from "../../../../js/react/components/general/ConfirmDialog";

export default function PageList(props) {
	const {
		questionnaire,
		hasNextPage,
		switchPage,
		nextPage,
		hasPreviousPage,
		previousPage,
		currentPage,
		isLoading,
		checkRequiredQuestionsOnPage,
	} = useContext(QuestionnaireContext);

	const [confirmOpen, setConfirmOpen] = useState(false);

	const attemptNextPage = () => {
		if (isLoading) {
			return;
		}
		if (checkRequiredQuestionsOnPage(currentPage)) {
			if (hasNextPage()) {
				nextPage();
			} else {
				setConfirmOpen(true);
			}
		}
	};

	const attemptPreviousPage = () => {
		if (isLoading) {
			return;
		}
		if (checkRequiredQuestionsOnPage(currentPage)) {
			if (hasPreviousPage()) {
				previousPage();
			}
		}
	};

	const nextButton = () => {
		if (hasNextPage()) {
			return uctrans("website.questionnaires.next_page");
		} else {
			return uctrans("website.questionnaires.finish_questionnaire");
		}
	};

	const getPageNavigationTitleData = () => {
		const currentPageIndex = findIndex(questionnaire.pages, { id: currentPage ? currentPage.id : -1 });
		return questionnaire.pages.length > 0 && questionnaire.pages[currentPageIndex].title;
	};

	return (
		<div>
			<h3>{getPageNavigationTitleData()}</h3>

			<QuestionList />

			<div className="space-x-3">
				{hasPreviousPage() && (
					<a className={`button button-secondary ${isLoading ? "disabled" : ""}`} onClick={attemptPreviousPage}>
						{uctrans("website.questionnaires.previous_page")}
					</a>
				)}

				<a className={`button button-secondary ${isLoading ? "disabled" : ""}`} onClick={attemptNextPage}>
					{nextButton()}
				</a>
			</div>

			{props.showPageNumbers && (
				<div className="page-number mt-4" role="navigation" aria-label="Pages">
					{questionnaire.pages.map(({ ...page }, pageIndex) => (
						<div key={page.id} className="float-left">
							<button
								className={`button button-pager mr-2 ${page.id === currentPage.id && "selected"}`}
								id={`page-item-${page.id}`}
								onClick={() => switchPage(page.id)}>
								{pageIndex + 1}
							</button>
						</div>
					))}
				</div>
			)}

			<ConfirmDialog
				isOpen={confirmOpen}
				onConfirm={nextPage}
				confirmIsDisabled={isLoading}
				onCancel={() => setConfirmOpen(false)}
				title={props.confirmTitle}
				confirmButtonLabel={uctrans("general.yes")}
				cancelButtonLabel={uctrans("general.no")}>
				<span className="form-full">{props.confirmBody}</span>
			</ConfirmDialog>
		</div>
	);
}

PageList.propTypes = {
	showPageNumbers: PropTypes.bool,
	confirmTitle: PropTypes.string,
	confirmBody: PropTypes.string,
};

PageList.defaultProps = {
	showPageNumbers: true,
	confirmTitle: uctrans("website.questionnaires.confirm_finish_title"),
	confirmBody: uctrans("website.questionnaires.confirm_finish_text"),
};
