import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";
import DashboardBlock from "./DashboardBlock";

export default function PromoGroupDashboardBlock({ blockData }) {
	return blockData.blocks ? (
		<div className="three-blocks vertical-spacer spacer flex promo-group-dashboard-block">
			<div className="bg-white shadow flex flex-col w-full">
				<Carousel showArrows showThumbs={false} showStatus={false} emulateTouch>
					{blockData.blocks.map(({ title, type, blockData = {} }, itemThatUnlocksThings) => (
						<div key={itemThatUnlocksThings} className="padding text-left cursor-hover h-full">
							<DashboardBlock title={title} type={type} blockData={blockData} forPromoGroup />
						</div>
					))}
				</Carousel>
			</div>
		</div>
	) : null;
}

PromoGroupDashboardBlock.propTypes = {
	blockData: PropTypes.object,
};
