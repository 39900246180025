import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import PropTypes from "prop-types";

export default function ProcessTimesDashboardBlock({ title }) {
	const [processTimeData, setProcessTimeData] = useState(null);
	const [initialized, setInitialized] = useState(false);

	const fetchprocessTimes = useCallback(async () => {
		const response = await axios.get(route("website.dashboard.data", { type: "processtimes" }));
		setProcessTimeData(response.data);
	}, []);

	useEffect(() => {
		if (!initialized) {
			fetchprocessTimes();
			setInitialized(true);
		}
	}, [fetchprocessTimes, initialized]);

	return (
		initialized &&
		processTimeData != null &&
		processTimeData !== "" &&
		!!processTimeData.activities && (
			<>
				<h3 className="break-words">
					{initialized && processTimeData != null && !!processTimeData.url ? (
						<a href={processTimeData.url}>{title}</a>
					) : (
						title
					)}
				</h3>
				<span className="text-xs">
					Team {processTimeData.team_name}, per{" "}
					{processTimeData.process_time_date ? format(processTimeData.process_time_date, "dd-MM-y") : ""}
				</span>

				<div className="table w-full mb-4 mt-4 text-xs">
					{processTimeData.activities.map((activityData, i) => (
						<div key={i} className="table-row">
							<div className="table-cell pb-2">
								<span
									className="mr-4 pb-4"
									style={{
										height: "16px",
										width: "16px",
										backgroundColor: activityData.within_servicelevel ? "#96C147" : "#f58a00",
										borderRadius: "50%",
										display: "inline-block",
										verticalAlign: "middle",
										lineHeight: "normal",
									}}
								/>
							</div>
							<div className="table-cell pb-2">
								{activityData.activity_name} ({activityData.process_time_value}{" "}
								{activityData.process_time_type === 1
									? trans("teams.process_times.hour")
									: activityData.process_time_value === 1
									  ? trans("teams.process_times.workday")
									  : trans("teams.process_times.workdays")}
								)
							</div>
						</div>
					))}
				</div>
				<div className="text-xs">
					<span
						className="mr-2"
						style={{
							height: "10px",
							width: "10px",
							backgroundColor: "#96C147",
							borderRadius: "50%",
							display: "inline-block",
						}}
					/>
					= {uctrans("teams.process_times.within_servicelevel")}
					<br />
					<span
						className="mr-2"
						style={{
							height: "10px",
							width: "10px",
							backgroundColor: "#f58a00",
							borderRadius: "50%",
							display: "inline-block",
						}}
					/>
					= {uctrans("teams.process_times.without_servicelevel")}
				</div>
			</>
		)
	);
}

ProcessTimesDashboardBlock.propTypes = {
	title: PropTypes.string,
};
