import { useCallback, useContext, useEffect } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import Questionnaire from "../../Questionnaire";
import CooperationRequestContext from "../CooperationRequestContext";

export default function StepSystemQuestionnaire() {
	const { pre_processed_data, setCanGoNext, goNext, goBack, setCanGoBack } = useContext(CooperationRequestContext);

	useEffect(() => {
		setCanGoBack(false);
	}, [setCanGoBack]);

	const goPrimordial = useCallback(() => {
		goBack();
	}, [goBack]);

	const onSystemQuestionnaireComplete = useCallback(() => {
		goNext();
	}, [goNext]);

	useEffect(() => {
		setCanGoNext(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div id="questionnaire">
			<Questionnaire
				questionnaire={pre_processed_data.questionnaire_static_data.questionnaire}
				questionnairetags={pre_processed_data.questionnaire_static_data.questionnairetags}
				questionnaireStarted={!!pre_processed_data.questionnaire_completion_data}
				questionnaire_completion_data={{ ...pre_processed_data.questionnaire_completion_data }}
				clientTreatmentParticipantIdToken={null}
				relationManagerSurveyIdToken={null}
				communicationLogIdToken={null}
				onComplete={onSystemQuestionnaireComplete}
				showPageNumbers={false}
				confirmTitle={uctrans("website.cooperation_request.questionnaires.confirm_finish_title")}
				confirmBody={uctrans("website.cooperation_request.questionnaires.confirm_finish_text")}
				goPrimordial={goPrimordial}
				primordial
				initialPage={pre_processed_data.initial_page}
			/>
		</div>
	);
}
