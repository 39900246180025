import GoogleMapReact from "google-map-react";
import sortBy from "lodash/sortBy";
import pointsCluster from "points-cluster";
import PropTypes from "prop-types";
import { createRef, useEffect, useState } from "react";
import { route, urlHttpPrefix } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import ClusterMarker from "../../../../../locator/js/react/components/locator/markers/ClusterMarker";
import CompanyMarker from "../../../../../locator/js/react/components/locator/markers/CompanyMarker";
import CompanyMiniMapMarker from "../../../../../locator/js/react/components/locator/markers/CompanyMiniMapMarker";
import CurrentLocationMarker from "../../../../../locator/js/react/components/locator/markers/CurrentLocationMarker";
import Tippy from "@tippyjs/react";
import axios from "axios";
import ImageLeaf from "../../../../../static/img/sustainable-greenleaf.svg";

export default function MiniLocator({ html }) {
	const [currentLocation, setCurrentLocation] = useState(
		window.data.cookieStartLocation ? { ...window.data.cookieStartLocation } : null,
	);
	const [foundCompanies, setFoundCompanies] = useState(
		window.data.foundCompanies ? [...window.data.foundCompanies] : [],
	);
	const [refs, setRefs] = useState([]);
	const [addressSearchTerm, setAddressSearchTerm] = useState("");
	const [addressSearchError, setAddressSearchError] = useState("");
	const { kmOptions } = window.data;
	const [km, setKm] = useState(10);
	const orderByOptions = [
		{ value: "distance", label: "Afstand" },
		{ value: "name", label: "Naam adviseur" },
		{ value: "sustainable", label: "Duurzame intermediair" },
	];
	const [orderBy, setOrderBy] = useState("distance");
	const [selectedDetailCompany, setSelectedDetailCompany] = useState(null);
	const [selectedDetailCompanyDetails, setSelectedDetailCompanyDetails] = useState(null);
	const [selectedListCompany, setSelectedListCompany] = useState(null);

	const [googleMaps, setGoogleMaps] = useState(null);
	const [googleMap, setGoogleMap] = useState(null);
	const [mapProps, setMapProps] = useState({
		zoom: 8,
		center: { lat: 52.092876, lng: 5.10448 },
		bounds: null,
	});
	const [clusters, setClusters] = useState([]);

	const [searchingCompanies, setSearchingCompanies] = useState(false);
	const [loadingselectedDetailCompany] = useState(false);

	const adviceRequestReasons = window.data.adviceRequestReasons ? [...window.data.adviceRequestReasons] : [];

	const [detailFormName, setDetailFormName] = useState("");
	const [detailFormEmail, setDetailFormEmail] = useState("");
	const [detailFormPhone, setDetailFormPhone] = useState("");
	const [detailFormAdviseRequestReason, setDetailFormAdviseRequestReason] = useState("");
	const [detailFormSubmitted, setDetailFormSubmitted] = useState(false);
	const [detailFormErrors, setDetailFormErrors] = useState([]);

	const search = function (e) {
		e.preventDefault();
		if (addressSearchTerm === "" || addressSearchTerm == null) {
			setAddressSearchError("Vul eerst een zoekterm in");
		} else {
			const geocoder = new googleMaps.Geocoder();
			geocoder.geocode(
				{
					address: addressSearchTerm,
					componentRestrictions: { country: "NL" },
				},
				(results, status) => {
					if (status === "OK") {
						setFoundCompanies([]);
						const [geocodeSearchResult] = results;
						const currentLocation = {
							formattedAddress: geocodeSearchResult.formatted_address,
							lat: geocodeSearchResult.geometry.location.lat(),
							lng: geocodeSearchResult.geometry.location.lng(),
							marker: null,
						};
						setCurrentLocation(currentLocation);
						searchCompanies(currentLocation, km);
					} else if (status === "ZERO_RESULTS") {
						setAddressSearchError("Geen resulaten gevonden");
					} else {
						console.error(`Geocode was not successful for the following reason: ${status}`);
					}
				},
			);
		}
	};

	const onMapChange = ({ center, zoom, bounds }) => {
		setMapProps({ center, zoom, bounds });
	};

	const searchCompanies = function (currentLocation, km) {
		setSearchingCompanies(true);

		axios
			.post(route("website.locator.search-companies"), {
				current_location_lat: currentLocation.lat,
				current_location_long: currentLocation.lng,
				searchterm: addressSearchTerm,
				formatted_address: currentLocation.formattedAddress,
				kilometer: km,
			})
			.then(response => {
				setFoundCompanies(orderCompanies(response.data, orderBy));
				scaleMapToMarkers(googleMap, googleMaps, response.data, 0.002);
				setTimeout(() => {
					setSearchingCompanies(false);
				}, 1000);
			})
			.catch(error => {
				console.error(`Errors searching for companies${error}`);
			});
	};
	const centerOnCurrentLocation = function () {
		googleMap.setCenter(new googleMaps.LatLng(currentLocation.lat, currentLocation.lng));
	};
	const resetCurrentLocation = function () {
		setCurrentLocation(null);
		scaleMapToMarkers(googleMap, googleMaps, []);

		axios.post(route("website.locator.unset-stored-location"));
	};

	const unselectDetailCompany = function () {
		setSelectedDetailCompany(null);
		setSelectedDetailCompanyDetails(null);
		scaleMapToMarkers(googleMap, googleMaps, foundCompanies, 0.002);
		setDetailFormSubmitted(false);
	};

	const orderCompanies = function (organsations, orderBy) {
		return sortBy(
			[...organsations],
			[
				function (o) {
					if (orderBy === "distance") {
						return o.distance;
					} else if (orderBy === "name") {
						return o.name;
					} else if (orderBy === "sustainable") {
						return !o.sustainable;
					}
				},
			],
		);
	};

	useEffect(() => {
		calculateClusters([...foundCompanies], mapProps);
	}, [foundCompanies, mapProps]);

	useEffect(() => {
		setRefs(
			foundCompanies.reduce((acc, value) => {
				acc[value.id] = createRef();
				return acc;
			}, {}),
		);
	}, [foundCompanies]);

	const calculateClusters = (foundCompanies, mapProps) => {
		const clusterer = mapProps.bounds ? pointsCluster(foundCompanies, { minZoom: 3, maxZoom: 13, radius: 60 }) : null;
		const clusters = (clusterer ? clusterer(mapProps) : []).map(({ wx, wy, numPoints, points }) => ({
			id: points[0].id,
			lat: wy,
			lng: wx,
			nrOfItems: numPoints,
			items: points,
		}));
		setClusters(clusters);
	};

	const clusterClicked = cluster => {
		scaleMapToMarkers(googleMap, googleMaps, cluster.items, 0.002);
	};

	const scaleMapToMarkers = (map, maps, markers, offset) => {
		if (!markers.length) {
			map.setCenter(new maps.LatLng(52.092876, 5.10448));
			map.setZoom(8);
		} else if (maps && markers) {
			const bounds = new maps.LatLngBounds();
			markers.forEach(marker => {
				bounds.extend({ lat: marker.lat, lng: marker.lng });
			});
			if (offset) {
				const center = bounds.getCenter();
				bounds.extend(new maps.LatLng(center.lat() + offset, center.lng() + offset));
				bounds.extend(new maps.LatLng(center.lat() - offset, center.lng() - offset));
			}

			map.fitBounds(bounds);
		}
	};

	const getMapItems = () =>
		[{ type: "currentlocation", mapItem: currentLocation }].concat(
			clusters.map(mapItem => {
				if (mapItem.nrOfItems === 1) {
					const [item] = mapItem.items;
					return { type: "company", mapItem: item };
				} else {
					return { type: "cluster", mapItem };
				}
			}),
		);

	const onCompanyMapItemClick = company => {
		if (selectedListCompany && company.id === selectedListCompany.id) {
			setSelectedListCompany(null);
		} else {
			setSelectedListCompany(company);
			refs[company.id].current.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "start",
			});
		}
	};

	const onCompanyListItemClick = company => {
		if (selectedListCompany && company.id === selectedListCompany.id) {
			setSelectedListCompany(null);
			scaleMapToMarkers(googleMap, googleMaps, foundCompanies, 0.002);
		} else {
			setSelectedListCompany(company);
			googleMap.setCenter(new googleMaps.LatLng(company.lat, company.lng));
			googleMap.setZoom(15);
		}
	};

	const companyStreetviewClicked = item => {
		const latlng = new googleMaps.LatLng(item.lat, item.lng);
		const sv = new googleMaps.StreetViewService();
		const pano = googleMap.getStreetView();
		sv.getPanoramaByLocation(latlng, 50, (data, status) => {
			if (status === googleMaps.StreetViewStatus.OK) {
				const heading = googleMaps.geometry.spherical.computeHeading(data.location.latLng, latlng);
				pano.setPano(data.location.pano);
				pano.setPov({
					heading,
					pitch: 0,
					zoom: 1,
				});
				pano.setVisible(true);
			}
		});
	};

	const submitDetailForm = () => {
		const errors = [];
		if (detailFormName === "" || detailFormName == null) {
			errors.push("Naam is verplicht");
		}
		if (detailFormEmail === "" || detailFormEmail == null) {
			errors.push("E-mailadres is verplicht");
		}
		if (detailFormAdviseRequestReason === "" || detailFormAdviseRequestReason == null) {
			errors.push("Reden van aanvraag verplicht");
		}
		setDetailFormErrors(errors);

		if (!errors.length) {
			axios
				.post(route("website.locator.submit-detail-form"), {
					company_id: selectedDetailCompanyDetails.id,
					name: detailFormName,
					email: detailFormEmail,
					phone: detailFormPhone,
					adviseRequestReason: detailFormAdviseRequestReason,
				})
				.then(() => {
					setDetailFormSubmitted(true);
				})
				.catch(error => {
					console.error(error);
				});
		}
	};

	return (
		<>
			<div className="container image-text-block spacer">
				<div className="image-text-block__text">
					<div dangerouslySetInnerHTML={{ __html: html }} />
					{!currentLocation && (
						<form onSubmit={search}>
							{!!addressSearchError && <div className="text-red">{addressSearchError}</div>}
							<div className="content flex">
								<div className="w-2/3">
									<h6>Zoek adviseurs in de buurt van</h6>
									<input
										style={{ lineHeight: 1.28 }}
										type="text"
										placeholder="Uw woonplaats / adres / postcode"
										value={addressSearchTerm}
										onInput={() => {
											setAddressSearchError("");
										}}
										onChange={e => setAddressSearchTerm(e.target.value)}
									/>
								</div>
								<div className="w-1/3 pl-2">
									<h6>Toon adviseurs binnen</h6>
									<select
										value={km}
										name="km"
										onChange={e => {
											setKm(e.target.value);
										}}>
										{kmOptions.map(kmOption => (
											<option key={kmOption} value={kmOption}>
												{`Binnen ${kmOption} km`}
											</option>
										))}
									</select>
								</div>
							</div>
							<div>
								<button type="submit" className="button button-primary mt-2">
									Zoek adviseur
								</button>
							</div>
						</form>
					)}
					{!!currentLocation && (
						<>
							<div className="content flex justify-between">
								<div>
									<h6>Zoek adviseurs in de buurt van</h6>
									{currentLocation.formattedAddress}
								</div>
								<div>
									<h6>Toon adviseurs binnen</h6>
									<select
										className="km_range_select"
										id="km_range"
										value={km}
										name="km"
										onChange={e => {
											setKm(e.target.value);
											searchCompanies(currentLocation, e.target.value);
										}}>
										{kmOptions.map(kmOption => (
											<option key={kmOption} value={kmOption}>
												{`Binnen ${kmOption} km`}
											</option>
										))}
									</select>
								</div>
								<div>
									<h6>&nbsp;</h6>
									<button type="button" className="button button-primary p-0" onClick={resetCurrentLocation}>
										Wijzig
									</button>
								</div>
							</div>
							<div id="results" className="mt-2">
								{!!searchingCompanies && (
									<div id="loader-wrapper">
										<div id="loader" />
									</div>
								)}
								<div className="flex w-full justify-between text-sm	">
									<div className=" w-1/4  mt-2">{foundCompanies.length} adviseurs gevonden</div>
									<div className=" w-3/4">
										<div className="flex w-full justify-center mt-2">
											<div style={{ width: "80px" }}>Sorteer op:</div>
											<select
												style={{ border: "none", padding: 0, width: "120px" }}
												id="order_by"
												value={orderBy}
												name="orderBy"
												onChange={e => {
													setOrderBy(e.target.value);
													setFoundCompanies(orderCompanies([...foundCompanies], e.target.value));
												}}>
												{orderByOptions.map(({ ...orderByOption }) => (
													<option key={orderByOption.value} value={orderByOption.value}>
														{orderByOption.label}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>

								<div className="flex w-full pr-4 text-sm">
									<div className="p-2 w-2/4">
										<b>Naam</b>
									</div>
									<div className="w-3/4 flex">
										<div className="p-2 w-1/2 text-center">
											<b>Duurzame intermediair</b>&nbsp;
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.sustainable")}>
												<span>
													<i className="fa fa-info-circle tooltip">&nbsp;</i>
												</span>
											</Tippy>
										</div>
										<div className="p-2 w-1/4 text-center">
											<b>Afstand</b>
										</div>
									</div>
								</div>
								<div id="company_list" className="text-sm">
									<ul>
										{foundCompanies.map(({ ...company }) => (
											<li
												ref={refs[company.id]}
												key={company.id}
												className={`company-listitem ${
													selectedListCompany && company.id === selectedListCompany.id ? "active" : ""
												}`}
												onClick={() => {
													onCompanyListItemClick(company);
												}}>
												<div className="flex w-full">
													<div className="p-2 w-2/4">{company.name}</div>
													<div className="w-3/4 flex">
														<div
															className="w-1/2 flex justify-center items-center"
															title={uctrans("general.show_on_map")}>
															{company.sustainable === 1 ? <img src={ImageLeaf} width="35" height="35" /> : " "}
														</div>
														<div className="p-2 w-1/4 flex justify-center items-center">
															{Math.round(company.distance * 10) / 10} Km
														</div>
													</div>
												</div>
												{selectedListCompany && company.id === selectedListCompany.id && (
													<div>
														<div className="flex w-full">
															<div className="w-2/4">
																<div className="p-2 w-full">
																	{company.address_street} {company.address_number} {company.address_addition}
																	<br />
																	{company.address_zipcode} {company.address_city}
																	<br />
																	{company.phone ? company.phone : ""}
																</div>
															</div>
														</div>
														<div className="flex w-full">
															<div className="w-2/3">
																<div className="p-2 w-1/2">
																	{company.email ? (
																		<a target="_parent" href={`mailto:${company.email}`}>
																			{company.email}
																		</a>
																	) : (
																		""
																	)}
																	<br />
																	{company.website ? (
																		<a target="_blank" href={urlHttpPrefix(company.website)} rel="noreferrer noopener">
																			{company.website}
																		</a>
																	) : (
																		""
																	)}
																</div>
															</div>
															<div className="w-1/3 flex justify-end"></div>
														</div>
													</div>
												)}
											</li>
										))}
									</ul>
								</div>
							</div>
						</>
					)}
				</div>
				<div className="image-text-block__image" style={{ overflowY: "hidden" }}>
					<div id="map-holder" style={{ height: "104%" }}>
						<div id="map-canvas">
							<GoogleMapReact
								bootstrapURLKeys={{ key: window.data.maps_api_key, language: "nl" }}
								center={mapProps.center}
								zoom={mapProps.zoom}
								onChange={onMapChange}
								yesIWantToUseGoogleMapApiInternals
								onGoogleApiLoaded={({ map, maps }) => {
									setGoogleMap(map);
									setGoogleMaps(maps);
									calculateClusters(foundCompanies, mapProps);
									scaleMapToMarkers(map, maps, foundCompanies, 0.002);
								}}>
								{!!currentLocation &&
									getMapItems().map(marker => {
										if (marker.type === "currentlocation") {
											const { mapItem } = marker;
											return (
												<CurrentLocationMarker
													key="current"
													lat={mapItem.lat}
													lng={mapItem.lng}
													label={mapItem.formattedAddress}
													onClick={centerOnCurrentLocation}
												/>
											);
										} else if (marker.type === "company") {
											const { mapItem } = marker;
											return (
												<CompanyMarker
													key={mapItem.id}
													lat={mapItem.lat}
													lng={mapItem.lng}
													company={mapItem}
													onStreetviewClick={() => {
														companyStreetviewClicked(mapItem);
													}}
													onClick={() => {
														onCompanyMapItemClick(mapItem);
													}}
													isOpen={selectedListCompany && selectedListCompany.id === mapItem.id}
												/>
											);
										} else if (marker.type === "cluster") {
											const { mapItem } = marker;
											return (
												<ClusterMarker
													key={mapItem.id}
													lat={mapItem.lat}
													lng={mapItem.lng}
													nrOfItems={mapItem.nrOfItems}
													onClick={() => {
														clusterClicked(mapItem);
													}}
												/>
											);
										} else {
											return null;
										}
									})}
							</GoogleMapReact>
						</div>
					</div>
				</div>
			</div>
			{!!selectedDetailCompany && (
				<div className="overlay details">
					<div className="overlay-content overlay-light">
						{!!loadingselectedDetailCompany && (
							<div id="loader-wrapper">
								<div id="loader" />
							</div>
						)}
						{!loadingselectedDetailCompany && selectedDetailCompany && (
							<>
								<div className="top">
									<span className="close cursor-pointer" onClick={unselectDetailCompany}>
										<EvaIcon type="close-outline" width="24" height="24" fill="#009286" />
									</span>
									<h2>{selectedDetailCompanyDetails.name}</h2>
								</div>
								<div className="bottom text-sm">
									<div className="left">
										{!detailFormSubmitted && (
											<div className="detail_form">
												<h4>Contact met mij opnemen</h4>
												<p>
													Vul hieronder uw gegevens in, zodat een medewerker van {selectedDetailCompanyDetails.name}{" "}
													contact met u kan opnemen
												</p>

												{!!detailFormErrors.length && (
													<div className="errors">
														<ul>
															{detailFormErrors.map(detailFormError => (
																<li key={detailFormError}>{detailFormError}</li>
															))}
														</ul>
													</div>
												)}
												<form>
													<fieldset>
														<label className="input-label">
															<strong>Naam *</strong>
														</label>
														<div className="input-container">
															<input
																type="text"
																className="address_search_field "
																value={detailFormName}
																onChange={e => setDetailFormName(e.target.value)}
															/>
														</div>
													</fieldset>
													<fieldset>
														<label className="input-label">
															<strong>E-mailadres *</strong>
														</label>
														<div className="input-container">
															<input
																type="email"
																value={detailFormEmail}
																onChange={e => setDetailFormEmail(e.target.value)}
															/>
														</div>
													</fieldset>
													<fieldset>
														<label className="input-label">
															<strong>Telefoonnummer</strong>
														</label>
														<div className="input-container">
															<input
																type="text"
																value={detailFormPhone}
																onChange={e => setDetailFormPhone(e.target.value)}
															/>{" "}
														</div>
													</fieldset>
													{!!adviceRequestReasons.length && (
														<fieldset>
															<label className="input-label">
																<strong>Reden contact *</strong>
															</label>
															<div className="input-container">
																{adviceRequestReasons.map(adviceRequestReason => (
																	<label
																		key={adviceRequestReason.id}
																		className="font-normal"
																		htmlFor={`advise_request_reason_${adviceRequestReason.id}`}>
																		<input
																			type="radio"
																			id={`advise_request_reason_${adviceRequestReason.id}`}
																			value={adviceRequestReason.id}
																			checked={adviceRequestReason.id === detailFormAdviseRequestReason}
																			onClick={() => {
																				setDetailFormAdviseRequestReason(adviceRequestReason.id);
																			}}
																		/>
																		{adviceRequestReason.name}
																	</label>
																))}
															</div>
														</fieldset>
													)}
													<button type="button" onClick={submitDetailForm} className="button button-primary">
														Versturen
													</button>
												</form>
											</div>
										)}
										{!!detailFormSubmitted && (
											<div className="detail_thankyou">
												<h4>Aanvraag verzonden</h4>
												<br />
												Uw aanvraag is succesvol verzonden naar {selectedDetailCompanyDetails.name}!
											</div>
										)}
									</div>
									<div className="right">
										<h4>Contactgegevens</h4>
										<span className="im_address">
											{selectedDetailCompanyDetails.address_street} {selectedDetailCompanyDetails.address_number}
											{selectedDetailCompanyDetails.address_addition}
											<br />
											{selectedDetailCompanyDetails.address_zipcode} {selectedDetailCompanyDetails.address_city}
											<br />
											{!!selectedDetailCompanyDetails.phone && (
												<>
													<span>{selectedDetailCompanyDetails.phone}</span>
													<br />
													<br />
												</>
											)}
											{!!selectedDetailCompanyDetails.email && (
												<>
													<a target="_parent" href={`mailto:${selectedDetailCompanyDetails.email}`}>
														{selectedDetailCompanyDetails.email}
													</a>
													<br />
												</>
											)}
											{!!selectedDetailCompanyDetails.website && (
												<>
													<a
														target="_blank"
														href={urlHttpPrefix(selectedDetailCompanyDetails.website)}
														rel="noreferrer noopener">
														{selectedDetailCompanyDetails.website}
													</a>
													<br />
												</>
											)}
										</span>
										<div className="detailmap">
											<GoogleMapReact
												bootstrapURLKeys={{ key: window.data.maps_api_key, language: "nl" }}
												center={{
													lat: selectedDetailCompanyDetails.lat,
													lng: selectedDetailCompanyDetails.lng,
												}}
												zoom={15}>
												<CompanyMiniMapMarker
													lat={selectedDetailCompanyDetails.lat}
													lng={selectedDetailCompanyDetails.lng}
												/>
											</GoogleMapReact>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
}

MiniLocator.propTypes = {
	html: PropTypes.string,
};
